import React from 'react';
import { Grid, Typography, Tabs, Tab, Paper, Box, Divider, TextField, Checkbox, FormControlLabel, Button, Drawer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { SelectBox } from '../../';
import { Types, caseSensitiveData, psudoData, encriptionTypeData, IsNumberKeyDown } from '../../../../utils';
import { useStyles } from './style';
import moment from 'moment';
// import { findAllByDisplayValue } from '@testing-library/dom';
// import Tooltip from '@material-ui/core/Tooltip';
// import Checkbox from '@material-ui/core/Checkbox';
// import TextField from '@material-ui/core/TextField';
import { withAllContexts } from "../../../../HOCs";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Keypopup, ValueDrawer } from '../../../entity/lov';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { barcodeTypes } from '../../../../utils';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`scrollable-auto-tabpanel-${index}`}
//             aria-labelledby={`scrollable-auto-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box p={0}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// }
class BasicDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabValue: 1,
            mandatory: false,
            valueNill: null,
            defaultValue: null,
            datatype: null,
            barcode: null,
            length: {
                min: null,
                max: null
            },
            value: {
                min: null,
                max: null
            },
            enumValues: [],
            promptTableValues: [],
            format: null,
            isLovKey: false,
            lovKeyData: [],
            isLovValue: false,
            lovValueData: [],

        }
        this.changeTab = this.changeTab.bind(this)
    }
    componentDidMount() {
        debugger;
        let state = this.state;
        let { editproperties, editproperties1, datalist } = this.props;

        state.datatype = editproperties.properties?.datatype;
        state.barcode = editproperties.properties?.barcode;

        state.mandatory = editproperties?.properties?.mandatory;
        state.length.min = editproperties?.properties?.length?.min;
        state.length.max = editproperties?.properties?.length?.max;
        state.value.min = editproperties?.properties?.minValue;
        state.value.max = editproperties?.properties?.maxValue;
        state.format = editproperties?.properties?.format;
        if (editproperties?.properties?.barcodeType == '') {
            state.barcodeType = 'UPC'
            this.props.setDataTypeProperties("barcodetypes", "UPC", 'property')
        }

        let LOVKEYS = [];
        let construct_LOV = editproperties?.properties?.LOV?.map(_M => {
            let lovKey = Object.keys(_M);
            let _error = {};

            for (let i = 0; i < lovKey?.length; i++) {

                _error[lovKey[i]] = false;
            }
            _M.error = _error;
            LOVKEYS = [...lovKey];
            return _M;
        })

        this.setState({
            ...state,
            lovKeyData: LOVKEYS,
            lovValueData: construct_LOV
        })
        if (this.props.isDimensionContext.IsDimension) {
            this.props.setDataTypeProperties("isdimensionedge", this.props.isDimensionContext.IsDimension, 'dimension')
        }
        console.log("this", editproperties, datalist)
        sessionStorage.setItem("editproperties", JSON.stringify(editproperties))
        sessionStorage.setItem("datalist", JSON.stringify(datalist))

    }
    shouldComponentUpdate(nextprops) {
        if (nextprops === this.props) {
            return false;
        }
        return true;
    }

    setValue = (name, value) => {
        debugger;
        if (!value || (name === 'type' && value?.length <= 0)) {
            let params = {
                saveopen: true,
                snackmsg: `Please select valid Data Type`,
                snacktype: 'info',
                autoHideDuration: 2000
            }
            this.props.errorValidation(params)
            return;
        }
        this.setState({
            [name]: value
        })
        if (name === 'datatype' && value === 'reference') {

            this.props.getCollectionList();
        }
        this.props.setValueJSON(name, value)
    }


    setBarcode = (name, value) => {
        debugger;
        if (!value || (name === 'type' && value?.length <= 0)) {
            let params = {
                saveopen: true,
                snackmsg: `Please select valid barcode`,
                snacktype: 'info',
                autoHideDuration: 2000
            }
            this.props.errorValidation(params)
            return;
        }
        this.setState({
            [name]: value
        })
        if (name === 'bar code ' && value === 'reference') {

            this.props.getCollectionList();
        }
        this.props.setBarcodeJSON(name, value)
    }










    changeTab(event, value) {
        this.setState({
            tabValue: value
        })
    }
    setArrayData(stateName, name, index, value) {
        let list = this.state[stateName];
        if (name) {
            list[index][name] = value;
        } else {
            list[index] = value;
        }
        this.setState({
            [stateName]: list
        })
        this.props.setValueJSON(stateName, list)
    }
    addNewValue(stateName, value) {
        let list = this.state[stateName];
        list.push(value);
        this.setState({
            [stateName]: list
        })
        this.props.setValueJSON(stateName, list)
    }
    removeAddedValue(stateName, index) {
        let list = this.state[stateName];
        list.splice(index, 1);
        this.setState({
            [stateName]: list
        })
        this.props.setValueJSON(stateName, list)
    }
    selectEntityColumn = (e, value, _entity) => {
        let { editproperties } = this.props;

        if (typeof value === 'undefined') {
            delete editproperties?.properties?.reference_keyFields[_entity.name]
            this.props.setDataTypeProperties("reference_keyFields", { ...editproperties?.properties?.reference_keyFields } ?? {}, 'property')

        } else {
            this.props.setDataTypeProperties("reference_keyFields", { ...editproperties?.properties?.reference_keyFields, [_entity.name]: value?.value } ?? {}, 'property')

        }
    }
    handleCloseKey = () => {
        this.setState((prevState) => ({
            ...prevState,
            isLovKey: false
        }));
    }
    isDimension = (value) => {
        if (value == false) {
            this.props.setDataTypeProperties("isdimensionedge", false, 'dimension')

        }

    }
    onKeySave = (keydata) => {
        let lovValueData = this.state.lovValueData;
        if (lovValueData?.length > 0) {
            lovValueData = lovValueData.map(_d => {
                let lovKey = Object.keys(_d);
                let _error = {};
                for (let i = 0; i < lovKey?.length; i++) {
                    if (keydata.indexOf(lovKey[i]) === -1) {
                        delete _d[lovKey[i]];
                    }
                }
                for (let i = 0; i < keydata?.length; i++) {
                    if (lovKey.indexOf(keydata[i]) === -1) {
                        _d[keydata[i]] = "";
                    };
                    _error[keydata[i]] = false;
                }
                _d.error = _error;
                return _d;
            })
        } else {
            let _push_struct = {};
            let error = {};
            let sample_data = keydata.map(_ => {
                _push_struct[_] = "";
                error[_] = false;
                return _;
            });
            _push_struct.error = error;
            lovValueData.push(_push_struct);
            let sentLovproperties = JSON.parse(JSON.stringify(lovValueData));
            sentLovproperties = sentLovproperties.map(_M => {
                delete _M.error;
                return _M;
            });
            this.props.setDataTypeProperties("LOV", sentLovproperties, 'property')
        }
        this.setState((prevState) => ({
            ...prevState,
            lovKeyData: keydata,
            lovValueData,
            isLovKey: false
        }));
    }
    openKeyDrawer = () => {
        this.setState((prevState) => ({
            ...prevState,
            isLovKey: true
        }));
    }
    handleCloseValue = () => {
        this.setState((prevState) => ({
            ...prevState,
            isLovValue: false
        }));
    }
    onValueSave = (keydata) => {
        this.setState((prevState) => ({
            ...prevState,
            lovValueData: keydata,
            isLovValue: false
        }));
        let sentLovproperties = JSON.parse(JSON.stringify(keydata));

        sentLovproperties = sentLovproperties.map(_M => {
            delete _M.error;
            return _M;
        })
        this.props.setDataTypeProperties("LOV", sentLovproperties, 'property')
    }
    openValueDrawer = () => {
        this.setState((prevState) => ({
            ...prevState,
            isLovValue: true
        }));
    }
    render() {


        let { classes, datalist, editproperties, collectionList, fieldProperties, entityLists, fieldLists } = this.props;
        let state = this.state;
        console.log("editproperties", this.props)

        return (

            <Grid container className={classes.root}>

                <Drawer anchor={"right"} open={state.isLovKey} onClose={this.handleCloseKey} >
                    <React.Fragment>
                        {state.isLovKey && <Keypopup onKeySave={this.onKeySave} lovKeyData={state.lovKeyData} handleCloseKey={this.handleCloseKey} />}
                    </React.Fragment>
                </Drawer>
                <Drawer anchor={"right"} open={state.isLovValue} onClose={this.handleCloseValue} >
                    <React.Fragment>
                        {state.isLovValue && <ValueDrawer onValueSave={this.onValueSave} lovKeyData={state.lovKeyData} lovValueData={state.lovValueData} handleCloseValue={this.handleCloseValue} />}
                    </React.Fragment>
                </Drawer>
                <Grid item md={12} style={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="caption" className={classes.headTitle}> <ListAltIcon className={classes.headIcon} /> Basic Properties</Typography>
                    <Typography style={{ fontSize: "1rem", marginLeft: "10px", overflowWrap: "break-word" }} variant="caption">
                        {
                            datalist?.editTable?.entity?.length <= 15 ? `Entity Name   => ${datalist?.editTable?.entity}` : <>
                                {
                                    <abbr title={datalist?.editTable?.entity} style={{ textDecoration: "none" }}>
                                        {
                                            `Entity Name   => ${datalist?.editTable?.entity?.slice(0, 15)}...`

                                        }

                                    </abbr>
                                    // `Entity Name   => ${datalist?.editTable?.entity}`

                                }
                                {/* <Tooltip title={datalist?.editTable?.entity} ><span>{datalist?.editTable?.entity?.slice(0,15)}</span> </Tooltip> */}

                            </>

                        }</Typography>
                    <Typography style={{ fontSize: "1rem", marginLeft: "10px", overflowWrap: "break-word" }} variant="caption">{
                        datalist?.editFieldData?.name?.length <= 15 ? `Entity Fields => ${datalist?.editFieldData?.name}` : <>
                            {
                                <abbr title={datalist?.editFieldData?.name} style={{ textDecoration: "none" }}>
                                    {
                                        `Entity Fields => ${datalist?.editFieldData?.name?.slice(0, 15)}...`

                                    }

                                </abbr>
                                // `Entity Name   => ${datalist?.editTable?.entity}`

                            }
                            {/* <Tooltip title={datalist?.editTable?.entity} ><span>{datalist?.editTable?.entity?.slice(0,15)}</span> </Tooltip> */}

                        </>

                    }</Typography>

                </Grid>
                <Grid item md={12} className={classes.spaceTop}>
                    <SelectBox
                        list={datalist.databaseProperties}
                        classname={classes.spaceBottom15}
                        onchange={(e, value) => {
                            this.setValue('datatype', value?.label ?? '')
                        }}
                        value={datalist.databaseProperties.filter(val => val.label === state.datatype)[0] ? datalist.databaseProperties.filter(val => val.label === state.datatype)[0] : null}
                        top_title='Data Type'
                        title='DataType'
                    />

                </Grid>
                {
                    state.datatype == "barcode" ? <>
                        <Grid item md={12} className={classes.spaceTop} >
                            <SelectBox
                                list={[...barcodeTypes]}
                                classname={classes.spaceBottom15}
                                onchange={(e, value) => { this.props.setDataTypeProperties("barcodetypes", value?.label ?? '', 'property') }}
                                value={{ label: editproperties?.properties?.barcodeType == '' ? 'UPC' : editproperties?.properties?.barcodeType, value: editproperties?.properties?.barcodeType == '' ? 'UPC' : editproperties?.properties?.barcodeType }}
                                top_title='Barcode Type'
                                title='select..'


                            />{
                                editproperties?.properties?.barcodeType == "UPC" || state.barcodeType == "UPC" ? <>
                                    <Typography > Font size </Typography>
                                    <TextField variant="outlined"
                                        size="small"
                                        inputProps={{ style: { height: "14px" } }}
                                        fullWidth={true}
                                        type="number"
                                        onChange={(e) => { this.props.setDataTypeProperties("fontsize", e.target.value, 'property') }}
                                        defaultValue={1}
                                        value={editproperties?.properties?.barcodeProp?.fontsize}

                                    //  onChange={}

                                    ></TextField>
                                    {editproperties.properties.barcodeProp.errorFontsize !== "" ?
                                        <span style={{ color: "red", fontSize: "12px" }}>{editproperties.properties.barcodeProp.errorFontsize}</span>
                                        :
                                        null

                                    }
                                </> : ''}
                            {editproperties?.properties?.barcodeType == "QRCODE" ? <>
                                <Typography size="small"> Width</Typography>
                                <TextField variant="outlined"
                                    size="small"
                                    inputProps={{ style: { height: "14px" } }}
                                    onChange={(e) => { this.props.setDataTypeProperties("barcode_width", e.target.value, 'property') }}
                                    fullWidth={true}
                                    defaultValue={100}
                                    value={editproperties?.properties?.barcodeProp?.width}
                                    type="number"></TextField>
                                <Typography> Height</Typography>
                                <TextField variant="outlined"
                                    size="small"
                                    inputProps={{ style: { height: "14px" } }}
                                    //  onChange={(e) => { this.props.setDataTypeProperties("defaultValue",e.target.value, 'property') }}
                                    fullWidth={true}
                                    onChange={(e) => { this.props.setDataTypeProperties("barcode_height", e.target.value, 'property') }}

                                    value={editproperties?.properties?.barcodeProp?.height}
                                    type="number">
                                </TextField>
                            </> : ''}

                        </Grid>
                    </> : ''}
                <Grid item md='12' sm='12' className={classes.spaceBottom15}>
                    {
                        // state?.datatype?.toLowerCase()==='boolean' ||  state?.datatype?.toLowerCase()==='time' || state?.datatype?.toLowerCase()==='datetime' || state?.datatype?.toLowerCase()==='date' || state?.datatype?.toLowerCase()==='instant' || state?.datatype?.toLowerCase()==='integer' || state?.datatype?.toLowerCase()==='positiveint' || state?.datatype?.toLowerCase()==='unsignedint' || state?.datatype?.toLowerCase()==='number' || state?.datatype?.toLowerCase()==='string' ?
                        typeof fieldProperties?.properties?.defaultValue !== 'undefined' ?
                            <>
                                {
                                    (!fieldProperties?.properties?.autoIncrement && !fieldProperties?.properties?.autoGenerate && state?.datatype?.toLowerCase() !== 'boolean') && <Typography variant="caption">Default Value</Typography>

                                }
                                {
                                    state?.datatype?.toLowerCase() === 'boolean' ? <>
                                        <FormControlLabel control={<Checkbox checked={editproperties?.properties?.defaultValue?.length > 0 ?? false} onChange={(e, value) => this.props.setDataTypeProperties("defaultValue", value, 'property')} name="checkedC" color="primary" />} label="Default Value" />

                                    </> : ''
                                }

                                {
                                    state?.datatype?.toLowerCase() === 'time' || state?.datatype?.toLowerCase() === 'datetime' || state?.datatype?.toLowerCase() === 'date' || state?.datatype?.toLowerCase() === 'instant' ? <>
                                        <TextField
                                            inputProps={{ style: { height: "14px" } }}
                                            id="min-length34"
                                            value={editproperties?.properties?.defaultValue || ''}
                                            // onChange={(e) => { this.props.setDataTypeProperties("defaultValue",e.target.value, 'property') }}
                                            fullWidth={true}
                                            disabled
                                            variant="outlined"
                                            size="small"
                                        />
                                        <Grid style={{ position: "relative" }}

                                        >
                                            <Button
                                                id="closeBtn"
                                                onClick={() => {
                                                    // alert('closeBtn');
                                                    this.props.setDataTypeProperties("defaultValue", '', 'property')
                                                }}
                                                style={{
                                                    height: "20px",
                                                    width: `${state?.datatype?.toLowerCase() === 'time' ? '30px' : '20px'}`,

                                                    //  position: "absolute",
                                                    right: "11px",
                                                    //  top: "-27px"
                                                }}
                                            >clear</Button>
                                        </Grid>
                                        <Grid style={{ position: "relative" }}>
                                            <TextField
                                                inputProps={{
                                                    style: {
                                                        height: "20px",
                                                        width: `${state?.datatype?.toLowerCase() === 'time' ? '30px' : '20px'}`,

                                                        position: "absolute",
                                                        right: "-266px",
                                                        top: "-48px"
                                                    }
                                                }}
                                                id="min-length"
                                                value={editproperties?.properties?.defaultValue}
                                                onChange={(e) => { state?.datatype?.toLowerCase() === 'time' ? this.props.setDataTypeProperties("defaultValue", e.target.value, 'property') : this.props.setDataTypeProperties("defaultValue", moment(e.target.value).format(editproperties?.properties?.format?.toUpperCase()), 'property') }}
                                                // onChange={(e) => { this.props.setDataTypeProperties("defaultValue",e.target.value, 'property') }}



                                                size="small"
                                                type={state?.datatype?.toLowerCase() === 'datetime' ? 'datetime-local' : state?.datatype?.toLowerCase() === 'instant' ? 'datetime-local' : state?.datatype?.toLowerCase()}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={(e) => {

                                                }}
                                                format={editproperties?.properties?.format?.toUpperCase()}
                                            />
                                        </Grid>

                                    </> : ''
                                }
                                {
                                    (!fieldProperties?.properties?.autoIncrement && !fieldProperties?.properties?.autoGenerate) && (state?.datatype?.toLowerCase() === 'integer' || state?.datatype?.toLowerCase() === 'positiveint' || state?.datatype?.toLowerCase() === 'unsignedint' || state?.datatype?.toLowerCase() === 'number' || state?.datatype?.toLowerCase() === 'string') ? <>
                                        <TextField
                                            inputProps={{ style: { height: "14px" } }}
                                            id="min-length"
                                            value={editproperties?.properties?.defaultValue}
                                            onChange={(e) => { this.props.setDataTypeProperties("defaultValue", e.target.value, 'property') }}
                                            fullWidth={true}

                                            variant="outlined"
                                            size="small"
                                        />
                                    </> : ''
                                }

                            </> : ''
                    }

                </Grid>
                {/* {(state?.datatype?.toLowerCase() === 'number' || state?.datatype?.toLowerCase() === 'integer' || state?.datatype?.toLowerCase()==='positiveint' || state?.datatype?.toLowerCase()==='unsignedint') && <Grid item md={12} className={classes.spaceTop}> */}
                {(fieldProperties?.properties?.IsLOV === true || fieldProperties?.properties?.IsLOV === false) && <Grid item md={12} className={classes.spaceTop} style={{ marginTop: 0 }}>


                    <Typography >LOV</Typography>
                    <FormControlLabel control={<Checkbox checked={editproperties?.properties?.IsLOV ?? false} onChange={(e, value) => this.props.setDataTypeProperties("IsLOV", value, 'property')} name="checkedC" color="primary" />} label="IsLOV" />

                    {
                        editproperties?.properties?.IsLOV ? <>
                            <Grid container className={classes.spaceBottom15} md={12} >

                                <Grid item md='6' sm='6' className={classes.spaceLeft5}>
                                    <SelectBox
                                        list={[
                                            {
                                                label: 'External',
                                                value: 'External'
                                            },
                                            {
                                                label: 'Internal',
                                                value: 'Internal'
                                            }
                                        ]}
                                        classname={classes.spaceBottom15}
                                        onchange={(e, value) => { this.props.setDataTypeProperties("LOVType", value?.label ?? '', 'property') }}
                                        value={{ label: editproperties?.properties?.LOVType, value: editproperties?.properties?.LOVType }}
                                        top_title='LOVType'
                                        title='LOVType'
                                    />
                                </Grid>
                                {
                                    editproperties?.properties?.LOVType?.toLowerCase() === 'internal' ? <>
                                        <Grid item md='6' sm='6' className={classes.spaceRight5}>
                                            <Typography variant="caption">LOV</Typography>
                                            {/* <TextField
                                                  inputProps={{ style: { height: "14px" } }}
                                                  id="LOV"
                                                  value={typeof editproperties?.properties?.LOV?.[0] === 'object' ? JSON.stringify(...editproperties?.properties?.LOV ?? '') : editproperties?.properties?.LOV?.[0]}
                                                  onChange={(e) => this.props.setDataTypeProperties("LOV", e.target.value, 'property')}
                                                  variant="outlined"
                                                  size="small"
                                              /> */}
                                            <Grid style={{ display: "flex" }}>
                                                <Button
                                                    onClick={() => this.openKeyDrawer()}
                                                    size='small'
                                                    className={classes.btnAction}
                                                    variant='contained'
                                                    color='primary'

                                                >
                                                    Key
                                                </Button>

                                                <Button
                                                    onClick={() => this.openValueDrawer()}
                                                    size='small'
                                                    //   className={classes.saveButtonPadding+ ' '+ classes.fontFormat}
                                                    variant='contained'
                                                    color='primary'
                                                    className={classes.btnAction}

                                                >
                                                    Value
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item md='6' sm='6' className={classes.spaceRight5}>
                                            <Typography variant="caption">LOV Value Fields</Typography>
                                            <TextField
                                                inputProps={{ style: { height: "14px" } }}
                                                id="LOV_value_fields"
                                                value={editproperties?.properties?.LOV_value_fields}
                                                onChange={(e) => this.props.setDataTypeProperties("LOV_value_fields", e.target.value, 'property')}
                                                variant="outlined"
                                                size="small"
                                            />
                                        </Grid>    <Grid item md='6' sm='6' className={classes.spaceRight5}>
                                            <Typography variant="caption">LOV Key Field</Typography>
                                            <TextField
                                                inputProps={{ style: { height: "14px" } }}
                                                id="LOV_key_field"
                                                value={editproperties?.properties?.LOV_key_field}
                                                onChange={(e) => this.props.setDataTypeProperties("LOV_key_field", e.target.value, 'property')}
                                                variant="outlined"
                                                size="small"
                                            />
                                        </Grid>
                                    </> : ''
                                }
                                {
                                    editproperties?.properties?.LOVType && editproperties?.properties?.LOVType?.toLowerCase() === 'external' ? <>
                                        <Grid item md='6' sm='6' className={classes.spaceLeft5}>
                                            <SelectBox
                                                list={[
                                                    ...entityLists ?? ''
                                                ]}
                                                classname={classes.spaceBottom15}
                                                onchange={(e, value) => { this.props.setDataTypeProperties("LOV_ref_collection", value?.label ?? '', 'property') }}
                                                value={{ label: editproperties?.properties?.LOV_ref_collection, value: editproperties?.properties?.LOV_ref_collection }}
                                                top_title='LOV Ref Collection'
                                                title='LOV Ref Collection'

                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' className={classes.spaceLeft5}>
                                            <SelectBox
                                                list={[
                                                    ...fieldLists ?? ''

                                                ]}
                                                classname={classes.spaceBottom15}
                                                onchange={(e, value) => { this.props.setDataTypeProperties("LOV_value_fields", value?.label ?? '', 'property') }}
                                                value={{ label: editproperties?.properties?.LOV_value_fields, value: editproperties?.properties?.LOV_value_fields }}
                                                top_title='LOV Value Fields'
                                                title='LOV Value Fields'

                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' className={classes.spaceLeft5}>
                                            <SelectBox
                                                list={[
                                                    ...fieldLists ?? ''
                                                ]}
                                                classname={classes.spaceBottom15}
                                                onchange={(e, value) => { this.props.setDataTypeProperties("LOV_key_field", value?.label ?? '', 'property') }}
                                                value={{ label: editproperties?.properties?.LOV_key_field, value: editproperties?.properties?.LOV_key_field }}
                                                top_title='LOV Key Field'
                                                title='LOV Key Field'

                                            />
                                        </Grid>

                                        {/* <Grid item md='6' sm='6' className={classes.spaceLeft5}>
                        
                      <FormControlLabel control={<Checkbox checked={editproperties?.properties?.Sequence?.IsReset ?? false} onChange={(e,value) => this.props.setDataTypeProperties("IsReset", value,'sequence')} name="checkedIS" color="primary" />} label="Is Reset" />
                          </Grid> */}
                                    </> : ''
                                }

                            </Grid>
                        </> : ''
                    }
                </Grid>}
                {fieldProperties?.properties?.CaseSensitive?.length >= 0 && <Grid item md={12} className={classes.spaceTop} style={{ marginTop: 0 }}>

                    <Typography >Case Sensitive</Typography>
                    <FormControlLabel control={<Checkbox checked={editproperties?.properties?.isCaseSensitive ?? false} onChange={(e, value) => this.props.setDataTypeProperties("isCaseSensitive", value, 'property')} name="checkedC1" color="primary" />} label="Is Case Sensitive" />

                    {
                        editproperties?.properties?.isCaseSensitive ? <>
                            <Grid container className={classes.spaceBottom15} md={12} >

                                <Grid item md='8' sm='8' className={classes.spaceLeft5}>
                                    <SelectBox
                                        list={[
                                            ...caseSensitiveData
                                        ]}
                                        classname={classes.spaceBottom15}
                                        onchange={(e, value) => { this.props.setDataTypeProperties("CaseSensitive", value?.label ?? '', 'property') }}
                                        value={{ label: editproperties?.properties?.CaseSensitive, value: editproperties?.properties?.CaseSensitive }}
                                        top_title='CaseSensitive Type'
                                        title='CaseSensitive Type'

                                    />
                                </Grid>
                            </Grid>
                        </> : ''
                    }

                </Grid>}
                <Typography >allow_lookup_data</Typography>
                <FormControlLabel control={<Checkbox checked={editproperties?.properties?.allow_lookup_data ?? false} onChange={(e, value) => this.props.setDataTypeProperties("allow_lookup_data", value, 'property')} name="checkedC1" color="primary" />} label="allow_lookup_data" />

                {
                    editproperties?.properties?.allow_lookup_data ? <>
                        <Grid container className={classes.spaceBottom15} md={12} >

                            <Grid item md='8' sm='8' className={classes.spaceLeft5}>
                                <TextField
                                    inputProps={{ style: { height: "14px" } }}
                                    id="lookup_referece_name"
                                    fullWidth={true}
                                    value={editproperties?.properties?.lookup_referece_name}
                                    onChange={(e) => this.props.setDataTypeProperties("lookup_referece_name", e.target.value, 'property')}
                                    variant="outlined"
                                    size="small"
                                    label="referece_name"
                                />
                            </Grid>
                        </Grid>
                    </> : ''
                }

                {/* <Typography >CaseSensitive_Match</Typography>
                            <Grid item md='8' sm='8' className={classes.spaceLeft5}>
                                <TextField
                                    inputProps={{ style: { height: "14px" } }}
                                    id="CaseSensitive_Match"
                                    fullWidth={true}
                                   type="number"
                                    value={editproperties?.properties?.CaseSensitive_Match}
                                    onChange={(e) => this.props.setDataTypeProperties("CaseSensitive_Match", e.target.value, 'property')}
                                    variant="outlined"
                                    size="small"
                                />
                                </Grid> */}

                {editproperties?.attributes?.Accuracy == true ?
                    <>
                        <Typography >Accuracy_Match</Typography>
                        <Grid item md='8' sm='8' className={classes.spaceLeft5}>
                            <TextField
                                inputProps={{ style: { height: "14px" } }}
                                id="Accuracy_Match"
                                fullWidth={true}
                                type="number"
                                value={editproperties?.properties?.Accuracy_Match}
                                onChange={(e) => this.props.setDataTypeProperties("Accuracy_Match", e.target.value, 'property')}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                    </> : null}
                {fieldProperties?.properties?.Pseducode?.length >= 0 && <Grid item md={12} className={classes.spaceTop} style={{ marginTop: 0 }}>

                    <Typography >Pseducode</Typography>
                    <FormControlLabel control={<Checkbox checked={editproperties?.properties?.IsPseducode ?? false} onChange={(e, value) => this.props.setDataTypeProperties("IsPseducode", value, 'property')} name="checkedC12" color="primary" />} label="Is Pseducode" />

                    {
                        editproperties?.properties?.IsPseducode ? <>
                            <Grid container className={classes.spaceBottom15} md={12} >

                                <Grid item md='8' sm='8' className={classes.spaceLeft5}>
                                    <SelectBox
                                        list={[
                                            ...psudoData
                                        ]}
                                        classname={classes.spaceBottom15}
                                        onchange={(e, value) => { this.props.setDataTypeProperties("Pseducode", value?.label ?? '', 'property') }}
                                        value={{ label: editproperties?.properties?.Pseducode, value: editproperties?.properties?.Pseducode }}
                                        top_title='Pseducode Type'
                                        title='Pseducode Type'

                                    />
                                </Grid>
                            </Grid>
                        </> : ''
                    }
                </Grid>}
                {/* {(editproperties?.properties?.Security?.Encryption) && <Grid item md={12} className={classes.spaceTop} style={{marginTop:0}}>
  
                      <Typography >Encryption</Typography>
                      <Grid container className={classes.spaceBottom15} md={12} >
                          <Grid item md='8' sm='8' className={classes.spaceLeft5}>
                          <SelectBox
                                  list={[
                                      ...encriptionTypeData
                                  ]}
                                  classname={classes.spaceBottom15}
                                  onchange={(e, value) => { this.props.setDataTypeProperties("Security",{Encryption:{Script:value?.label ?? ''}}, 'property') }}
                                  value={{label:editproperties?.properties?.Security?.Encryption?.Script,value:editproperties?.properties?.Security?.Encryption?.Script}}
                                  top_title='Encrypt Type'
                                  title='Encrypt Type'
  
                              />
                          </Grid>
                       </Grid>
                      
                      </Grid>} */}
                {
                    state?.datatype?.toLowerCase() === 'dimension' ?
                        <React.Fragment>
                            <Typography >Dimension Field</Typography>
                            <FormControlLabel control={<Checkbox checked={editproperties?.properties?.fieldCollection ?? false} onChange={(e, value, data) => this.props.setDataTypeProperties("fieldCollection", value, 'dimension')} name="checkedC12" color="primary" />} label="Field Collection" />
                            {
                                editproperties?.properties?.fieldCollection ? <React.Fragment></React.Fragment> :
                                    <React.Fragment>
                                        <Grid item md={12} className={classes.spaceTop} style={{ marginTop: 0 }}>
                                            {/* <Grid container className={classes.spaceBottom15} md={12} > */}
                                            <Grid item md='12' sm='8' className={classes.spaceLeft5}>
                                                <SelectBox
                                                    list={[
                                                        ...entityLists ?? ''
                                                    ]}
                                                    classname={classes.spaceBottom15}
                                                    onchange={(e, value) => { this.props.setDataTypeProperties("ref_entity", value?.label ?? '', 'dimension') }}
                                                    value={{ label: editproperties?.properties?.ref_entity, value: editproperties?.properties?.ref_entity }}
                                                    top_title='Ref Entity'
                                                    title='Ref Entity'

                                                />
                                            </Grid>
                                            {/* </Grid> */}
                                        </Grid>
                                        {
                                            !this.props.isedgeFieldCollection ?
                                                <Grid item md={12} className={classes.spaceTop} style={{ marginTop: 0 }}>
                                                    <FormControlLabel control={<Checkbox checked={editproperties?.properties?.isdimensionedge ?? false}
                                                        // onChange={(e, value) => this.isDimension(value)} 
                                                        name="checkedC123" color="primary" />} label="Is Dimension Edge" disabled={editproperties?.properties?.isdimensionedge ?? false} />

                                                </Grid>
                                                : ''
                                        }


                                    </React.Fragment>
                            }
                        </React.Fragment> : ''
                }
                {
                    // state?.datatype?.toLowerCase() === 'time' || state?.datatype?.toLowerCase() === 'datetime' || state?.datatype?.toLowerCase() === 'date' || state?.datatype?.toLowerCase() === 'instant' || state?.datatype?.toLowerCase() === 'instant' ?
                    typeof fieldProperties?.properties?.format !== 'undefined' ?

                        <React.Fragment>
                            <Grid item md='12' sm='12' className={classes.spaceBottom15}>
                                <Typography variant="caption">Format</Typography>
                                <TextField
                                    inputProps={{ style: { height: "14px" } }}
                                    id="format"
                                    fullWidth={true}
                                    value={editproperties?.properties?.format}
                                    //value={state?.datatype?.toLowerCase() === 'instant' ? 'dd/MM/yyyy HH:mm:ss' : editproperties?.properties?.format}
                                    onChange={(e) => this.props.setDataTypeProperties("format", e.target.value, 'property')}
                                    variant="outlined"
                                    size="small"
                                    disabled={state?.datatype?.toLowerCase() === 'instant' ? true : false}
                                />
                                {/* <span>{editproperties?.properties?.format}</span> */}
                            </Grid>
                        </React.Fragment> : ''

                }
                {
                    <React.Fragment>
                        {/* <Divider className={classes.dividerLine} /> */}
                        {
                            // state?.datatype?.toLowerCase() === 'string' ? 
                            fieldProperties?.properties?.length ?
                                <React.Fragment>
                                    <Grid md={12} >
                                        <Typography variant="subtitle2" className={classes.spaceTop} >Length</Typography>
                                        <Grid container className={classes.spaceBottom5} md={12} >
                                            <Grid item md='6' sm='6' className={classes.spaceRight5}>
                                                <Typography variant="caption">Min</Typography>
                                                <TextField
                                                    inputProps={{ style: { height: "14px" } }}
                                                    id="min-length"
                                                    value={editproperties?.properties?.length?.min}
                                                    onChange={(e) => this.props.setDataTypeProperties("length", { max: editproperties?.properties?.length?.max, min: e.target.value }, 'property')}
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    // onKeyDown={(e)=>(IsNumberKeyDown(e,true)) }
                                                    onInput={
                                                        (e) => {
                                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString();
                                                        }
                                                    }
                                                    onKeyDown={(evt) => (evt.which === 40 || evt.which === 38) && evt.preventDefault()}

                                                />
                                            </Grid>
                                            <Grid item md='6' sm='6' className={classes.spaceLeft5}>
                                                <Typography variant="caption">Max</Typography>
                                                <TextField
                                                    inputProps={{ style: { height: "14px" } }}
                                                    id="min-length"
                                                    value={editproperties?.properties?.length?.max}
                                                    onChange={(e) => this.props.setDataTypeProperties("length", { min: editproperties?.properties?.length?.min, max: e.target.value }, 'property')}
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    // onKeyDown={(e)=>(IsNumberKeyDown(e,true)) }
                                                    onInput={
                                                        (e) => {
                                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString();
                                                        }
                                                    }
                                                    onKeyDown={(evt) => (evt.which === 40 || evt.which === 38) && evt.preventDefault()}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </React.Fragment> : ''
                        }
                        {
                            //  state?.datatype?.toLowerCase() === 'number' || state?.datatype?.toLowerCase()==='unsignedint' || state?.datatype?.toLowerCase()==='integer' ? 
                            (typeof fieldProperties?.properties?.minValue !== 'undefined' || typeof fieldProperties?.properties?.maxValue !== 'undefined') ?

                                <React.Fragment>
                                    <Grid md={12} >
                                        <Typography variant="subtitle2" className={classes.spaceTop} >Value</Typography>
                                        <Grid container className={classes.spaceBottom15 + ' ' + classes.spaceBottom5} md={12} >
                                            <Grid item md='6' sm='6' className={classes.spaceRight5}>
                                                <Typography variant="caption">Min</Typography>
                                                <TextField
                                                    inputProps={{ style: { height: "14px" } }}
                                                    id="min-length"
                                                    value={editproperties?.properties?.minValue}
                                                    onChange={(e) => this.props.setDataTypeProperties("minValue", parseInt(e.target.value), 'property')}
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    // onKeyDown={(e)=>(IsNumberKeyDown(e,true)) }
                                                    onInput={
                                                        (e) => {
                                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString();
                                                        }
                                                    }
                                                    onKeyDown={(evt) => (evt.which === 40 || evt.which === 38) && evt.preventDefault()}
                                                />
                                            </Grid>
                                            <Grid item md='6' sm='6' className={classes.spaceLeft5}>
                                                <Typography variant="caption">Max</Typography>
                                                <TextField
                                                    inputProps={{ style: { height: "14px" } }}
                                                    id="min-length"
                                                    value={editproperties?.properties?.maxValue}
                                                    onChange={(e) => this.props.setDataTypeProperties("maxValue", parseInt(e.target.value), 'property')}
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    // onKeyDown={(e)=>(IsNumberKeyDown(e,true)) }
                                                    onInput={
                                                        (e) => {
                                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString();
                                                        }
                                                    }
                                                    onKeyDown={(evt) => (evt.which === 40 || evt.which === 38) && evt.preventDefault()}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </React.Fragment> : ''
                        }


                        {
                            //   state?.datatype?.toLowerCase() ==='array' || state?.datatype?.toLowerCase() ==='integer'  || state?.datatype?.toLowerCase() ==='positiveint' ||  state?.datatype?.toLowerCase() ==='unsignedint' || state?.datatype?.toLowerCase() ==='object'  || state?.datatype?.toLowerCase() ==='digitalobject' || state?.datatype?.toLowerCase() ==='string' || state?.datatype?.toLowerCase() ==='number'? <React.Fragment>
                            typeof fieldProperties?.properties?.mandatory !== 'undefined' ? <React.Fragment>
                                <Divider className={classes.dividerLine} />
                                <Grid md={12} >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={editproperties?.properties?.mandatory ?? false}
                                                onChange={(e, value) => this.props.setDataTypeProperties("mandatory", value, 'property')}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Mandatory Field"
                                    />
                                </Grid>
                            </React.Fragment> : ''
                        }
                        {
                            typeof fieldProperties?.properties?.reference_keyFields !== 'undefined' ?
                                <Grid md={12} >
                                    <Autocomplete
                                        multiple
                                        id="reference"
                                        options={typeof collectionList === 'object' ? collectionList : []}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => { this.props.setDataTypeProperties("selectCollectionList", value, 'property') }}
                                        value={editproperties?.properties?.selectCollectionList ?? []}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        // style={{ width: 500 }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Reference collections" placeholder="Reference collections" />
                                        )}
                                    />
                                </Grid>
                                : ''
                        }
                        {
                            (typeof fieldProperties?.properties?.reference_keyFields !== 'undefined' && editproperties?.properties?.selectCollectionList?.length > 0) ? <>
                                {
                                    editproperties?.properties?.selectCollectionList?.map(_entity => (
                                        <Grid item md='12' sm='12' className={classes.spaceLeft5}>
                                            <SelectBox
                                                list={this.props.filterCollectionName(_entity.name) ?? []}
                                                classname={classes.spaceBottom15}
                                                onchange={(e, value) => { this.selectEntityColumn(e, value, _entity) }}
                                                value={{ label: editproperties?.properties?.reference_keyFields[_entity.name], value: editproperties?.properties?.reference_keyFields[_entity.name] }}
                                                top_title={'Entity Fields Of' + ' ' + _entity.name}
                                                title='Entity Fields'

                                            />
                                        </Grid>
                                    ))
                                }
                            </> : ''
                        }
                        {
                            fieldProperties?.properties?.regexPattern?.length >= 0 &&
                            <Grid item md='12' sm='12' className={classes.spaceBottom15}>
                                <Typography variant="caption">Regex Pattern</Typography>
                                <TextField
                                    inputProps={{ style: { height: "14px" } }}
                                    id="regexPattern"
                                    fullWidth={true}
                                    value={editproperties?.properties?.regexPattern}
                                    onChange={(e) => this.props.setDataTypeProperties("regexPattern", e.target.value, 'property')}
                                    variant="outlined"
                                    size="small"
                                />
                            </Grid>
                        }
                    </React.Fragment>
                }
                <Divider className={classes.dividerLine} />
            </Grid>
        )
    }
}

export default withStyles(useStyles)(withAllContexts(BasicDetails));