import React from 'react';
import { Typography, Avatar, Icon, Popover } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { AddEntity } from '../../../components/entity';

const useStyles = ((theme) => ({

    topParent: {
        display: 'flex'
    },
    bottomParenticon: {
        display: 'flex',
        margin: "auto 10px 0px 10px"
    },
    topIcon: {
        margin: "12px auto",
        display: "inline",
        width: "auto",
        cursor: "pointer",
    },
    leftTitle: {
        fontSize: "13px",
        textAlign: "center",
        fontWeight: 600
    },
    bottomIcon: {
        margin: "12px auto",
        display: "inline",
        width: "auto",
        cursor: "pointer"
    },
    newIcon: {
        fontSize: "40px"
    },

}));

class MiniCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            anchorEl: null,
        }
        this.handleClose = this.handleClose.bind(this)
    }
    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    render() {
        let { classes, expandLeftCard } = this.props;
        let { anchorEl } = this.state;

        return (
            <React.Fragment>

                <div className={classes.topParent}>
                    <div className={classes.topIcon} onClick={() => expandLeftCard()}>
                        <Avatar style={{background: "#fafafb"}}><img src={require('../../../assets/icons-8-columns.svg')} /></Avatar>
                        <Typography className={classes.leftTitle}>Entity</Typography>
                    </div>
                </div>

                <div className={classes.bottomParenticon} onClick={this.handleClick}>
                    <div className={classes.bottomIcon}>
                        {/* <Icon color="secondary" className={classes.newIcon} >add_circle</Icon> */}
                        <img src={require('../../../assets/group-18.svg')} style={{position:"fixed", bottom:"20px"}} />
                        <Typography className={classes.leftTitle}>New</Typography>
                    </div>
                </div>
                <Popover
                    id={'add_entity'}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{ vertical: 'center', horizontal: 'right', }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                >
                    <AddEntity onclose={this.handleClose} />
                </Popover>
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(MiniCard);