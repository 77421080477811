 import React from 'react';
 import { Grid, ListItem, List, ListItemText, Typography } from '@material-ui/core';
 import { withStyles } from '@material-ui/core/styles';
 import { EntityLeftContext } from '../../../../contexts'
 const useStyles = ((theme) => ({
     root: {
         flexGrow: 1,
         padding: 0
     },
     listRoot: {
         flexGrow: 1,
         padding: "6px 5px"
     },
     listItem: {
         padding: "3px 8px",
         borderRadius: "7px",
         marginBottom: 5
     },
     listItemSelected: {
         background: theme.palette.primary.main
     },
     listItemText: {
         margin: "0px"
     },
     iconLeft: {
         marginLeft: "5px"
     },
     listicon: {
         height: "24px",
         '& svg': {
             width: "20px",
             color: "#5f5d5d"
         }
     },
     emptyData: {
         padding: "10px",
         fontSize: "12px",
         textAlign: "center"
     }
 }));
 
 class ListCard extends React.Component {
     constructor(props) {
         super(props)
         this.state = {
             open: null
         }
     }
     expandLeftCard(val) {
         let { setEntity } = this.context;
         setEntity({
             ...this.context,
             selected: val.id
         })
     }
     handleFieldName = (name)=>{
         if(typeof name ==='string'){
             let filter_name =   `${name.slice(0,15)}...`;
             return filter_name;
         } else {
             return  name;
         }
     }
     render() {
         let { classes, list, emptyContent, selected, isexpand, titlekey, isnostatus } = this.props;
         return (
             <Grid container className={classes.root}>
                 <List
                     component="nav"
                     aria-labelledby="nested-list-subheader"
                     className={classes.listRoot}
                 >
                     {
                         list && list.map((val, index) => {
                             return <ListItem key={index} onClick={isexpand ? () => this.expandLeftCard(val) : () => { }} button className={`${(selected && selected === val.id) ? classes.listItemSelected : ''}  ${classes.listItem}`}>
                                 {
                                     (val[titlekey ? titlekey : 'title']?.length<15 || typeof val[titlekey ? titlekey : 'title']  ==='object') ?
                                     <ListItemText secondaryTypographyProps={{ style: { color: (selected && selected === val.id) ? "white" : '', } }} secondary={val[titlekey ? titlekey : 'title']} />
                                     : 
                                     <abbr title={val[titlekey ? titlekey : 'title']}>
                                        <ListItemText secondaryTypographyProps={{ style: { color: (selected && selected === val.id) ? "white" : '', } }} secondary={this.handleFieldName(val[titlekey ? titlekey : 'title'])} />
                                        {/* <Typography style= {{color: (selected && selected === val.id) ? "white" : '' }} >{`${val[titlekey ? titlekey : 'title']?.slice(0,15)}...`} </Typography> */}
 
                                     </abbr>
 
                                 }
                                 {!isnostatus && <ListItemText secondaryTypographyProps={{ style: { color: (selected && selected === val.id) ? "white" : '', float: "right" } }} secondary={val.status ? val.status==='draft' ? 'Draft':'Published': 'Draft'} />}
                             </ListItem>
                         })
                     }
                     {
                         (!list || list === 0) && <div>
                             <Typography className={classes.emptyData}>{emptyContent ? emptyContent : `No Data`}</Typography>
                         </div>
                     }
                 </List>
             </Grid >
         )
     }
 }
 ListCard.contextType = EntityLeftContext;
 export default withStyles(useStyles)(ListCard);