

import React from "react";
// import { IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
// import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

function Text(props) {
  return (
    <TextField
      id="standard-basic"
      value={props.value}
      multiline
      autoFocus={true}
      autoComplete="off"
      variant="outlined"
      onChange={props.onChange}
      size="small"
      onKeyPress={props.onKeyPress}
      placeholder={props.placeholder}
      error={props.error}
      helperText={props.error ? "Textfield should not be empty" : ""}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            {/* <IconButton onClick={props.onClickadd}> */}
            <AddIcon
              onClick={props.onClickadd}
              style={{ color: "#9696a9", cursor: "pointer" }}
            />
            {/* </IconButton>
            <IconButton> */}
            <CloseIcon
              onClick={props.onClickdelete}
              style={{ color: "#9696a9", cursor: "pointer" }}
            />
            {/* </IconButton> */}
          </InputAdornment>
        ),
      }}
    />
  );
}
export default Text;
