import React from "react";
import { LinearProgress } from "@material-ui/core";
import { NetworkCall } from "./networkcall";
import config from "../src/config";
class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  refreshAPI = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let metadataIdFromURL = urlParams?.get("metadata_id");
    let metaDataId = localStorage.getItem("metaDataId");
    let tokenFromUrl = urlParams.get("token");
    let token = localStorage.getItem("token");
    if (!(tokenFromUrl && metadataIdFromURL)) {
      window.location.replace(config.qdm_admin_url);
    } else if (!(metaDataId && token)) {
      localStorage.setItem("metaDataId", metadataIdFromURL);
      localStorage.setItem("token", tokenFromUrl);
    } else if (tokenFromUrl !== token) {
      localStorage.setItem("token", tokenFromUrl);
    } else if (metadataIdFromURL !== metaDataId) {
      localStorage.setItem("metaDataId", metadataIdFromURL);
    }
  };
  componentDidMount() {
    // sessionStorage.setItem( "metaId", "8f762af2-14ba-4e09-bd22-2b1845175d2a")
    // sessionStorage.setItem( "metaId", "c6ab4ef9-7e15-482c-be57-21237e58f21a")
    localStorage.setItem("zindex",11)
    NetworkCall("meta.json", "GET", null, null, false, true)
      .then((_) => {
        localStorage.setItem("version", _.data.version);
      })
      .catch((err) => {
        console.log("err:", err);
      });
    this.refreshAPI();
  }

  render() {
    let { loading } = this.props;

    return (
      <>
        {loading && <LinearProgress />}
        {this.props.children}
      </>
    );
  }
}

export default AppAuth;
