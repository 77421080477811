import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './style.css';
const useStyles = makeStyles((theme) => ({
    custom_popover_parent: {
        position: "absolute",
        left: "0px",
        top: "0px",
        zIndex: 27,
        width: "100%",
        height: "100%"
    },
    custom_popover_child: {
        display: "inline-block",
        background: "white",
        position: "absolute",
        // left: "88px",
        // top: "23px",
        zIndex: 99999,
        boxShadow: "0px 0px 8px -1px #d6d2d2",
        borderRadius: '10px'
    }
}));
function CustomPopover({ classname, open, onclose, children }) {
    var target = document.getElementsByClassName('IframeData')[0].contentWindow.document.getElementsByClassName(classname)[0];
    // var target = document.getElementsByClassName(classname)[0];

    var left = 0, top = 0;
    if (target) {
        let client = target.getBoundingClientRect()
        left = client.x + client.width + 10
        top = client.y
    }
    const classes = useStyles();
    return (
        <React.Fragment>
            { open &&
                <React.Fragment>
                    <div onClick={() => onclose()} className={classes.custom_popover_parent}>
                    </div>
                    <div className={classes.custom_popover_child} style={{ top: top, left: left }}>
                        {children}
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default CustomPopover;