import React from "react";
import { RuleID, FinalDocu, Publishopen, PublishForEntity, IsDimensionContext } from "./contexts";


class AppRuleid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleid: "",
      finalDocu: "",
      isopen: true,
      IsDimension: false
      //  listOfPublish:[]
    };
  }

  close = () => {
    this.setState({
      ruleid: ""
    });
  };

  set = (props) => {
    this.setState({ ruleid: props });
  };

  closeFinal = () => {
    this.setState({
      finalDocu: ""
    });
  };

  setFinal = (props) => {
    this.setState({ finalDocu: props });
  };
  closeopen = () => {
    this.setState({
      isopen: false
    });
  }
  setopen = (props) => {
    this.setState({ isopen: props });
  }

  setdimen = (props) => {
    this.setState({ IsDimension: props })
  }
  // setpublishEntity=(props)=>{
  //   this.setState({listOfPublish:props})
  // }
  render() {
    return (
      <RuleID.Provider
        value={{
          ...this.state.ruleid,
          onclose: this.close,
          setRuleID: this.set,
        }}
      >
        <FinalDocu.Provider
          value={{
            ...this.state.finalDocu,
            oncloseFinalDocu: this.closeFinal,
            setFinalDocu: this.setFinal,
          }}
        >
          <Publishopen.Provider
            value={{
              // ...this.state.isopen,
              isopen: this.state.isopen,
              closeopen: this.closeopen,
              setisopen: this.setopen

            }}
          >
            <IsDimensionContext.Provider
              value={{
                IsDimension: this.state.IsDimension,
                setIsDimensionContext: this.setdimen
              }}
            >
              {this.props.children}

            </IsDimensionContext.Provider>
            {/* <PublishForEntity.Provider
            value={{
              ...this.state.listOfPublish,
              setPublish :this.setpublishEntity
            }}> */}

            {/* </PublishForEntity.Provider> */}

          </Publishopen.Provider>

        </FinalDocu.Provider>
      </RuleID.Provider>
    );
  }
}

export default AppRuleid;
