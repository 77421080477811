
import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import Icon from "./Icon";
import Medialibrary from "./Medialibrary";
import Personas from "./Personas";

import List from "@material-ui/core/List";

import { withAllContexts } from "../../../HOCs";

const useStyles = makeStyles((theme) => ({
  list: {
    padding: "0",
    borderRadius: 0,
  },

  librarytxt: {
    paddingLeft: 20,
    fontSize: "16px",

    // fontFamily: "fontSemiBold",
    color: "#110f47",
    textAlign: "left",
  },
}));

function Body(props) {
  // const { selectedArr } = props;
  const classes = useStyles();
  const { Data, setData } = props.datajson;
  // const theme = useTheme();
  // const [open, setOpen] = React.useState(false);

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [anchorep, setAnchorep] = React.useState(null);
  // const [anchorem, setAnchorem] = React.useState(null);

  // const handleClickicon = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClickpersona = (event) => {
  //   setAnchorep(event.currentTarget);
  // };
  // const handleClickmedia = (event) => {
  //   setAnchorem(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleCloseper = () => {
  //   setAnchorep(null);
  // };
  // const handleClosemed = () => {
  //   setAnchorem(null);
  // };

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  //   props.setisopen(true);
  // };

  // const handleDrawerClose = () => {
  //   props.setisopen(false);
  //   setOpen(false);
  // };

  // const opens = Boolean(anchorEl);
  // const id = opens ? "simple-popover" : undefined;

  // const openp = Boolean(anchorep);
  // const id1 = opens ? "simple-popover" : undefined;

  // const openm = Boolean(anchorem);
  // const id2 = opens ? "simple-popover" : undefined;

  // adding persona in persona list

  const [text, setText] = React.useState("");
  const [err, setErr] = React.useState(false);
  const [anchorElP, setAnchorElP] = React.useState(null);

  const popen = Boolean(anchorElP);
  const idp = popen ? "list-popover" : undefined;

  const handleCloseP = () => {
    setAnchorElP(null);
    SetUpload("");
    setEdit("");
  };

  const handleClickP = (e) => {
    e.stopPropagation();
    setAnchorElP(e.currentTarget);
    setText("");
    SetUpload("");
  };

  const textChange = (e) => {
    
    setText(e.target.value);
    setErr(false);
  };

  const addpersona = (e) => {
    e.stopPropagation();
    
    if (edit === "edit") {
      // var persona = {
      //   id: personacount,
      //   // imgUrl: "https://picsum.photos/200/300?random=" + personacount,
      //   imgUrl: upload ? upload : text,
      //   name: text,
      // };
      Data.Personas[personaindex].name = text;
      Data.Personas[personaindex].imgUrl = upload;
      setText("");
      SetUpload("");
      setData({ Data });
      setAnchorElP(null);

      setEdit("");
    } else {
      if (text === "") {
        setErr(true);
      } else {
        let personacount = Data.Personas.length + 1;
        var persona = {
          id: personacount,
          // imgUrl: "https://picsum.photos/200/300?random=" + personacount,
          imgUrl: upload ? upload : text,
          name: text,
        };
        Data.Personas.push(persona);
        setText("");
        SetUpload("");
        setData({ Data });
        setAnchorElP(null);
      }
    }
  };

  const keypresspersona = (e) => {
    if (e.key === "Enter") {
      addpersona(e);
    }
  };

  const [edit, setEdit] = React.useState("");
  const [personaindex, setPersonaindex] = React.useState("");
  // edit Persona
  const handleChange = (e, v, url, name, i) => {
    
    handleClickP(e);
    setText(name);
    SetUpload(url);
    setEdit(v);
    setPersonaindex(i);
  };

  // adding media function
  const onClickMedia = (e) => {
    e.stopPropagation();
    let mediacount = Data.Medialibrary.length + 1;
    var media = {
      id: mediacount,
      imgUrl: "https://picsum.photos/200/300?random=" + mediacount,
    };
    Data.Medialibrary.push(media);
    setData({ Data });
  };
  // 10px 6px

  // accordion open

  const [openA, setOpena] = React.useState("");

  const handleClickA = (v) => {
    
    setOpena(v);
    if (openA === v) {
      setOpena(false);
    }
  };

  const [upload, SetUpload] = React.useState("");

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      SetUpload(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Grid container direction="column">
      <Grid container direction="column">
        <div>
          <p className={classes.librarytxt}>Library</p>
        </div>
        <List className={classes.list}>
          <Icon openA={openA} handleClickA={handleClickA} />
        </List>
        <List className={classes.list}>
          <Personas
            upload={upload}
            handleImageChange={handleImageChange}
            id={idp}
            open={popen}
            anchorEl={anchorElP}
            handleClickP={handleClickP}
            keypresspersona={keypresspersona}
            handleClose={handleCloseP}
            onClickPersona={addpersona}
            handleChange={handleChange}
            className={classes.pers}
            text={text}
            textChange={textChange}
            err={err}
            openA={openA}
            value={upload}
            handleClickA={handleClickA}
          />
        </List>
        <List className={classes.list}>
          <Medialibrary
            openA={openA}
            handleClickA={handleClickA}
            onClickMedia={onClickMedia}
          />
        </List>
      </Grid>
    </Grid>
  );
}

export default withAllContexts(Body);
