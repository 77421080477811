import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemText, Checkbox, List, ListItem, ListItemIcon } from '@material-ui/core';
import AdjustRoundedIcon from '@material-ui/icons/AdjustRounded';
import RadioButtonCheckedSharpIcon from '@material-ui/icons/RadioButtonCheckedSharp';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));
const filterValue = [
    { label: "All", value: 1 },
    { label: "Draft", value: 2 },
    { label: "Published", value: 3 },
]
export default function EntityFilter() {
    const classes = useStyles();
    const [value, setValue] = React.useState(1);
    const handleChange = (value) => {
        setValue(value);
    };
    return (
        <List className={classes.root}>
            {filterValue.map((val) => {
                return (
                    <ListItem key={val} role={undefined} dense button onClick={() => handleChange(val.value)}>
                        <ListItemIcon>
                            <Checkbox
                                icon={<AdjustRoundedIcon fontSize="small" />}
                                checkedIcon={<RadioButtonCheckedSharpIcon fontSize="small" />}
                                checked={val.value === value}
                                fontSize="small"
                            />
                        </ListItemIcon>
                        <ListItemText secondary={val.label} />
                    </ListItem>
                );
            })}
        </List>
    );
}