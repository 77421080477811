import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Divider, Drawer, Button, DialogActions, DialogContent, DialogTitle, Dialog, List, ListItem, DialogContentText } from '@material-ui/core';
import { AddEntity, EntityFieldList, ListCard, AddField, CustomPopover, AddRelationShip } from '../../';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Stack from '@mui/material/Stack';
import RuleID from "../../../screens/rulepage";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { withAllContexts } from '../../../HOCs';
import { RightSideNav, } from '../../../contexts'
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import InputRoundedIcon from '@material-ui/icons/InputRounded';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SaveAltIcon from '@material-ui/icons/Save';
import PublishAltIcon from '@material-ui/icons/Publish';
import { removeData, defaultFieldAdd } from "../../../functions/common";
import { relationSplit, grouprelationSplit } from '../../../functions';
import environment from '../../../config';
import { parse, stringify } from 'flatted';
import { Alerts } from '../../../components';
import { converData } from '../../../contexts/entity'
import axios from 'axios';
import { isLastDayOfMonth } from 'date-fns';
import { width } from '@mui/system';
import * as converter from "to-json-schema";
import { async } from 'mdm-rule-builder';
const useStyles = ((theme) => ({
    paper: {
        // padding: "15px",
        cursor: "auto",
        borderRadius: "9px",
        width: "360px",
        // marginLeft:"28px"

    },
    title: {
        fontWeight: 600,
        display: "inline-block"
    },
    titleCard: {
        cursor: "move",
        color: "white",
        // borderRadius: "9px",
        borderTopLeftRadius: "9px",
        borderTopRightRadius: "9px",
        padding: "10px 20px"
    },
    entityEditIcon: {
        width: "30px",
        height: "18px",
        marginLeft: "3px",
        cursor: "pointer"
    },
    status: {
        float: 'right',
        "webkitTouchCallout": "none",
        "webkitUserSelect": "none",
        "khtmlUserSelect": "none",
        "mozUserSelect": "none",
        "msUserSelect": "none",
        "userSelect": "none"
    },
    addText: {
        display: "inline"
    },
    notificationIcon: {
        marginLeft: "5px",
        background: "#c3c3c7",
        color: "white",
        borderRadius: "50%",
        width: "18px",
        height: "18px !important"

    },
    notificationFont: {
        fontSize: "12px",
        fontWeight: "bold",
        textAlign: "center"
    },
    collapseIcon: {
        position: "absolute",
        left: "0px"
    }
}));

class MiddleExpandCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            listofcollapsedublicate: this.props.collapseForEntity.listOfCollapse,
            collapseentity: this.props.collapse,
            anchorEl: null,
            fieldAnchorEl: null,
            editData: null,
            left: [],
            top: [],
            isAdd: null,
            relationData: null,
            relationDataField: null,
            openCollapse: [],
            property: "",
            saveopen: false,
            snackopen: false,
            snackmsg: 'Please select field first',
            snacktype: 'error',
            arr: [],
            entitycode: [],
            random: 0,
            opencard: [],
            saveopen: { open: false, savedata: null, savee: null },
            publishopen: { open: false, savedata: null, savee: null },
            zindex: Number(localStorage.getItem("zindex")),
            documents: [],
            // aligntrue:false

        }
        this.publishid = this.publishid.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleFieldClose = this.handleFieldClose.bind(this)
        this.mouseDown = this.mouseDown.bind(this)
        this.mouseMove = this.mouseMove.bind(this)
        this.mouseUp = this.mouseUp.bind(this)

    }


    componentDidMount() {


        let { length } = this.props
        let { entitycode } = this.props
        let { publishForEntity } = this.props
        let { listOfPublish, setListofPublishlist } = publishForEntity
        // var arr = [];
        // console.log("leng",length.length)

        for (let i = 1; i <= length?.length; i++) {
            this.setState({ arr: this.state.arr.push({ 1: i * 100 }) })
        }
        this.setState({ top: this.state.arr, })
        // console.log(this.state.editData,"openedit")
        let { data, singleArray } = this.props
        setListofPublishlist({
            listOfPublish: singleArray
        })
        let editt = listOfPublish.filter(x => x == data.attributes.id)

        // listOfPublish=singleArray
        // let allentitycode=data.entitycode
        // for (let i=data;i<=data.entitycode i++){
        // this.setState({entitycode:this.state.entitycode.push(allentitycode)}) 
        //  }


        // let allentitycode=data.entitycode

        localStorage.setItem("allentitycode", JSON.stringify(entitycode));




        // var idcollapse=data.attributes.id;

        // this.setState({listofcollapsedublicate:idcollapse})

        // console.log("value",data.attributes.type.value)


        // let { leftContext } = this.props
        // let { list, setEntity } = leftContext;
        // let {tableIndex}=this.props

        // list[data.attributes.type.value].map((val, i) => {
        //     if (val.attributes.id === data.attributes.id) {
        //         list[data.attributes.type.value][i].attributes["left"] = data.attributes.type.label=="Personas"?"20px":data.attributes.type.label=="Things"?"400px":data.attributes.type.label=="Ecosystem"?"780px":data.attributes.type.label=="Products and Services"?"1160px":data.attributes.type.label=="Others"?"1542px":null
        //         list[data.attributes.type.value][i].attributes["top"] = this.state.top[tableIndex]?.[1]
        //     }
        //     return false
        // })

        var newArray = length.concat





        //         var {data}=this.props
        //         if(data.attributes.type.label=="Personas"){
        // console.log("datap",data)

        //         }else if(data.attributes.type.label=="Ecosystem"){
        // console.log("datae",data)
        //         }else if(data.attributes.type.label=="Others"){
        // console.log("datao",data)
        //         }

        // for (let i=1;i<=length?.length;i++){
        //     arr1.push({1:i*40})
        //     }

        // console.log("length",arr)

        //     for (var i=0; i<=length?.length; i++) {
        //         if (i === 0) {
        //                 console.log(i +  "even");
        //          }
        //         else if (i % 2 === 0) {
        //                 console.log(i + "even");
        //                 console.log(i*100,"console.log") 
        //                 // arr.push({1:i*80})                     
        //         }
        //         else {
        //                 console.log(i + "odd");
        //                 console.log(i*10,"console.log")
        //                 // arr1.push({1:i*40}) 
        //         }
        // }




        var a = [1, 4, 7, 9, 11, 14], count = a[a.length - 1];
        var missing = [];
        for (var i = 1; i <= count; i++) {
            if (a.indexOf(i) == -1) {
                missing.push(i);
            }
        }
        console.log("missing", missing);

        // if(this?.props?.collapseForEntity?.listOfCollapse?.length>0){
        //     // alert('cechk')
        //     this.setState(prevState=>({
        //         ...prevState,
        //         openCollapse:this?.props?.collapseForEntity?.listOfCollapse
        //     }));
        // }
    }
    componentDidUpdate(prevProps, prevState) {
        console.log("prevprops", prevProps.collapse, prevState.collapseentity)
        if (prevProps.collapse !== this.props.collapse) {
            this.setState({ collapseentity: !this.state.collapseentity })
            this.handleCollapse("", "", this.props.collapse)
        }
        console.log("collapseentity", this.props.collapse)

    }
    // shouldComponentUpdate

    closeSnackBar = () => {
        this.setState({ snackopen: false, open: false, saveopen: false })
    }
    handleClose = () => {

        this.setState({ anchorEl: null, entityData: null, isAdd: null })
    }
    handleClick = (event, data) => {
        debugger
        console.log("id", data.attributes.id)
        this.setState({ anchorEl: event, entityData: data })

        let ruledatas = data
        // console.log("ruledatas",ruledatas)
        // sessionStorage.setItem("ruledata",btoa(JSON.stringify( ruledatas)))
        // console.log("")


        // const [documents, setDocuments] = useState([]);
        // let _ruledata=atob(sessionStorage.getItem("ruledata"));

        // let ruledata=JSON.parse(_ruledata);
        //  console.log("rule888",ruledata)
        let clienddb = sessionStorage.getItem("clientDB");
        let payload = {

            "db_name": clienddb,
            "entity": ruledatas.entity,

        }
        // console.log("ruledatas",ruledatas.entitycode)
        let RoleId = {


            "db_name": "ATP_Metadata_Dev",
            "entity": "entity_fields_published",
            // "filter": "entity_fields_published.entitycode == 'c9038e2f-46f3-482b-b120-8fd7442466de'&&entity_fields_published.entity=='Patient'",
            "filter": `entity_fields_published.entitycode == '${ruledatas.entitycode}'&&entity_fields_published.entity=='${ruledatas.entity}'`,
            "return_fields": "entity_fields_published.RuleID"

        }
        const finalschema = async (input, entityname) => {
            // debugger;
            let convertedschema = converter(input);


            return {
                ...convertedschema, "$schema": "http://json-schema.org/draft-04/schema#", entity: entityname, "required": [
                ],
            };

        };


        const fetchData = async () => {
            await axios.post(`https://arangodbservice.dev.ainqaplatform.in/api/GetMetaDataSampleJson`, { ...payload, metadata_dbname: environment.database }).then(async (res) => {

                await axios.post(` https://arangodbservice.dev.ainqaplatform.in/api/read_documents`, { ...RoleId }).then(async (res) => {
                    console.log("roleiddd", res.data.result)
                    let ruleId = res.data.result

                    this.props.ruleID.setRuleID({
                        ...this.props.ruleID,
                        ruleid: res.data.result
                    })

                    // console.log("finalrule",ruleId)
                    // sessionStorage.setItem("finalrule",JSON.stringify(ruleId))//two
                })
                // console.log("fetch",res.data[0].doc)
                // console.log("enttt",res.data[0].entity)
                // let s= await finalschema(res.data[0].doc, res.data[0].entity)


                //  

                let _document = await finalschema(res.data[0].doc, res.data[0].entity)
                let doubledocument = [_document]
                // let s=  JSON.parse(sessionStorage.getItem("FinalDocuments"))
                let document = doubledocument.map((value) => {
                    let newProperties = Object.keys(value.properties).reduce((accum, currentValueKey) => {
                        return { ...accum, [`${currentValueKey}`]: { type: "double" } }
                    }, {})
                    return { ...value, properties: newProperties }
                })
                console.log("fetchdocu", document)
                // this.state.documents.push(document)
                this.props.finalDocu.setFinalDocu({
                    ...this.props.finalDocu,
                    finalDocu: document
                })
                // sessionStorage.setItem("FinalDocuments",JSON.stringify(document))//one




                console.log("double", _document)
            })

        }

        fetchData()
        // console.log("this.props",this.props.publishopen.closeopen())
        // console.log("thir",this.props.publishopen.isopen )
        // console.log("this.state.documents",this.state.documents)
        // console.log("88",JSON.stringify( this.state.documents))
        // sessionStorage.setItem("FinalDocuments",JSON.stringify( this.state.documents))
    }
    handleFieldClose = () => {
        this.setState({ fieldAnchorEl: null, editData: null, isAdd: null })
    }
    handleFieldClick = (event) => {
        this.setState({ fieldAnchorEl: event })
    }
    handleRealatioShipClose = () => {
        this.setState({ relationshipAnchorEl: null })
    }
    handleRealatioShipClick = (event, relation) => {
        let context = this.context;
        let { handleRealatioShipOpen, data = {} } = this.props;
        handleRealatioShipOpen(event, data, relation)
    }

    handleGroupRealatioShipClose = () => {
        this.setState({ grouprelationshipAnchorEl: null })
    }
    handleGroupRealatioShipClick = (event, relation) => {
        let context = this.context;
        let { handleGroupRealatioShipOpen, data = {} } = this.props;
        handleGroupRealatioShipOpen(event, data, relation)
    }
    openEdit = (event, val, isAdd) => {
        // console.log("not active", isAdd)
        this.setState({ fieldAnchorEl: event, editData: val, isAdd: isAdd })

    }
    saveclose = () => {
        this.setState({ saveopen: { open: false } })
    }
    publishclose = () => {
        this.setState({ publishopen: { open: false } })
    }
    saveok = () => {

        this.Save_Entity(this.state.saveopen.savee, this.state.saveopen.savedata)
        this.setState({ saveopen: { open: false } })
    }

    publishok = () => {

        this.Publish_Entity(this.state.publishopen.savee, this.state.publishopen.savedata)
        this.setState({ publishopen: { open: false } })
    }
    saveokopen = (e, data) => {
        this.setState({
            saveopen: {
                open: true,
                savedata: data,
                savee: e

            }
        })
    }

    publishokopen = (e, data) => {
        this.setState({
            publishopen: {
                open: true,
                savedata: data,
                savee: e

            }
        })
    }
    mouseDown(e) {
        this.parentRef.style.zIndex = 100;
        this.hitOffset = {
            x: 132,
            y: 26,
            clientx: e?.clientX,
            clienty: e?.clientY
        };
        // this.canvasRef.addEventListener('mousemove', this.mouseMove);
        document.getElementsByClassName("IframeData")[0].contentDocument.addEventListener('mousemove', this.mouseMove);
        document.getElementsByClassName("IframeData")[0].contentDocument.addEventListener('mouseup', this.mouseUp);
        //   document.addEventListener('mousemove', this.mouseMove);
        // document.addEventListener('mouseup', this.mouseUp);

        // this.canvasRef.addEventListener('mouseup', this.mouseUp);
    }
    mouseMove(e) {
        // alert("the data")
        let { data = {}, } = this.props;
        let downx = this.hitOffset.clientx;
        let downy = this.hitOffset.clienty;
        if ((downx !== e.pageX) && (e.pageY !== downy)) {
            let classData = document.getElementsByClassName('IframeData')[0].contentWindow.document.getElementsByClassName("drag-entity" + data?.attributes?.id)[0]
            // let classData = document.getElementsByClassName("drag-entity" + data?.attributes?.id)[0]

            classData.style.left = (e.pageX - this.hitOffset.x) + "px";
            classData.style.top = (e.pageY - this.hitOffset.y) + "px";
            const { drawLine } = this.props;
            let { setCount, clearCount } = drawLine;
            setCount({
                ...drawLine,
                clearCount: clearCount + 1
            })
        }
    }
    mouseUp(e) {
        // debugger
        this.parentRef.style.zIndex = 1;
        const { drawLine } = this.props;
        let { setCount, count } = drawLine;
        let downx = this.hitOffset.clientx;
        let downy = this.hitOffset.clienty;
        let left = (e.pageX - this.hitOffset.x)
        let top = (e.pageY - this.hitOffset.y)
        if ((downx !== e.pageX) && (e.pageY !== downy)) {
            this.setToContext(left, top)
            setCount({
                ...drawLine,
                count: count + 1
            })
        }
        document.getElementsByClassName("IframeData")[0].contentDocument.removeEventListener('mousemove', this.mouseMove);
        document.getElementsByClassName("IframeData")[0].contentDocument.removeEventListener('mouseup', this.mouseUp);
        // document.removeEventListener('mousemove', this.mouseMove);
        // document.removeEventListener('mouseup', this.mouseUp);
    }
    setToContext(left, top) {
        let { leftContext, data } = this.props
        let { list, setEntity } = leftContext;
        console.log("left", left, "", "top", top)
        list[data.attributes.type.value].map((val, i) => {
            if (val.attributes.id === data.attributes.id) {
                list[data.attributes.type.value][i].attributes["left"] = left
                list[data.attributes.type.value][i].attributes["top"] = top;
            }
            return false
        })
        setEntity({
            ...leftContext,
            list
        })
    }
    selectEntity(entity) {
        let context = this.context;
        // sessionStorage.setItem('metadataId',entity.metadataId);
        sessionStorage.setItem('entityCode', entity.entitycode);
        sessionStorage.setItem('entity', entity.entity);

    }
    handleedit() {
        this.props.alert.setSnack({
            open: true,
            msg: "Please finish the Save & Publish",
            severity: 'info'
        });

    }
    deleteClick(e, data) {
        const { confirmationContext } = this.props;
        const { setConfirm } = confirmationContext;
        setConfirm({
            ...confirmationContext,
            open: true,
            title: "Confirmation",
            dialog: "Are you sure to delete this entity ?",
            type: 1,
            entity: data,
            data
        })
    }
    countFields = (list, countList = { _d: 0, _f: 0 }) => {

        list.map(dataSet => {
            // debugger
            if (dataSet.fieldType === 'd') {
                countList._d = countList._d + 1;
                if (dataSet?.properties?.fields) {
                    countList = this.countFields(dataSet?.properties?.fields, countList)
                } else if (dataSet?.fields) {
                    countList = this.countFields(dataSet?.fields, countList)

                }
            } else if (dataSet?.fieldType === 'f') {
                countList._f = countList._f + 1;

            }

            else if (["SourceSystemID", "SourceSystemRefId"].includes(dataSet.name)) {
                // debugger
                countList._f = countList._f + 1;

            }
            return false;
        })
        return countList;
    }
    // finalpub=(id)=>{
    //     debugger
    // return id==id
    // }
    publishid = (id, action) => {
        console.log("oooo", id, "", action)
        debugger
        let { publishForEntity } = this.props
        let { listOfPublish, setListofPublishlist } = publishForEntity

        if (action === "open") {

            const found = listOfPublish.find(element => element == id);
            if (!found) {
                console.log("newww", found)
                listOfPublish.push(id)

            } else {
                console.log("neww", found)
            }


        } else if (action === "close") {
            let listOfPubli = listOfPublish.filter(_filter => _filter !== id)
            console.log("listOfPublish", listOfPubli)
            setListofPublishlist({
                ...publishForEntity,
                listOfPublish: listOfPubli
            })
        }
    }


    handleCollapse = (id, action, allcolapse) => {
        debugger

        // this.props.collapse(id ,action)
        console.log("allcolapse", allcolapse)

        let { collapseForEntity } = this.props
        let { listOfCollapse, setListOfEntityCollaps } = collapseForEntity;
        // if(allcolapse){
        //     listOfCollapse=this.state.listofcollapsedublicate
        //     console.log("1stone")
        //     // console.log("ddddd",listOfCollapse);
        //     // console.log("state",this.state.listofcollapsedublicate)
        // }
        if (action === 'open') {
            listOfCollapse.push(id);
            let indexz = Number(localStorage.getItem("zindex"))
            localStorage.setItem('zindex', indexz + 1)
            this.setState({ ...this.state, zindex: indexz })
            // this.setState({opencard:[...this.state.opencard,id]})
        } else if (action === 'close') {
            // const updatedeItems=this.state.opencard.filter(_filter => _filter !== id)
            listOfCollapse = listOfCollapse.filter(_filter => _filter !== id)
            var idd = id
            this.setState({ opencard: idd })
            let indexz = Number(localStorage.getItem("zindex"))
            localStorage.setItem('zindex', indexz + 1)
            // this.setState({ ...this.state.zindex,zindex: this.state.zindex + 1 });
            this.setState({ ...this.state, zindex: indexz })
            // console.log("else list")
            // console.log("id",id)

            // listOfCollapse.push(id);

        }
        // console.table("1one",listOfCollapse)

        if (allcolapse) {
            let { singleArray } = this.props
            // console.table("1one",listOfCollapse)
            // this.setState({listofcollapsedublicate:id})
            listOfCollapse = singleArray
            // console.log("2ones",listOfCollapse,)
            // if(data)

            // console.log("ddddd",listOfCollapse);
            // console.log("state",this.state.listofcollapsedublicate)
        }
        setListOfEntityCollaps({
            ...collapseForEntity,
            listOfCollapse
        });
        // console.log("handlecolll",listOfCollapse)
    }



    checkIndexFields = (field, index_ids, fieldpath = {}) => {
        ;

        for (let i = 0; i < field.length; i++) {
            if (!fieldpath[i]) {
                fieldpath[i] = { error: false, path: [] };

            }

            if (field[i].attributes !== undefined) {
                if (index_ids.indexOf(field[i].attributes.id) > -1) {
                    fieldpath[i].path.push(field[i].name);
                    fieldpath[i].error = true;

                    return fieldpath
                }
            }
            if (field[i].fieldType === 'd') {

                fieldpath[i].path.push(field[i].name);
                this.checkIndexFields(field[i].properties.fields, index_ids, fieldpath);
            }
        }
        return fieldpath
    }

    validatePath = [];
    is_diamention_error = false;
    is_Sequence_error = false;
    // is_ResetType_error = false;

    validateDiamention = (fields) => {
        for (let i = 0; i < fields.length; i++) {
            let pathData = this.fieldCheck(fields[i].fields);
            for (let pat in pathData) {
                if (pathData[pat].error) {
                    this.validatePath = pathData[pat].path
                    this.validatePath.unshift(fields[i].entity)
                }
            }
            if (this.is_diamention_error) {
                break;
            }

            else if (this.is_Sequence_error) {
                break;
            }
            // else if (this.is_groupBy_error) {
            //     break;
            //   }
        }
    }

    validateIndexs = (fields) => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i]?.indexes?.length > 0) {
                let index_ids = fields[i].indexes.filter(_ => _.indexType.length <= 0).map(_m => _m.fieldId);
                ;

                let pathData = this.checkIndexFields(fields[i].fields, index_ids);
                ;

                let is_error = false;
                let errorPath = [];
                for (let pat in pathData) {
                    if (pathData[pat].error) {
                        errorPath = pathData[pat].path;
                        errorPath.unshift(fields[i].entity);
                        is_error = true;
                    }
                }
                if (is_error) {
                    return {
                        is_error: is_error,
                        path: errorPath
                    }
                }
            }

        }
        return {
            is_error: false,
            path: []
        }
    }

    fieldCheck = (field, fieldpath = [], parent = null) => {
        for (let i = 0; i < field.length; i++) {
            if (!parent) {
                fieldpath[i] = { error: false, path: [] };
                parent = i;
            }

            if (field[i].fieldType === 'd') {
                if (field[i].properties.fieldCollection === false) {
                    if (field[i].properties.isdimensionedge === true || field[i].properties.ref_entity.length > 0) {
                        fieldpath[parent].path.push(field[i].name);
                        this.fieldCheck(field[i].properties.fields, fieldpath, parent);
                    } else {
                        fieldpath[parent].error = true;
                        fieldpath[parent].path.push(field[i].name);
                        // this.is_diamention_error = true;
                        break;
                    }
                } else {
                    fieldpath[parent].path.push(field[i].name);
                    this.fieldCheck(field[i].properties.fields, fieldpath, parent);

                }
                // this.validatePath[i] = fieldpath;

            }

            else if (field[i].properties.datatype === "string" || field[i].properties.datatype === "number" || field[i].properties.datatype === "integer" || field[i].properties.datatype === "positiveint" || field[i].properties.datatype === "unsignedint") {

                if (field[i].properties.Sequence !== undefined) {
                    if (field[i].properties.Sequence.IsReset === true) {
                        if (field[i].properties.Sequence.reset_config !== undefined) {
                            if (field[i].properties.Sequence.reset_config.resetType !== undefined) {
                                if (field[i].properties.Sequence.reset_config.resetType === "custom") {
                                    if (field[i].properties.Sequence.reset_config.resetvalue === "") {
                                        fieldpath[parent].error = true;
                                        fieldpath[parent].path.push(field[i].name);
                                        this.is_Sequence_error = true;
                                        break;
                                    }
                                }
                            }

                            // else if (field[i].properties.Sequence.reset_config.resetType === undefined) {
                            //     fieldpath[parent].error = true;
                            //     fieldpath[parent].path.push(field[i].name);
                            //     this.is_ResetType_error = true;
                            //     break;
                            // }

                        }

                        // else if (field[i].properties.Sequence.reset_config === undefined) {
                        //     fieldpath[parent].error = true;
                        //     fieldpath[parent].path.push(field[i].name);
                        //     this.is_ResetType_error = true;
                        //     break;
                        // }
                    }
                }

            }


        }
        return fieldpath
    }
    handleRename = () => {
        let { renameContext } = this.props;

        return new Promise(async (resolve, reject) => {

            if (renameContext.rename.length > 0) {

                let result = await Promise.all(renameContext.rename.map(_r => {
                    return axios
                        .post(`${environment.api_url}/api/rename_collection`, {
                            "db_name": _r.db_name,
                            "entity": _r.entity,
                            "renameEntity": _r.renameEntity,
                            "metadata_dbname": `${environment.database}`
                        })
                }))
                    .then(_d => {
                        renameContext.setRenameObj({
                            rename: []
                        });

                        resolve(true);

                    })

                    .catch((error) => {

                        resolve(false);

                    })
            } else {

                resolve(true);
            }

        })
    }



    Save_Entity = async (event, reletion) => {

        const { leftContext, relationshipcontext, collapseForEntity, grouprelationshipcontext } = this.props
        let { list, saveToPulishParams } = leftContext
        let values = Object.values(list)
        let datalist = []
        // const { confirmationContext } = this.props;
        // const { setConfirm } = confirmationContext;
        this.publishid(reletion.attributes.id, "close")
        if (!values && values.length <= 0) {
            this.props.alert.setSnack({
                open: true,
                msg: "Please create entity widget",
                severity: 'info'
            });
            return;
        }
        values.map(val => {
            if (val) {
                datalist = [...datalist, ...val]
            }
            return false
        });

        datalist = datalist.filter(li => li.entity === reletion.entity);
        datalist = defaultFieldAdd(datalist);
        datalist = removeData(datalist);

        if (sessionStorage.getItem('is_db') === 'true') {
            let newrelationshipcontext = relationshipcontext.list.filter(li => li.entity === reletion.entity);
            let newgrouprelationshipcontext = grouprelationshipcontext.list.filter(li => li.entity === reletion.entity);
            let splitrelation = relationSplit(newrelationshipcontext)
            let splitgrouprelation = grouprelationSplit(newgrouprelationshipcontext)

            let payload = {
                client:
                    sessionStorage.clientName && sessionStorage.clientName !== 'undefined'
                        ? sessionStorage.clientName
                        : 'test',
                db_name:
                    sessionStorage.clientDB && sessionStorage.clientDB !== 'undefined'
                        ? sessionStorage.clientDB.replace(/\s/g, "_")
                        : 'test',
                clientId:
                    sessionStorage.clientId && sessionStorage.clientId !== 'undefined'
                        ? sessionStorage.clientId
                        : '',
                metadataId:
                    sessionStorage.getItem('metadataId') &&
                        sessionStorage.getItem('metadataId') !== 'undefined'
                        ? sessionStorage.getItem('metadataId')
                        : '',
                metadataname:
                    sessionStorage.getItem('metadataname') &&
                        sessionStorage.getItem('metadataname') !== 'undefined'
                        ? sessionStorage.getItem('metadataname')
                        : '',
                attributes: [{ relationship: splitrelation?.dontsend ?? [], listOfCollapse: collapseForEntity?.listOfCollapse ?? [] }],
                metadata: datalist,
                entity_relationship: splitrelation.send,

                entity_grouprelationship: splitgrouprelation.send,
                projectId:
                    sessionStorage.getItem('projectId') &&
                        sessionStorage.getItem('projectId') !== 'undefined'
                        ? sessionStorage.getItem('projectId')
                        : '',
                metadata_dbname: environment.database

            }

            if (datalist.length <= 0) {
                this.props.alert.setSnack({
                    open: true,
                    msg: "Please add entity",
                    severity: 'info',
                    vertical: 'top',
                    horizontal: 'right'
                });
                return;
            }

            this.validatePath = [];
            this.is_diamention_error = false;
            this.is_Sequence_error = false;
            // this.is_ResetType_error = false;
            this.validateDiamention(datalist)
            if (this.is_diamention_error) {
                this.props.alert.setSnack({
                    open: true,
                    msg: ` ${this.validatePath.join('/')} property dimension field ref entity should not be empty`,
                    severity: 'info',
                    vertical: 'top',
                    horizontal: 'right'
                });

                return;
            }

            else if (this.is_Sequence_error) {
                this.props.alert.setSnack({
                    open: true,
                    msg: `${this.validatePath.join('/')} Is Sequence custome date field cannot be empty `,
                    severity: 'info',
                    vertical: 'top',
                    horizontal: 'right'
                });

                return;
            }

            // else if (this.is_ResetType_error) {
            //     this.props.alert.setSnack({
            //         open: true,
            //         msg: `${this.validatePath.join('/')} Select Reset Type Option`,
            //         severity: 'info',
            //         vertical: 'top',
            //         horizontal: 'right'
            //     });

            //     return;
            // }
            let indexCheckFields = this.validateIndexs(datalist)

            if (indexCheckFields?.is_error) {
                this.props.alert.setSnack({
                    open: true,
                    msg: ` ${indexCheckFields.path.join('/')} index field should not be empty`,
                    severity: 'info',
                    vertical: 'top',
                    horizontal: 'right'
                });
                return;
            }
            let re_success = await this.handleRename();
            if (!re_success) {
                return;
            }
            let clone_pay = stringify(payload);
            clone_pay = { ...parse(clone_pay) };
            this.setState(prevState => ({
                ...prevState,
                isSave: false
            }))
            await axios
                .post(`${environment.api_url}/api/entity_saveschema`, clone_pay)
                .then(async res => {


                    if (res.data.Code === 201) {

                        let payloadentity =
                        {
                            entity: `${reletion.entity}`,
                            metadataId: `${sessionStorage.getItem("metadataId")}`,
                            metadata_dbname: `${environment.database}`
                        }

                        await axios
                            .post(`${environment.api_url}/api/entity_getschema`, payloadentity)
                            .then(response => {
                                if (response.data.Code === 201) {
                                    let data = converData(response.data)
                                    sessionStorage.setItem('metaId', response.data.Result.metadataId)
                                    sessionStorage.setItem('metadataId', response.data.Result.metadataId)

                                    sessionStorage.setItem(
                                        'metadataname',
                                        response.data.Result.metadataname
                                    )
                                    sessionStorage.setItem(
                                        'entityCode',
                                        response.data.Result.metadata[0].entitycode
                                    )
                                    sessionStorage.setItem(
                                        'entity',
                                        response.data.Result.metadata[0].entity
                                    )

                                    let { setEntity } = leftContext
                                    let { setRelation } = relationshipcontext;
                                    let { setgroupRelation } = grouprelationshipcontext;
                                    let { setListOfEntityCollaps } = collapseForEntity;

                                    const firstEntity = Object.keys(data.entity)[0];
                                    const firstEnttyField = data.entity[firstEntity];
                                    const Entitylist = leftContext.list[firstEntity].filter(li => li.entity !== reletion.entity);
                                    Entitylist.push(firstEnttyField[0]);
                                    var newEntityList = leftContext.list;
                                    delete newEntityList[firstEntity];
                                    newEntityList[`${firstEntity}`] = Entitylist;

                                    let EntityRelationshipcontext = relationshipcontext.list.filter(li => li.entity !== reletion.entity);
                                    if (data.relation !== undefined) {
                                        if (data.relation.length > 0) {
                                            data.relation.map(relation => {
                                                EntityRelationshipcontext.push(relation)
                                            })
                                        }
                                    }


                                    let EntityGroupRelationshipcontext = grouprelationshipcontext.list.filter(li => li.entity !== reletion.entity);

                                    if (data.grouprelation !== undefined) {
                                        if (data.grouprelation.length > 0) {

                                            data.grouprelation.map(relationss => {
                                                EntityGroupRelationshipcontext.push(relationss)
                                            })
                                        }
                                    }
                                    const datassss = leftContext.saveToPulishParams;


                                    const grp_rel = response?.data?.Result?.entity_grouprelationship;
                                    const rel = response?.data?.Result?.entity_relationship;
                                    const meta = response?.data?.Result?.metadata;

                                    if (grp_rel.length > 0) {
                                        datassss.entity_grouprelationship.filter(li => li.entity !== reletion.entity)
                                        grp_rel.map(relationshipgroup => {
                                            datassss.entity_grouprelationship.push(relationshipgroup);
                                        })
                                    }

                                    if (rel.length > 0) {
                                        datassss.entity_relationship.filter(li => li.entity !== reletion.entity)
                                        rel.map(relationship => {
                                            datassss.entity_relationship.push(relationship);
                                        })
                                    }

                                    if (meta.length > 0) {
                                        datassss.metadata.filter(li => li.entity !== reletion.entity)
                                        meta.map(metasss => {
                                            datassss.metadata.push(metasss);
                                        })
                                    }
                                    setEntity({
                                        ...leftContext,
                                        list: newEntityList,
                                        saveToPulishParamsElement: response?.data?.Result,
                                        saveToPulishParams: datassss

                                    })


                                    let setRelationship = EntityRelationshipcontext;
                                    if (response.data.Result.attributes && response.data.Result.attributes.length > 0) {
                                        let Multirelations = response.data.Result.attributes[0].relationship;
                                        setRelationship = [...setRelationship, ...Multirelations]
                                    }
                                    setRelation({
                                        ...relationshipcontext,
                                        list: setRelationship
                                    });


                                    let setgroupRelationship = EntityGroupRelationshipcontext;
                                    if (response.data.Result.attributes && response.data.Result.attributes.length > 0) {
                                        let Multirelations = response.data.Result.attributes[0].relationship;
                                        setgroupRelationship = [...setgroupRelationship, ...Multirelations]
                                    }
                                    setgroupRelation({
                                        ...grouprelationshipcontext,
                                        list: setgroupRelationship
                                    });
                                    setListOfEntityCollaps({
                                        ...collapseForEntity,
                                        listOfCollapse: response?.data?.Result?.attributes?.[0]?.listOfCollapse ?? []
                                    })

                                    this.props.alert.setSnack({
                                        open: true,
                                        msg: "entity saved successfully",
                                        severity: 'success',
                                        vertical: 'top',
                                        horizontal: 'right'
                                    });
                                    // this.props.publishopen.setisopen(false)
                                    // this.props.publishopen.closeopen()

                                }


                            })
                            .catch(err => {
                                this.props.alert.setSnack({
                                    open: true,
                                    msg: res.data.error,
                                    severity: 'error',
                                    vertical: 'top',
                                    horizontal: 'right'
                                });

                            })
                    } else {
                        this.props.alert.setSnack({
                            open: true,
                            msg: res.data.error,
                            severity: 'error',
                            vertical: 'top',
                            horizontal: 'right'
                        });
                    }
                })
                .catch(err => {
                    console.log("err", err)
                    this.props.alert.setSnack({
                        open: true,
                        msg: 'Entity is already exists ',
                        severity: 'error',
                        vertical: 'top',
                        horizontal: 'right'

                    })
                })
        }
    }

    Publish_Entity = async (event, reletion) => {


        // let publishid =this.props.singleArray
        // console.log("reletion",reletion.attributes.id)
        this.publishid(reletion.attributes.id, "open")

        const { leftContext, relationshipcontext, collapseForEntity, grouprelationshipcontext } = this.props;
        let { list, saveToPulishParamsElement, saveToPulishParams } = leftContext;
        // let entitypayload={
        //                     "db_name": "ATP_Metadata_Dev",
        //                     "entity": "entity_fields_published",
        //                        "filter": `entity_fields_published.metadataId == '${saveToPulishParams.metadataId}' && entity_fields_published.db_objectname == '${reletion.entity}'`,
        //                     // "filter": `entity_fields_published.metadataId == ${saveToPulishParams.metadataId}&& entity_fields_published.entity_group_name == 'MDO' `,
        //                     "return_fields":"entity_fields_published"
        //             }  
        //             await axios.post(`http://164.52.210.54:8080/api/read_documents`,{...entitypayload}).then(async(res)=>{
        //                 console.log("pubres",res.data.result)
        //             })



        if (saveToPulishParamsElement === undefined) {
            let metadata = saveToPulishParams.metadata.filter(li => li.entity === reletion.entity);
            let entity_relationship = saveToPulishParams.entity_relationship.filter(li => li.entity === reletion.entity);
            let entity_grouprelationship = saveToPulishParams.entity_grouprelationship.filter(li => li.entity === reletion.entity);

            saveToPulishParamsElement = {
                active: saveToPulishParams.active,
                activestatus: saveToPulishParams.activestatus,
                attributes: saveToPulishParams.attributes,
                client: saveToPulishParams.client,
                clientId: saveToPulishParams.clientId,
                createdate: saveToPulishParams.createdate,
                db_name: saveToPulishParams.db_name,
                entity_grouprelationship: entity_grouprelationship,
                entity_relationship: entity_relationship,
                metadata: metadata,
                metadataId: saveToPulishParams.metadataId,
                metadataname: saveToPulishParams.metadataname,
                projectId: saveToPulishParams.lastupdatetime,
                updatedate: saveToPulishParams.updatedate,
                updatedby: saveToPulishParams.updatedby,


            }
        }

        else if (saveToPulishParamsElement !== undefined) {

            if (saveToPulishParamsElement.metadata[0].entity === reletion.entity) {

                saveToPulishParamsElement = saveToPulishParamsElement
            }

            else {
                let metadata = saveToPulishParams.metadata.filter(li => li.entity === reletion.entity);
                let entity_relationship = saveToPulishParams.entity_relationship.filter(li => li.entity === reletion.entity);
                let entity_grouprelationship = saveToPulishParams.entity_grouprelationship.filter(li => li.entity === reletion.entity);

                saveToPulishParamsElement = {
                    active: saveToPulishParams.active,
                    activestatus: saveToPulishParams.activestatus,
                    attributes: saveToPulishParams.attributes,
                    client: saveToPulishParams.client,
                    clientId: saveToPulishParams.clientId,
                    createdate: saveToPulishParams.createdate,
                    db_name: saveToPulishParams.db_name,
                    entity_grouprelationship: entity_grouprelationship,
                    entity_relationship: entity_relationship,
                    metadata: metadata,
                    metadataId: saveToPulishParams.metadataId,
                    metadataname: saveToPulishParams.metadataname,
                    projectId: saveToPulishParams.lastupdatetime,
                    updatedate: saveToPulishParams.updatedate,
                    updatedby: saveToPulishParams.updatedby
                }

            }

        }
        let metadata = saveToPulishParams.metadata.filter(li => li.entity === reletion.entity);

        // let projectmetadata = saveToPulishParams.metadata.filter(li => li.projectId === reletion.projectId);


        // console.log("mettta",metadatagroupname)
        let payload = {
            "db_name": saveToPulishParams.db_name,
            "entity": reletion.entity,
            "mdm_entity_data": {
                "client": saveToPulishParams.client,
                "db_name": saveToPulishParams.db_name,
                "metadataId": saveToPulishParams.metadataId,
                "projectId": saveToPulishParams.projectId,
                "clientId": saveToPulishParams.clientId,
                "createdate": saveToPulishParams.createdate,
                "lastupdatetime": saveToPulishParams.lastupdatetime,
                "metadataname": saveToPulishParams.metadataname,
                "projectname": saveToPulishParams.projectId,
                "metadata": metadata,
            },

        }
        console.log("payll", saveToPulishParamsElement.metadata)

        await axios
            .post(`${environment.api_url}/api/entity_publishschema`, { ...saveToPulishParamsElement, metadata_dbname: environment.database }).then(async (res) => {

                console.log("rere", res)
                if (res.data.Code === 201) {

                    let payloadentity =
                    {
                        entity: `${reletion.entity}`,
                        metadataId: `${sessionStorage.getItem("metadataId")}`,
                        metadata_dbname: `${environment.database}`
                    }

                    await axios
                        .post(`${environment.api_url}/api/entity_getschema`, payloadentity)
                        .then(response => {
                            console.log("response", response)
                            if (response.data.Code === 201) {
                                let data = converData(response.data)

                                let { setEntity } = leftContext
                                let { setRelation } = relationshipcontext;
                                let { setgroupRelation } = grouprelationshipcontext;
                                let { setListOfEntityCollaps } = collapseForEntity;

                                const firstEntity = Object.keys(data.entity)[0];
                                const firstEnttyField = data.entity[firstEntity];
                                const Entitylist = leftContext.list[firstEntity].filter(li => li.entity !== reletion.entity);
                                Entitylist.push(firstEnttyField[0]);
                                var newEntityList = leftContext.list;
                                delete newEntityList[firstEntity];
                                newEntityList[`${firstEntity}`] = Entitylist;

                                let EntityRelationshipcontext = relationshipcontext.list.filter(li => li.entity !== reletion.entity);
                                if (data.relation !== undefined) {
                                    if (data.relation.length > 0) {
                                        data.relation.map(relation => {
                                            EntityRelationshipcontext.push(relation)
                                        })
                                    }
                                }
                                let EntityGroupRelationshipcontext = grouprelationshipcontext.list.filter(li => li.entity !== reletion.entity);


                                if (data.grouprelation !== undefined) {
                                    if (data.grouprelation.length > 0) {

                                        data.grouprelation.map(relationss => {
                                            EntityGroupRelationshipcontext.push(relationss)
                                        })
                                    }
                                }
                                const datassss = leftContext.saveToPulishParams;


                                const grp_rel = response?.data?.Result?.entity_grouprelationship;
                                const rel = response?.data?.Result?.entity_relationship;
                                const meta = response?.data?.Result?.metadata;

                                if (grp_rel.length > 0) {
                                    datassss.entity_grouprelationship.filter(li => li.entity !== reletion.entity)
                                    grp_rel.map(relationshipgroup => {
                                        datassss.entity_grouprelationship.push(relationshipgroup);
                                    })
                                }

                                if (rel.length > 0) {
                                    datassss.entity_relationship.filter(li => li.entity !== reletion.entity)
                                    rel.map(relationship => {
                                        datassss.entity_relationship.push(relationship);
                                    })
                                }

                                if (meta.length > 0) {
                                    datassss.metadata.filter(li => li.entity !== reletion.entity)
                                    meta.map(metasss => {
                                        datassss.metadata.push(metasss);
                                    })
                                }

                                setEntity({
                                    ...leftContext,
                                    list: newEntityList,
                                    saveToPulishParams: datassss
                                    // saveToPulishParamsElement: response?.data?.Result,
                                })
                                let setRelationship = EntityRelationshipcontext;
                                if (response.data.Result.attributes && response.data.Result.attributes.length > 0) {
                                    let Multirelations = response.data.Result.attributes[0].relationship;
                                    setRelationship = [...setRelationship, ...Multirelations]
                                }
                                setRelation({
                                    ...relationshipcontext,
                                    list: setRelationship
                                });

                                let setgroupRelationship = EntityGroupRelationshipcontext;
                                if (response.data.Result.attributes && response.data.Result.attributes.length > 0) {
                                    let Multirelations = response.data.Result.attributes[0].relationship;
                                    setgroupRelationship = [...setgroupRelationship, ...Multirelations]
                                }
                                setgroupRelation({
                                    ...grouprelationshipcontext,
                                    list: setgroupRelationship
                                });
                                setListOfEntityCollaps({
                                    ...collapseForEntity,
                                    listOfCollapse: response?.data?.Result?.attributes?.[0]?.listOfCollapse ?? []
                                })

                                this.props.alert.setSnack({
                                    open: true,
                                    msg: 'entity Published successfully',
                                    severity: 'success',
                                    vertical: 'top',
                                    horizontal: 'right'
                                });
                                this.props.publishopen.setisopen(true)

                            }


                        })
                        .catch(err => {
                            this.props.alert.setSnack({
                                open: true,
                                msg: res.data.error,
                                severity: 'error',
                                vertical: 'top',
                                horizontal: 'right'
                            });
                        })
                }
            }).then(async (response) => {


                if (reletion.entity_group_name === "MDO") {

                    await axios.post(`https://dev.mdmservice.ainqaplatform.in/mdmapi/MDM_Publish`, { ...payload, metadata_dbname: environment.database })
                }
            })



    }



    render() {

        let { length, isSave } = this.props

        let { publishForEntity } = this.props
        let { listOfPublish, setListofPublishlist } = publishForEntity
        let context = this.context;
        console.log("isSave", this.props)
        let { classes, data = {}, tableIndex, collapseForEntity } = this.props;
        let { listOfCollapse } = collapseForEntity;
        var idm = []
        idm = this.props.data.attributes.id

        // console.log("listOfCollapse",listOfCollapse)
        let { _d, _f } = this.countFields(data?.fields);
        let { anchorEl, fieldAnchorEl, editData, entityData, isAdd, saveopen, publishopen, documents, snackopen, autoHideDuration, snackmsg, snacktype } = this.state;
        // let { singleArray } = this.props
        let datalist = JSON.parse(sessionStorage.getItem("datalist"))
        let editproperties = JSON.parse(sessionStorage.getItem("editproperties"))
        let listofpubli = listOfPublish.filter(x => x == data.attributes.id)
        //        let {leftContext}=context
        //        let { saveToPulishParams } = leftContext; 
        //        let mdmmetadata = saveToPulishParams.metadata.filter(li => li.entity_group_name === "MDO");
        console.log("1212", "", listofpubli)

        return (



            // <div onLoad={this.top()}>

            //<React.Fragment>
            <>



                <React.Fragment>


                    <Grid
                        item
                        id="drag-drop-entity"
                        className={"drag-entity" + data?.attributes?.id}
                        ref={ref => {
                            this.parentRef = ref;
                        }}
                        style={{
                            width: "360px",
                            top: this.props.align ? data.attributes.top : this.state.top[tableIndex]?.[1],
                            left: this.props.align ? data.attributes.left : data.attributes.type.label == "MDO" ? "20px" : data.attributes.type.label == "RDO" ? "400px" : null,
                            position: "absolute",
                            display: "inline-flex",
                            zIndex: this.state.zindex ? this.state.zindex : "50"
                        }}
                    >

                        {
                            data.attributes.type.label == "MDO" ?
                                <Paper className={classes.paper}>

                                    <Grid
                                        ref={ref => {
                                            this.canvasRef = ref;
                                        }}
                                        onMouseDown={(e) => this.mouseDown(e)}
                                        className={classes.titleCard} style={{ background: data.attributes.type.label == "MDO" ? "red" : data.attributes.type.label == "RDO" ? "#BDBD4D" : null }} onClick={() => this.selectEntity(data)}>
                                        <Typography className={classes.title} >
                                            {/* <Tooltip title="Field List Collapse"> */}
                                            {/* {console.log(listOfCollapse,"listOfCollapse")} */}
                                            <abbr title="Collapse" style={{ textDecoration: "none" }}>
                                                {listOfCollapse.indexOf(data?.attributes?.id) <= -1 ? <ArrowDropDownIcon onClick={() => this.handleCollapse(data?.attributes?.id, 'open')} className={classes.collapseIcon} /> : <ArrowRightIcon onClick={() => this.handleCollapse(data?.attributes?.id, 'close')} className={classes.collapseIcon} />}

                                            </abbr>

                                            {/* </Tooltip> */}
                                            {
                                                data?.entity?.length > 9 ?
                                                    <abbr title={data?.entity} style={{ textDecoration: "none" }}>
                                                        &nbsp;&nbsp; {`${data?.entity?.slice(0, 9)}...`}
                                                    </abbr>
                                                    : <>
                                                        &nbsp;&nbsp;{data.entity}
                                                    </>

                                            }

                                            {/* <abbr title="Edit" style={{ textDecoration: "none" }}>
                                {listofpubli==data?.attributes?.id?
 <EditOutlinedIcon className={'editIcon' + data?.attributes?.id + "  " + classes.entityEditIcon} onClick={e => this.handleClick(e, data)} />
 :<EditOutlinedIcon className={'editIcon' + data?.attributes?.id + "  " + classes.entityEditIcon} onClick={e => this.handleedit()} />}
                            </abbr> */}
                                            {this.props.publishopen.isopen ?
                                                <abbr title="Edit" style={{ textDecoration: "none" }}>

                                                    <EditOutlinedIcon className={'editIcon' + data?.attributes?.id + "  " + classes.entityEditIcon} onClick={e => listofpubli == data?.attributes?.id ? this.handleClick(e, data) : this.handleedit()} />

                                                </abbr> :
                                                <abbr title="Edit" style={{ textDecoration: "none" }}>

                                                    <EditOutlinedIcon className={'editIcon' + data?.attributes?.id + "  " + classes.entityEditIcon} onClick={e => this.handleedit()} />

                                                </abbr>

                                            }






                                            <abbr title="Delete" style={{ textDecoration: "none" }}>
                                                <DeleteOutlineIcon className={'delete' + data?.attributes?.id + "  " + classes.entityEditIcon} onClick={e => this.deleteClick(e, data)} />
                                            </abbr>
                                            {/* <abbr title={'Group Relationship'} style={{ textDecoration: "none" }}>

                                    <ListAltIcon onClick={(e) => this.handleGroupRealatioShipClick(e, data)} className={classes.entityEditIcon + ' GrouprelationShip' + data?.attributes?.id} />
                                </abbr> */}
                                            <abbr title={'Save'} style={{ textDecoration: "none" }}>
                                                <SaveAltIcon onClick={(e) => this.saveokopen(e, data)} className={classes.entityEditIcon + ' Save' + data?.attributes?.id} />
                                            </abbr>
                                            <abbr title={'Publish'} style={{ textDecoration: "none" }}>
                                                <PublishAltIcon onClick={(e) => this.publishokopen(e, data)} className={classes.entityEditIcon + ' Save' + data?.attributes?.id} />
                                            </abbr>

                                        </Typography>
                                        <Typography className={classes.status} >{data.status == 'draft' ? 'Draft' : 'Published'}</Typography>
                                    </Grid>
                                    <Grid>
                                        {/* {this.state.collapseentity?listOfCollapse.indexOf(data?.attributes?.id) <= -1 && data.fields && <EntityFieldList handleRealatioShipClick={this.handleRealatioShipClick} tableIndex={tableIndex} data={data} openEdit={this.openEdit} list={data.fields} />:null} */}
                                        {this.state.collapseentity ? listOfCollapse.indexOf(data?.attributes?.id) <= -1 && data.fields && <EntityFieldList handleRealatioShipClick={this.handleRealatioShipClick} tableIndex={tableIndex} data={data} openEdit={this.openEdit} list={data.fields} /> : null}
                                    </Grid>

                                    <Divider />
                                    <Grid>
                                        <ListCard
                                            list={[{
                                                secondaryData: "Version", title: <>
                                                    <Grid style={{ display: "flex" }}>
                                                        <Typography className={'addEntityField' + data?.attributes?.id + ' ' + classes.addText} onClick={this.handleFieldClick} color="secondary">+ Add</Typography>
                                                        <Grid style={{ marginLeft: "25px", display: "flex" }} >
                                                            {data.attributes.type.label == "RDO" || "MDO" ? <Grid style={{ display: "flex" }}>
                                                                <Typography>D</Typography>
                                                                <Grid className={classes.notificationIcon}>
                                                                    <Typography className={classes.notificationFont}>{_d}</Typography>
                                                                </Grid>

                                                            </Grid> : null}
                                                            <Grid style={{ display: "flex", marginLeft: "7px" }}>
                                                                <Typography>F</Typography>
                                                                <Grid className={classes.notificationIcon}>
                                                                    <Typography className={classes.notificationFont}>{_f}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid> </>
                                            }]}
                                        />
                                    </Grid>
                                </Paper>
                                : data.attributes.type.label == "RDO" ? <Paper className={classes.paper}>

                                    <Grid
                                        ref={ref => {
                                            this.canvasRef = ref;
                                        }}
                                        onMouseDown={(e) => this.mouseDown(e)}
                                        className={classes.titleCard} style={{ background: data.attributes.type.label == "MDO" ? "red" : data.attributes.type.label == "RDO" ? "#BDBD4D" : null }} onClick={() => this.selectEntity(data)}>
                                        <Typography className={classes.title} >
                                            {/* <Tooltip title="Field List Collapse"> */}
                                            {/* {console.log(listOfCollapse,"listOfCollapse")} */}
                                            <abbr title="Collapse" style={{ textDecoration: "none" }}>
                                                {listOfCollapse.indexOf(data?.attributes?.id) <= -1 ? <ArrowDropDownIcon onClick={() => this.handleCollapse(data?.attributes?.id, 'open')} className={classes.collapseIcon} /> : <ArrowRightIcon onClick={() => this.handleCollapse(data?.attributes?.id, 'close')} className={classes.collapseIcon} />}

                                            </abbr>

                                            {/* </Tooltip> */}
                                            {
                                                data?.entity?.length > 9 ?
                                                    <abbr title={data?.entity} style={{ textDecoration: "none" }}>
                                                        &nbsp;&nbsp; {`${data?.entity?.slice(0, 9)}...`}
                                                    </abbr>
                                                    : <>
                                                        &nbsp;&nbsp;{data.entity}
                                                    </>

                                            }
                                            {/* <abbr title="Edit" style={{ textDecoration: "none" }}> */}
                                            {/* <abbr title="Edit" style={{ textDecoration: "none" }}>
                                
                                <EditOutlinedIcon className={'editIcon' + data?.attributes?.id + "  " + classes.entityEditIcon} onClick={e => listofpubli==data?.attributes?.id?this.handleClick(e, data):this.handleedit()} />
    
                               </abbr> */}


                                            {this.props.publishopen.isopen ?
                                                <abbr title="Edit" style={{ textDecoration: "none" }}>

                                                    <EditOutlinedIcon className={'editIcon' + data?.attributes?.id + "  " + classes.entityEditIcon} onClick={e => listofpubli == data?.attributes?.id ? this.handleClick(e, data) : this.handleedit()} />

                                                </abbr> :
                                                <abbr title="Edit" style={{ textDecoration: "none" }}>

                                                    <EditOutlinedIcon className={'editIcon' + data?.attributes?.id + "  " + classes.entityEditIcon} onClick={e => this.handleedit()} />

                                                </abbr>

                                            }
                                            {/* {listOfPublish .indexOf(data?.attributes?.id) <= -1 ? <EditOutlinedIcon className={'editIcon' + data?.attributes?.id + "  " + classes.entityEditIcon} onClick={e => this.handleClick(e, data)} /> : <EditOutlinedIcon className={'editIcon' + data?.attributes?.id + "  " + classes.entityEditIcon} onClick={e => this.handleedit()} />} */}
                                            {/* <EditOutlinedIcon className={'editIcon' + data?.attributes?.id + "  " + classes.entityEditIcon} onClick={e => this.props.publishopen.isopen?this.handleClick(e, data):this.handleedit()} /> */}
                                            {/* </abbr> */}



                                            <abbr title="Delete" style={{ textDecoration: "none" }}>
                                                <DeleteOutlineIcon className={'delete' + data?.attributes?.id + "  " + classes.entityEditIcon} onClick={e => this.deleteClick(e, data)} />
                                            </abbr>
                                            {/* <abbr title={'Group Relationship'} style={{ textDecoration: "none" }}>

                                <ListAltIcon onClick={(e) => this.handleGroupRealatioShipClick(e, data)} className={classes.entityEditIcon + ' GrouprelationShip' + data?.attributes?.id} />
                            </abbr> */}


                                            <abbr title={'Save'} style={{ textDecoration: "none" }}>
                                                <SaveAltIcon
                                                    onClick={(e) => this.saveokopen(e, data)}
                                                    className={classes.entityEditIcon + ' Save' + data?.attributes?.id} />
                                            </abbr>


                                            <abbr title={'Publish'} style={{ textDecoration: "none" }}>
                                                <PublishAltIcon onClick={(e) => this.publishokopen(e, data)} className={classes.entityEditIcon + ' Save' + data?.attributes?.id} />
                                            </abbr>

                                        </Typography>
                                        <Typography className={classes.status} >{data.status == 'draft' ? 'Draft' : 'Published'}</Typography>
                                    </Grid>
                                    <Grid>
                                        {/* {this.state.collapseentity?listOfCollapse.indexOf(data?.attributes?.id) <= -1 && data.fields && <EntityFieldList handleRealatioShipClick={this.handleRealatioShipClick} tableIndex={tableIndex} data={data} openEdit={this.openEdit} list={data.fields} />:null} */}
                                        {this.state.collapseentity ? listOfCollapse.indexOf(data?.attributes?.id) <= -1 && data.fields && <EntityFieldList handleRealatioShipClick={this.handleRealatioShipClick} publishid={this.publishid} tableIndex={tableIndex} data={data} openEdit={this.openEdit} list={data.fields} /> : null}
                                    </Grid>

                                    <Divider />
                                    <Grid>
                                        <ListCard list={[{
                                            secondaryData: "Version", title: <>
                                                <Grid style={{ display: "flex" }}>
                                                    <Typography className={'addEntityField' + data?.attributes?.id + ' ' + classes.addText} onClick={this.handleFieldClick} color="secondary">+ Add</Typography>
                                                    <Grid style={{ marginLeft: "25px", display: "flex" }} >

                                                        {data.attributes.type.label == "RDO" ? <Grid style={{ display: "flex" }}>
                                                            <Typography>D</Typography>
                                                            <Grid className={classes.notificationIcon}>
                                                                <Typography className={classes.notificationFont}>{_d}</Typography>
                                                            </Grid>

                                                        </Grid> : null}
                                                        <Grid style={{ display: "flex", marginLeft: "7px" }}>
                                                            <Typography>F</Typography>
                                                            <Grid className={classes.notificationIcon}>
                                                                <Typography className={classes.notificationFont}>{_f}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> </>
                                        }]} />
                                    </Grid>
                                </Paper>
                                    : null
                        }

                    </Grid>
                    <CustomPopover open={Boolean(anchorEl)} classname={'editIcon' + data?.attributes?.id} target={anchorEl} onclose={this.handleClose}>
                        {Boolean(anchorEl) && <AddEntity editData={entityData} publis={this.publishid} data={data} onclose={this.handleClose} />}
                    </CustomPopover>
                    {/* <Popover id={Boolean(fieldAnchorEl) ? 'simple-popover' : undefined} open={Boolean(fieldAnchorEl)} classname={'addEntityField' + (editData ? editData?.attributes?.id : data?.attributes?.id)} anchorEl={fieldAnchorEl} onClose={this.handleFieldClose}>
                    <React.Fragment>
                        {Boolean(fieldAnchorEl) && <AddField isAdd={isAdd} editData={editData} data={data} onclose={this.handleFieldClose} />}
                    </React.Fragment>
                </Popover> */}
                    <CustomPopover open={Boolean(fieldAnchorEl)} classname={'addEntityField' + (editData ? editData?.attributes?.id : data?.attributes?.id)} target={fieldAnchorEl} onclose={this.handleFieldClose}>
                        <React.Fragment>
                            {Boolean(fieldAnchorEl) && <AddField datalist={datalist} editproperties={editproperties} isAdd={isAdd} editData={editData} publishid={this.publishid} data={data} list={data.fields} onclose={this.handleFieldClose}


                            />
                            }
                        </React.Fragment>
                    </CustomPopover>
                    {/* <CustomPopover open={Boolean(relationshipAnchorEl)} classname={'relationShip' + (relationData ? relationData?.attributes?.id : data?.attributes?.id)} target={relationshipAnchorEl} onclose={this.handleRealatioShipClose}>
                </CustomPopover> */}

                    {/* </div> */}

                </React.Fragment>
                <Dialog open={saveopen.open} onClose={this.saveclose}>
                    <DialogTitle style={{ paddingTop: "15px", paddingLeft: "78px" }}>SAVE</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ paddingLeft: "40px" }} id="alert-dialog-slide-description">
                            Do you want save
                        </DialogContentText>
                    </DialogContent>
                    <List sx={{ pt: 0 }}>

                        <ListItem
                            autoFocus button>
                            {/* <Button  variant="outlined" onClick={this.saveclose}>Cancel</Button>
          <Button style={{marginLeft:"12px"}} color="success" variant="outlined" onClick={this.saveok}>ok</Button> */}
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" color="error" onClick={this.saveclose} >
                                    Cancel
                                </Button>
                                <Button variant="contained" color="success" onClick={this.saveok}>
                                    Ok
                                </Button>
                            </Stack>
                        </ListItem>
                    </List>

                </Dialog>

                <Dialog open={publishopen.open} onClose={this.publishclose}>
                    <DialogTitle style={{ paddingTop: "15px", paddingLeft: "55px" }}>PUBLISH</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ paddingLeft: "22px" }} id="alert-dialog-slide-description">
                            Do you want publish
                        </DialogContentText>
                    </DialogContent>
                    <List sx={{ pt: 0 }}>

                        <ListItem
                            autoFocus button >
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" color="error" onClick={this.publishclose} >
                                    Cancel
                                </Button>
                                <Button variant="contained" color="success" onClick={this.publishok}>
                                    Ok
                                </Button>
                            </Stack>
                        </ListItem>
                    </List>

                </Dialog>


            </>




        )
    }

}
MiddleExpandCard.contextType = RightSideNav;
export default withStyles(useStyles)(withAllContexts(MiddleExpandCard));