import React from "react";

import AppAuth from "./App.auth";
import AppTheme from "./App.theme";
import AppAlert from "./App.alert";
import DrawLine from "./App.drawLine";
import AppErrorBoundary from "./App.errorBoundry";
import RouterApp from "./router";

import { CssBaseline } from "@material-ui/core";
import AppDialog from "./App.dialog";
import AppBackdrop from "./App.backdrop";
import { LeftEntity, TemplateContext } from "./contexts/entity";
import ContextProvider from "./contexts/enterprise/index";
import AppRuleid from "./App.commoncontect";
function App() {
  return (
    <AppAuth>
      <AppErrorBoundary>
        <AppTheme>
          <CssBaseline />
          <AppRuleid>
          <AppAlert>
            <AppDialog>
              <AppBackdrop>
                <LeftEntity>
                  <TemplateContext>
                    <DrawLine>
                      <ContextProvider>
                        <RouterApp />
                      </ContextProvider>
                    </DrawLine>
                  </TemplateContext>
                </LeftEntity>
              </AppBackdrop>
            </AppDialog>
          </AppAlert>
          </AppRuleid>
        </AppTheme>
      </AppErrorBoundary>
    </AppAuth>
  );
}

export default App;
