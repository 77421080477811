import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Typography, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
const useStyles = ((theme) => ({
    selectBox: {
        width: "100%",
        height: "35px",
        "& .MuiFormLabel-root.Mui-focused": {
            transform: "translate(14px, -6px) scale(0.75) !important"
        },
        "& .MuiFormLabel-root.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75) !important"
        },
        "& .MuiInputLabel-outlined": {
            transform: "translate(14px, 9px) scale(1) !important"
        },
        "& .MuiAutocomplete-inputRoot": {
            height: "35px",
            "& input": {
                padding: "0.5px 4px !important"
            }
        }
    },
    errorSelectBox: {
        borderWidth: "1px",
        borderColor: "red",
        width: "100%",
        height: "35px",
        marginBottom: "20px",
        color: "#7d7c7c",
        fontSize: "15px",
        borderRadius: "4px",
        background: "white"
    },
    successSelectBox: {
        width: "100%",
        height: "35px",
        marginBottom: "20px",
        color: "#7d7c7c",
        fontSize: "15px",
        borderRadius: "4px",
        background: "white"
    },
    errorFont: {
        color: "#f44336",
        fontSize: "0.75rem",
        marginTop: "-18px"
    }
}));

class SelectBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        let { Optionlabel = 'label', classes, onchange, classname, list, value, title, top_title, error, entityname, indicate, message, disabled } = this.props;
        console.log("www", this.props)
        return (
            <React.Fragment>
                <Grid container >
                    {top_title && <Typography variant="caption">{top_title}</Typography>}
                    {/* <Autocomplete
                    options={list}
                    className={`${classes.selectBox} ${value ? 'Mui-focused' : ''} ${classname ? classname : ''}`}
                    onChange={onchange}
                    getOptionLabel={(option) => option[Optionlabel]}
                    style={{ width: "100%", height: "35px" }}
                    value={value}
                    renderInput={(params) => <TextField style={{ height: "35px" }} {...params} label={top_title ? '' : title} variant="outlined" />}
                /> */}
                    <select
                        disabled={disabled}
                        value={value?.value} onChange={(e) => { let index = e.nativeEvent.target.selectedIndex; let label = e.nativeEvent.target[index].text; onchange(e, list[index - 1]) }} className={error ? classes.errorSelectBox : classes.successSelectBox}>
                        {<option value={null} >{title ? title : 'Type'}</option>}
                        {
                            list?.length > 0 && list?.sort((a, b) => a.label > b.label ? 1 : -1).map((selectList, index) => (
                                <option value={selectList?.value}>{selectList[Optionlabel]}</option>
                            ))
                        }
                    </select>
                    {
                        error ? <Typography className={classes.errorFont}>{message ?? 'Please select your field'} </Typography> : ""

                    }
                    {
                        indicate ? <Typography className={classes.errorFont}>{message ?? ` ${entityname} ---> Have a Dimension`} </Typography> : ""
                    }
                </Grid>
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(SelectBox);