 import React from 'react';
 import { Grid, Typography } from '@material-ui/core';
 import { withStyles } from '@material-ui/core/styles';
 import StopIcon from '@material-ui/icons/Stop';
 import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
 import { SelectBox } from '../';
 import { EntityGroupRelationShipContext, EntityLeftContext } from '../../../contexts';
 import { withAllContexts } from '../../../HOCs';
 import axios from 'axios';
 import environment from '../../../config';
 
 const useStyles = ((theme) => ({
     root: {
         flexGrow: 1,
         padding: "10px 10px",
         borderBottom: "1px dashed"
     },
     titleCard: {
         display: "grid",
         gridTemplateColumns: "26px 1fr 19px"
     },
     spaceTop: {
         marginTop: "15px"
     },
     spaceLeft5: {
         paddingLeft: 5
     },
     spaceRight5: {
         paddingRight: 5
     },
     spaceBottom: {
         marginBottom: "15px"
     },
     byName: {
         color: theme.palette.text.light
     },
     no_data: {
         textAlign: "center",
         padding: "15px",
         fontSize:13
     },
     deleteBtn:{
         cursor: "pointer",
         background: "#f24a58",
         borderRadius: "50%",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         width: "26px",
         height: "31px",
         color: "white"
     }
 }));
 class GroupRelationship extends React.Component {
     constructor(props) {
         super(props)
         this.state = {}
     }
     setValue(name, value) {
         this.setState({
             [name]: value
         })
     }
     getAlltable(data) {
         let arr = [];
         data.map(val => {
             arr = [...arr, ...val];
             return false
         })
         return arr;
     }
     deleteRelationship  = async(_val) =>{
         
         let { drawLine, grouprelationshipcontext }  = this.props;
          
         // let {  relations }  = this.state;
         // let relationcontext = relationshipcontext;
         let { list, setgroupRelation } = grouprelationshipcontext;
          
         // relations = relations.filter(_rl=>_rl.id  !== _val.id);
         // this.setState({ relations });
         let _f_list = list.filter(val => val?.groupId === _val?.groupId);
         
         if(_f_list?.length>0){
             
            let datass = {
                db_name:`${environment.database}`,
                entity:'entity_grouprelation_stg',
                is_metadata:false,
                filter: {
                  groupId: `${_val.groupId}`
                },
                doc: {
                  active: "N"
                }
              }
               
              let data = JSON.stringify([datass])
              var config = {
                method: 'post',
                url: `${environment.api_url}/api/upsert_document`,
                headers: {
                  'Content-Type': 'application/json'
                },
                data: data
              }
               
              await axios(config)
              .then(async response => {
               
              })
               
              let publish_relation = {
                "db_name": `${environment.database}`,
                "entity": "entity_grouprelation_published",
                "filter": `entity_grouprelation_published.groupId=='${_val.groupId}'`,
                "metadata_dbname" : `${environment.database}`
                };
        
              await axios.post(`${environment.api_url}/api/delete_document`, publish_relation)
              .then(async res => {
        
                
              })
               
              list = list.filter(val => val?.groupId !== _val?.groupId);
              setgroupRelation({
                 ...grouprelationshipcontext,
                 list
             });
            
                // call draw line
                 let { setCount, count } = drawLine;
                 setCount({
                     ...drawLine,
                     count: count -1
                 })
           
         }
 
     }
     render() {
         let { classes } = this.props;
         let context = this.context;
         let tabelList = this.getAlltable(Object.values(context.list))
         return (
             <EntityGroupRelationShipContext.Consumer>
                 {grouprelationShipContext => <React.Fragment>
                     {grouprelationShipContext.list.map(val => <Grid container className={classes.root}>
                         
                         <Grid item md={12} className={classes.titleCard}>
                             <div> <StopIcon color="primary" /> </div>
                             <div>
                                 <Typography style={{width:"230px", height:"40px"}} variant="subtitle2">{`${val.FromCollection}.${val.FromColumnName}_IN_${val.ToCollection}.${val.ToColumnName}`}</Typography>
                                 <Typography variant="caption" className={classes.byName}>by Stanislov kieron</Typography>
                             </div>
                             <div className={classes.deleteBtn}>
                                 <DeleteOutlinedIcon onClick={()=>this.deleteRelationship(val)}/> 
                             </div>
                         </Grid>
                         <Grid container md={12} className={classes.spaceTop}>
                             <Grid md={6} sm={6} className={classes.spaceRight5} > 
                                 {/* <SelectBox
                                     list={tabelList}
                                     Optionlabel={"entity"}
                                     // classname={classes.spaceBottom}
                                     onchange={(e, value) => { this.setValue('type', value) }}
                                     value={val.attributes.cuurententity}
                                     title=''
                                 /> */}
                                  <select disabled value={val?.attributes?.currententity?.entity} onChange={(e)=>{let  index = e.nativeEvent.target.selectedIndex;let label= e.nativeEvent.target[index].text;this.setValue('type',tabelList[index-1])}} style={{ width: "100%", height: "35px",marginBottom:"20px",color: "#7d7c7c",fontSize: "15px",borderRadius: "4px",background: "white"}}>
                 {/* {top_title??<option value={0}>{top_title ? '' : title}</option>}  */}
                     {
                         
                             <option value={val.FromCollection}>{val.FromCollection}</option>
  
                     }
                 </select>
                                 <Typography variant="caption">source</Typography>
                             </Grid>
                             <Grid md={6} sm={6} className={classes.spaceLeft5} >
                                 {/* <SelectBox
                                     list={tabelList}
                                     Optionlabel={"entity"}
                                     // classname={classes.spaceBottom}
                                     onchange={(e, value) => { this.setValue('type', value) }}
                                     value={val.attributes.targetTable}
                                     title=''
                                 /> */}
                                      <select disabled value={val?.attributes?.targetTable?.entity} onChange={(e)=>{let  index = e.nativeEvent.target.selectedIndex;let label= e.nativeEvent.target[index].text;this.setValue('type',tabelList[index-1])}} style={{ width: "100%", height: "35px",marginBottom:"20px",color: "#7d7c7c",fontSize: "15px",borderRadius: "4px",background: "white"}}>
                 {/* {top_title??<option value={0}>{top_title ? '' : title}</option>}  */}
                     {
                        //  tabelList?.map((selectList,index)=>(
                             <option value={val.ToCollection}>{val.ToCollection}</option>
                        //  ))
                     }
                 </select>
                                 <Typography variant="caption">Destination</Typography>
                             </Grid>
                         </Grid>
                     </Grid>)}
                     {grouprelationShipContext.list.length === 0 && <Typography className={classes.no_data}>No Group Relationship exits!</Typography>}
                 </React.Fragment>}
             </EntityGroupRelationShipContext.Consumer>
         )
     }
 }
 GroupRelationship.contextType = EntityLeftContext;
 export default withStyles(useStyles)(withAllContexts(GroupRelationship));