import React from "react";
import {
  Grid,
  Drawer,
  Typography,
  Paper,
  AppBar,
  Divider,
  Avatar,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { LeftCard, RightCard, MiddleCard } from "./";
import { withNavBars } from "../../HOCs";
import { SecondTopNav } from "../../components/entity";
import { GeneralMaster } from "atp-general-master";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Iframe,
  BottomCard,
  JSONDiff,
  EntityTemplate,
  AddRelationShip,
  AddGroupRelationShip,
} from "../../components";
import { HideCard } from "../../components/entity/rightCard";
import { RightSideNav } from "../../contexts";
import Enterprise from "../enterprise_modeler/index";
import ContextProvider from "../../contexts/enterprise";
import axios from "axios";
import environment from "../../config";
import { Alerts } from "../../components";
const useStyles = (theme) => ({
  root: {
    // display: "flex",
    // align-items: "center",
    flexGrow: 1,
  },
  leftCardParent: {
    display: "grid",
    gridTemplateAreas: `"left main right"`,
    paddingTop: "1px",
  },
  leftCard: {
    gridArea: "left",
    overflow: "auto",
  },
  middleCard: {
    gridArea: "main",
    maxHeight: "calc(100vh - 90px)",
    overflow: "auto",
    scrollbarWidth: "thin",
    // maxWidth:"lg"
  },
  rightCard: {
    gridArea: "right",
    overflow: "auto",
    //  maxHeight: 'calc(100vh - 90px)',
  },
  paper: {
    // padding:"16px",
    borderRadius: 0,
    //  backgroundColor:"#3e01a4"

    //  color:"blue"
  },
  title: {
    fontSize: 20,
    padding: "5px 20px",
    //  color:"#fabc02"
    color: "white",
  },
  username: {
    fontSize: 14,
    fontWeight: 600,
    margin: "auto",
  },
  rounded: {
    width: "22px",
    height: "22px",
    fontSize: "11px",
    margin: "auto",
    background: "black",
    color: "blue",
  },
  rightalign: {
    textAlign: "right",
    display: "grid",
    gridTemplateColumns: "36px 105px 26px",
    float: "right",
    padding: "8px",
  },
  textName: {
    //  background: "#0a78fa",
    display: "inline",
    width: "129px",
    textAlign: "center",
    /* top: 13px; */
    marginTop: "2px",
    color: "#000000",
    //  fontSize: "13px"
    fontSize: 16,
  },
  TextValue: {
    //  paddingLeft: "10px",
    fontSize: 16,
    color: "#000000",
    // padding: "0px 90px ",
  },

  cdivider: {
    margin: "0px 10px",
  },
  appbar: {
    width: "100%",
    backgroundColor: "#3e01a4",
  },
  container: {
    padding: theme.spacing(2),
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  logo: {
    width: 100,
  },
  divider: {
    height: 30,
    alignSelf: "center",
    margin: "0px 15px",
    backgroundColor: "#fff",
  },
  userLogo: {
    marginRight: 15,
  },
});

const overlay = {
  position: "absolute",
  width: "100%",
  height: "100%",
  top: "0",
  left: "0",
  right: "0",
  bottom: " 0",
  backgroundColor: "rgb(217 188 188 / 50%)",
  zIndex: 100001,
};

class Entity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openleft: true,
      openRight: true,
      value: 1,
      enterpriseVSentity: "definition_panel",
      stateChangeForNav: true,
      username: "",
      lockScreen: true,
      snackopen: false,
      snackmsg: "Please select field first",
      snacktype: "error",
      saveopen: false,
      autoHideDuration: 2000,
      align: true,
      collapse: true,
    };
    this.collapse = this.collapse.bind(this);
    this.topAlign = this.topAlign.bind(this);
    this.expandLeftCard = this.expandLeftCard.bind(this);
    this.handleRealatioShipClose = this.handleRealatioShipClose.bind(this);
    this.handleRealatioShipOpen = this.handleRealatioShipOpen.bind(this);
    this.setValue = this.setValue.bind(this);
  }
  async componentDidMount() {
    if (this.props.location.search && this.props.location.search.length > 0) {
      let params = new URLSearchParams(this.props.location.search);

      // sessionStorage.setItem('metadataname', params.get('metaDataName'))
      sessionStorage.setItem("metadataId", params.get("metadata_id"));
      sessionStorage.setItem("metaId", params.get("metadata_id"));
      sessionStorage.setItem("token", params.get("token"));
      var base64Url = params.get("token").split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      let getTokenDetails = JSON.parse(jsonPayload);
      this.setState((prev) => ({
        ...prev,
        username: getTokenDetails.given_name,
      }));
      sessionStorage.setItem("is_db", params.get("isDB"));
      let payload = {
        db_name: `${environment.database}`,
        entity: "projectvstools",
        filter: `projectvstools.metadataid=='${params.get("metadata_id")}'`,
        return_fields: `{projectvstools}`,
      };
      let project_details = {};
      await axios
        .post(`${environment.api_url}/api/read_documents`, payload)
        .then(async (response) => {
          if (response?.data?.Code === 201) {
            project_details = response?.data?.result[0]?.projectvstools;
          } else {
          }
        })
        .catch((error) => { });
      let project_info = {};
      if (project_details?.projectid) {
        let project_payload = {
          db_name: `${environment.database}`,
          entity: "projects",
          filter: `projects._id=='${project_details?.projectid}'`,
          return_fields: `{projects}`,
        };
        await axios
          .post(`${environment.api_url}/api/read_documents`, project_payload)
          .then(async (response) => {
            if (response?.data?.Code === 201) {
              project_info = response?.data?.result[0]?.projects;
              sessionStorage.setItem(
                "metadataname",
                response?.data?.result[0]?.projects?.projectname
              );
              sessionStorage.setItem("clientDB", project_details.dbname);
              sessionStorage.setItem(
                "projectId",
                response?.data?.result[0]?.projects?.projectid
              );
              // project_details = response?.data?.result[0]?.projectname;
            } else {
            }
          })
          .catch((error) => { });

        if (project_info?.projectid) {
          let client_payload = {
            db_name: `${environment.database}`,
            entity: "clients",
            filter: `clients._id=='${project_details?.clientid}'`,
            return_fields: `{clients}`,
          };
          await axios
            .post(`${environment.api_url}/api/read_documents`, client_payload)
            .then(async (response) => {
              if (response?.data?.Code === 201) {
                sessionStorage.setItem(
                  "clientName",
                  response?.data?.result[0]?.clients?.clientname
                );
                sessionStorage.setItem(
                  "clientId",
                  response?.data?.result[0]?.clients?.clientid
                );

                this.setState((prev) => ({
                  ...prev,
                  stateChangeForNav: false,
                }));
                // project_details = response?.data?.result[0]?.projectname;
              } else {
              }
            })
            .catch((error) => { });
        }
        if (sessionStorage.is_db === "true") {
          this.setState({
            ...this.state,
            enterpriseVSentity: "definition_panel",
          });
        } else {
          this.setState({
            ...this.state,
            enterpriseVSentity: "definition_panel",
          });
        }
      }
      let screenPayload = {
        db_name: `${environment.database}`,
        entity: "ScreenLock",
        metadata_dbname: `ATP_Platform`,
        filter: `ScreenLock.metadataid=='${sessionStorage.getItem(
          "metadataId"
        )}'`,
        return_fields: `{ScreenLock}`,
      };
      let ScreenLock_details = {};
      await axios
        .post(`${environment.api_url}/api/read_documents`, screenPayload)
        .then(async (response) => {
          if (response?.data?.Code === 201) {
            ScreenLock_details = response?.data?.result;
          } else {
          }
        });
      if (ScreenLock_details.length > 0) {
        const Result = ScreenLock_details[ScreenLock_details.length - 1];

        if (Result.ScreenLock.IsLocked) {
          if (Result.ScreenLock.userId === getTokenDetails.preferred_username) {
            this.setState({ lockScreen: false });
            sessionStorage.setItem("Is_Lock", false);
          } else {
            this.setState({ lockScreen: true });
            sessionStorage.setItem("Is_Lock", true);

            //   this.setState({
            //     saveopen: true,
            //     snackmsg: `Screen Locked By ${Result.ScreenLock.userId}`,
            //     snacktype: 'error',
            //     autoHideDuration: 5000,
            //     isSave: true

            // })
          }
        } else {
          this.setState({ lockScreen: false });
          sessionStorage.setItem("Is_Lock", false);
        }
      } else {
        this.setState({ lockScreen: false });
        sessionStorage.setItem("Is_Lock", false);
      }
    }
    let generalpay = {
      "db_name": environment.database,
      "entity": "entity_fields_published",
      "filter": `entity_fields_published.projectId=='${sessionStorage.getItem("projectId")}'&& entity_fields_published.clientId=='${sessionStorage.getItem("clientId")}' && entity_fields_published.active=='Y' && entity_fields_published.metadataId !='${sessionStorage.getItem("metadataId")}' && entity_fields_published.activestatus==true`,
      "return_fields": "distinct entity_fields_published.metadataId",
    }

    await axios
      .post(`${environment.api_url}/api/read_documents`, generalpay)
      .then(async (response) => {
        if (response?.data?.Code === 201) {

          sessionStorage.setItem("Entity_metadataId", response?.data?.result[0]);
          console.log(response.data.result[0], "generalpay", generalpay)
        }
      });
  }
  shouldComponentUpdate(prev, next) {
    if (!sessionStorage.clientId) {
      return false;
    }
    return true;
  }
  expandLeftCard() {
    let { openleft } = this.state;
    this.setState({ openleft: !openleft });
  }
  handleTab = (val) => {
    const state = this.state;
    if (val === "definition_panel") {
      sessionStorage.setItem("is_db", true);
    } else {
      sessionStorage.setItem("is_db", false);
    }
    this.setState({ ...state, enterpriseVSentity: val });
  };
  closeSnackBar = () => {
    this.setState({ snackopen: false, saveopen: false });
  };
  handleRealatioShipClose = () => {
    this.setState({ relationshipAnchorEl: null });
  };
  handleRealatioShipOpen = (e, data, relation) => {
    this.setState({
      relationshipAnchorEl: e,
      relationdata: relation,
      enitityData: data,
    });
  };

  handleGroupRealatioShipOpen = (e, data, relation) => {
    this.setState({
      grouprelationshipAnchorEl: e,
      relationdata: relation,
      enitityData: data,
    });
  };
  handleGroupRealatioShipClose = () => {
    this.setState({ grouprelationshipAnchorEl: null });
  };
  setValue = (value) => {
    this.setState({
      value: value,
    });
  };

  topAlign = async () => {
    // alert("topalign")
    // this.setState({align:false})
    if (this.state.align == true) {
      this.setState({ align: false });
    } else if (this.state.align == false) {
      await setTimeout(this.setState({ align: true }), 1000);
      this.setState({ align: false });
    }
  };
  collapse = async () => {
    // alert("working")
    this.setState({ collapse: !this.state.collapse });
    setTimeout(() => {
      this.setState({ collapse: !this.state.collapse });
    }, 1000);
  };
  render() {
    let {
      openleft,
      enterpriseVSentity,
      saveopen,
      autoHideDuration,
      relationshipAnchorEl,
      relationdata,
      enitityData,
      value,
      grouprelationshipAnchorEl,
      snackopen,
      snackmsg,
      snacktype,
    } = this.state;
    let { classes } = this.props;
    let context = this.context;

    console.log("when reader state changes", sessionStorage.getItem(
      "Entity_metadataId"
    ));

    return (
      <>
        {this.state.lockScreen ? (
          <div className={classes.root}>
            {saveopen && (
              <Alerts
                severity={snacktype}
                open={saveopen}
                vertical={"top"}
                horizontal={"right"}
                msg={snackmsg}
                autoHideDuration={autoHideDuration}
                onclose={() => this.closeSnackBar()}
              />
            )}
            {/* Your nav bars here */}

            <Paper className={classes.paper}>
              {/* <Paper className={classes.paper} style={{width:"100%" , height:"100%" , top:0 , bottom:0, left:0,right:0,backgroundColor:"rgba(217, 188, 188, 0.5)" , position:"absolute" , zIndex:100001}}> */}
              <Grid container>
                <Grid item md={12} style={{ display: "flex" }}>
                  <Grid
                    item
                    md={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "150px",
                    }}
                  >
                    <Typography
                      className={classes.textName}
                      style={{ display: "inline" }}
                      sx={{ border: 2 }}
                    >
                      Client Name
                    </Typography>
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={classes.divider}
                      style={{ backgroundColor: "black", height: "12px" }}
                    />
                    <Typography className={classes.TextValue}>
                      {sessionStorage.getItem("clientName")
                        ? sessionStorage.getItem("clientName")
                        : "Foodzo v0.1"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "80px",
                    }}
                  >
                    <Typography
                      className={classes.textName}
                      style={{ display: "inline" }}
                    >
                      Project Name
                    </Typography>
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={classes.divider}
                      style={{ backgroundColor: "black", height: "12px" }}
                    />
                    <Typography className={classes.TextValue}>
                      {sessionStorage.getItem("metadataname")
                        ? sessionStorage.getItem("metadataname")
                        : "Foodzo v0.1"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      className={classes.textName}
                      style={{ display: "inline" }}
                    >
                      Database Name
                    </Typography>
                    <Typography className={classes.TextValue}>
                      {sessionStorage.getItem("clientDB")
                        ? sessionStorage.getItem("clientDB")
                        : "Foodzo v0.1"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            {/* Content */}

            <div className={classes.content}>
              <Grid container className={classes.root}>
                <Grid item md={12}>
                  <SecondTopNav
                    enterpriseVSentity={enterpriseVSentity}
                    handleTab={this.handleTab}
                    value={value}
                    username={this.state.username}
                    data={this.props.location.search}
                    align={this.state.align}
                    collapse={this.state.collapse}
                    topAlign={this.topAlign}
                    collapseFun={this.collapse}
                  />
                </Grid>

                {/* this is a enterprise modeler Component */}

                {enterpriseVSentity === "enterprise_modeler" && <Enterprise />}
                {/* end */}

                {enterpriseVSentity === "definition_panel" && (
                  <Grid
                    item
                    md={12}
                    className={classes.leftCardParent}
                    style={{
                      gridTemplateColumns: `${openleft ? `279px` : "100px"} ${context.isopen ? `1fr  300px` : ""
                        }`,
                    }}
                  >
                    <Grid className={classes.leftCard}>
                      <LeftCard
                        expandLeftCard={this.expandLeftCard}
                        left={openleft}
                      />
                    </Grid>

                    <Grid className={classes.middleCard}>
                      {/* <Iframe> */}
                      <MiddleCard
                        handleRealatioShipOpen={this.handleRealatioShipOpen}
                        handleGroupRealatioShipOpen={
                          this.handleGroupRealatioShipOpen
                        }
                        align={this.state.align}
                        collapse={this.state.collapse}
                      />
                      {/* </Iframe>  */}
                      <HideCard data={this.props.location.search} />
                      <BottomCard />
                    </Grid>

                    <Grid className={classes.rightCard}>
                      <RightCard />
                    </Grid>
                  </Grid>
                )}
                {enterpriseVSentity === "definition_panel" && <JSONDiff />}

                {enterpriseVSentity === "general_Master" && (
                  <div style={{ width: "100%", overflowY: "scroll", height: "400px" }}>
                    <GeneralMaster
                      URl={environment.api_url}
                      db_name={sessionStorage.getItem("clientDB")}
                      metadataId={sessionStorage.getItem(
                        "Entity_metadataId"
                      )}
                      metadata_dbname={environment.database}
                      theme={require("../../themes/default.json")}
                    />
                    {/* <ToastContainer /> */}
                  </div>
                )}

                <Drawer
                  anchor={"right"}
                  open={Boolean(relationshipAnchorEl)}
                  onClose={this.handleRealatioShipClose}
                >
                  <React.Fragment>
                    {Boolean(relationshipAnchorEl) && (
                      <AddRelationShip
                        current={enitityData}
                        currentField={relationdata}
                        onclose={this.handleRealatioShipClose}
                      />
                    )}
                  </React.Fragment>
                </Drawer>

                <Drawer
                  anchor={"right"}
                  open={Boolean(grouprelationshipAnchorEl)}
                  onClose={this.handleGroupRealatioShipClose}
                >
                  <React.Fragment>
                    {Boolean(grouprelationshipAnchorEl) && (
                      <AddGroupRelationShip
                        current={enitityData}
                        currentField={relationdata}
                        onclose={this.handleGroupRealatioShipClose}
                      />
                    )}
                  </React.Fragment>
                </Drawer>
              </Grid>
            </div>
            {/* <ToastContainer /> */}
          </div>
        ) : (
          <div className={classes.root}>
            {/* Your nav bars here */}

            <Paper className={classes.paper}>
              {/* <Paper className={classes.paper} style={{width:"100%" , height:"100%" , top:0 , bottom:0, left:0,right:0,backgroundColor:"rgba(217, 188, 188, 0.5)" , position:"absolute" , zIndex:100001}}> */}
              <Grid container>
                <Grid item md={12} style={{ display: "flex" }}>
                  <Grid
                    item
                    md={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "150px",
                    }}
                  >
                    <Typography
                      className={classes.textName}
                      style={{ display: "inline" }}
                      sx={{ border: 2 }}
                    >
                      Client Name
                    </Typography>
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={classes.divider}
                      style={{ backgroundColor: "black", height: "12px" }}
                    />
                    <Typography className={classes.TextValue}>
                      {sessionStorage.getItem("clientName")
                        ? sessionStorage.getItem("clientName")
                        : "Foodzo v0.1"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "80px",
                    }}
                  >
                    <Typography
                      className={classes.textName}
                      style={{ display: "inline" }}
                    >
                      Project Name
                    </Typography>
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={classes.divider}
                      style={{ backgroundColor: "black", height: "12px" }}
                    />
                    <Typography className={classes.TextValue}>
                      {sessionStorage.getItem("metadataname")
                        ? sessionStorage.getItem("metadataname")
                        : "Foodzo v0.1"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      className={classes.textName}
                      style={{ display: "inline" }}
                    >
                      Database Name
                    </Typography>
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={classes.divider}
                      style={{ backgroundColor: "black", height: "12px" }}
                    />
                    <Typography className={classes.TextValue}>
                      {sessionStorage.getItem("clientDB")
                        ? sessionStorage.getItem("clientDB")
                        : "Foodzo v0.1"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            {/* Content */}
            <div className={classes.content}>
              <Grid container className={classes.root}>
                <Grid item md={12}>
                  <SecondTopNav
                    enterpriseVSentity={enterpriseVSentity}
                    handleTab={this.handleTab}
                    value={value}
                    username={this.state.username}
                    data={this.props.location.search}
                    align={this.state.align}
                    collapse={this.state.collapse}
                    topAlign={this.topAlign}
                    collapseFun={this.collapse}
                  />
                </Grid>

                {/* this is a enterprise modeler Component */}

                {enterpriseVSentity === "enterprise_modeler" && <Enterprise />}
                {/* end */}

                {enterpriseVSentity === "definition_panel" && (
                  <Grid
                    item
                    md={12}
                    className={classes.leftCardParent}
                    style={{
                      gridTemplateColumns: `${openleft ? `279px` : "100px"} ${context.isopen ? `1fr  300px` : ""
                        }`,
                    }}
                  >
                    <Grid className={classes.leftCard}>
                      <LeftCard
                        expandLeftCard={this.expandLeftCard}
                        left={openleft}
                      />
                    </Grid>

                    <Grid className={classes.middleCard}>
                      {/* <Iframe> */}
                      <MiddleCard
                        handleRealatioShipOpen={this.handleRealatioShipOpen}
                        handleGroupRealatioShipOpen={
                          this.handleGroupRealatioShipOpen
                        }
                        align={this.state.align}
                        collapse={this.state.collapse}
                      />
                      {/* </Iframe>  */}
                      <HideCard data={this.props.location.search} />
                      <BottomCard />
                    </Grid>

                    <Grid className={classes.rightCard}>
                      <RightCard />
                    </Grid>
                  </Grid>
                )}
                {enterpriseVSentity === "definition_panel" && <JSONDiff />}
                {enterpriseVSentity === "general_Master" && (
                  <div style={{ width: "100%", overflow: "scroll", height: "400px" }} >
                    <GeneralMaster
                      URl={environment.api_url}
                      db_name={sessionStorage.getItem("clientDB")}
                      metadataId={sessionStorage.getItem(
                        "Entity_metadataId"
                      )}
                      metadata_dbname={environment.database}
                      theme={require("../../themes/default.json")}
                    />
                    {/* <ToastContainer /> */}
                  </div>
                )}
                <Drawer
                  anchor={"right"}
                  open={Boolean(relationshipAnchorEl)}
                  onClose={this.handleRealatioShipClose}
                >
                  <React.Fragment>
                    {Boolean(relationshipAnchorEl) && (
                      <AddRelationShip
                        current={enitityData}
                        currentField={relationdata}
                        onclose={this.handleRealatioShipClose}
                      />
                    )}
                  </React.Fragment>
                </Drawer>

                <Drawer
                  anchor={"right"}
                  open={Boolean(grouprelationshipAnchorEl)}
                  onClose={this.handleGroupRealatioShipClose}
                >
                  <React.Fragment>
                    {Boolean(grouprelationshipAnchorEl) && (
                      <AddGroupRelationShip
                        current={enitityData}
                        currentField={relationdata}
                        onclose={this.handleGroupRealatioShipClose}
                      />
                    )}
                  </React.Fragment>
                </Drawer>
              </Grid>
            </div >
            {/* <ToastContainer /> */}
          </div >
        )
        }

      </>
    );
  }
}
Entity.contextType = RightSideNav;
export default withNavBars(withStyles(useStyles)(Entity));
