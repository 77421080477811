import React from 'react';
import { Grid, Typography, Avatar, Divider, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { JsonCheckerContext } from '../../../contexts';
import axios from 'axios';
import { Alerts } from '../../../components';
import { withAllContexts } from '../../../HOCs';
import environment  from '../../../config';
const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
        padding: "10px 10px"
    },
    avatarParent: {
        display: "flex"
    },
    avatar: {
        width: "32px",
        height: "31px",
        margin: "auto auto auto 0"
    },
    parentCard: {
        display: "grid",
        gridTemplateColumns: "42px 1fr 35px"
    },
    byName: {
        color: theme.palette.text.light
    },
    dividerLine: {
        height: "11px",
        display: "inline-block",
        marginLeft: "10px",
        marginRight: "10px"
    },
    button: {
        marginTop: 20,
        width: "100%"
    },
    viewAction: {
        background: "#f50057",
        fontSize: "14px",
        color: "white",
        textAlign: "center",
        borderRadius: "5px",
        cursor: "pointer"
    }
}));
class History extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            versionList: [],
            compareVersion: [],
            isopen: false,
            snackmsg: '',
            snacktype: '',
            viewVersion: ''

        }
    }
    componentDidMount() {
        this.getVersion();

    }
    
    
    OpenVersionChecker() {
        let context = this.context;
        if (this.state.compareVersion.length === 2) {
            this.setState({
                isopen: false
            })
            // context.setJsonChecker({
            //     ...context,
            //     isopen: true
            // })
            this.getCompareVersion();

        } else {
            this.setState({
                isopen: true,
                snacktype: "info",
                snackmsg: 'please select two  version'
            })
        }

    }
    async getCompareVersion() {
        let context = this.context;
        let payload = {
            "metadataId": sessionStorage.getItem('metadataId'),
            "entitycode": sessionStorage.getItem('entityCode'),
            "entity": sessionStorage.getItem('entity'),
            "version": this.state.compareVersion
        }
        await axios
            .post(`${environment.api_url}/api/compare_entity_version`, payload).then(async (res) => {
                console.log(res);
                // this.setState({
                //   compareVersionList: res.data,
                //   oldCode:`${res.data[0]}`,
                //   newCode:`${res.data[1]}`

                // })
                context.setJsonChecker({
                    isopen: true,
                    compareVersionList: res.data,
                    oldCode: `${JSON.stringify(res.data[0], null, 20)}`,
                    newCode: `${JSON.stringify(res.data[1], null, 20)}`,
                    compareVersion: this.state.compareVersion
                })
            })
            .catch(err => {
            });
    }
    selectVersion(version) {
        if (this.state.compareVersion.some(list => list === version)) {
            let compareVersion = this.state.compareVersion;
            compareVersion = compareVersion.filter(_ => _ !== version);
            this.setState({
                compareVersion
            })
        }
        else {
            let compareVersion = this.state.compareVersion;
            compareVersion.push(version);
            this.setState({
                compareVersion
            });
        }
    }
    closeSnackBar() {
        this.setState({
            isopen: false
        })
    }
    async getVersion() {
        let context = this.context;
        let payload = {
            "metadataId": sessionStorage.getItem('metadataId'),
            "entitycode": this.props?.properties?.editTable?.entitycode ??  '',
            "entity": this.props?.properties?.editTable?.entity  ?? ''
            // "entitycode": sessionStorage.getItem('entityCode'),
            // "entity": sessionStorage.getItem('entity')
        }
        if (sessionStorage.getItem('metadataId')) {
            await axios
                .post(`${environment.api_url}/api/list_entity_version`, payload).then(async (res) => {
                    this.setState({
                        versionList: res.data
                    });
                    context.setJsonChecker({
                        versionList: res.data
                    });
                })
                .catch(err => {
                });
        }
        console.log("VERSIONLIST",this.state.versionList)

    }
    
    async viewEntityVersion(version) {
        const { leftContext } = this.props;
        let { setEntity } = leftContext;
        // let { setRelation } = relationshipcontext
        let context = this.context;
        let payload = {
            "metadataId": sessionStorage.getItem('metadataId'),
            "entitycode": sessionStorage.getItem('entityCode'),
            "entity": sessionStorage.getItem('entity'),
            "version": [version]
        }
        await axios
            .post(`${environment.api_url}/api/compare_entity_version`, payload).then(async (res) => {
                console.log(res);
                setEntity({
                    ...leftContext,
                    list: { [res.data[0].entity_group_name]: res.data }
                });
                // this.setState({
                //     viewVersion:version
                // })
                // console.log(Object.keys(res.data))
                context.setJsonChecker({
                    ...context,
                    viewVersion: version
                })
            })
            .catch(err => {
            });
    }
    
    render() {
        console.log("VERSIONLIST",this.state.versionList)
        let { classes } = this.props;
        let { viewVersion } = this.context;
        let { versionList, isopen, snackmsg, snacktype, compareVersion } = this.state;
        return (
            <React.Fragment>
                <Grid container className={classes.root}>
                    {isopen && <Alerts severity={snacktype} open={isopen} vertical={'top'} horizontal={'right'} msg={snackmsg} onclose={() => this.closeSnackBar()} />}

                    {
                        versionList?.map((list, index) => (
                            <>
                                <Grid item md={12} className={classes.parentCard} >
                                    {
                                        compareVersion?.some(data => data === list.version) ?
                                            <>
                                                <div className={classes.avatarParent} onClick={() => this.selectVersion(list.version)}><Avatar className={classes.avatar} style={{ background: "#38aa7c" }}> <img src={require('../../../assets/path-11-copy.svg')} /></Avatar></div>

                                            </>
                                            :
                                            <>
                                                <div className={classes.avatarParent} onClick={() => this.selectVersion(list.version)}><Avatar className={classes.avatar} /></div>

                                            </>
                                    }

                                    <div>
                                        {
                                            list?.entity?.length <=10 ? 
                                            <Typography variant="subtitle1">{`${list.entity} v${list.version}`}</Typography>
                                            :
                                            <abbr title={list.entity}>
                                            <Typography variant="subtitle1">{`${list?.entity?.slice(0,10)}... v${list.version}`}</Typography>

                                            </abbr>
                                        }
                                        
                                        <Typography variant="caption" className={classes.byName}>by you</Typography>
                                        <Divider orientation="vertical" className={classes.dividerLine} />
                                        <Typography variant="caption" className={classes.byName}>{`${JSON.stringify(list?.createdate)?.slice(6, 8)}/${JSON.stringify(list?.createdate)?.slice(4, 6)}/${JSON.stringify(list?.createdate)?.slice(0, 4)}`}</Typography>
                                    </div>
                                    <div>

                                        <Typography color="secondary" variant="subtitle1" onClick={() => this.viewEntityVersion(list.version)} className={viewVersion === list.version ? classes.viewAction : ""} style={{ cursor: "pointer" }}>view</Typography>
                                    </div>
                                </Grid>
                            </>
                        ))
                    }
                    {
                        versionList?.length > 0 ? <>
                            <Grid item md={12}>
                                <Button className={classes.button} onClick={() => this.OpenVersionChecker()} variant="contained" color="secondary">Compare version</Button>
                            </Grid>
                        </> : <label style={{ textAlign: "center", margin: "auto" }}>No data found</label>
                    }
                </Grid>
            </React.Fragment>
        )
    }
}
History.contextType = JsonCheckerContext;
export default withStyles(useStyles)(withAllContexts(History));