import React from 'react';
import { Grid, TextField, Divider, Popover, Button, Box, Typography, Checkbox, Modal, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { EntityLeftContext } from '../../../contexts';
import { withAllContexts } from '../../../HOCs';
import { getTemplatesList } from '../../../functions';
import { SelectBox, TagSelects } from '../common';
import { ErrorValidationOfField } from '../../../utils';
import { performData } from '../../../utils'
import { uuid } from 'uuidv4';
import axios from "axios";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { converEntityData } from '../../../contexts/entity'
import { Alerts } from '../../alert';
import { withRouter } from 'react-router-dom';
import environment from '../../../config';
import { async, CreateRule } from "mdm-rule-builder";
const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        width: "100%"
    },
    toparea: {
        padding: "20px 20px 0px",
        width: 300
    },
    toparea1: {
        padding: "20px 20px 0px",
        width: 300,
        maxHeight: "78vh",
        minHeight: "78vh",
        overflow: "auto"
    },
    bottomarea: {
        padding: "4px 20px",
        textAlign: "right"
    },
    bottomSpace: {
        marginBottom: 20
    },
    checkBox: {
        padding: 0,
        float: "right"
    },
    fontFormat: {
        textTransform: "capitalize"
    },
    templateEntity: {
        height: "40vh",
        marginBottom: "24px"
    },
    rulestyle: {
        position: "absolute",
        color: "red",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "70%",
        height: "80%",
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: 3,
        overflowY: "scroll",
        background: "white",

    },

}));

const colors = ["red", "#bdbd4d", "#5bbabe", "rgb(24 108 140)", "blue"]
const Types = [
    { label: "MDO", value: "MDO" },
    { label: "RDO", value: "RDO" },

]
const oldowner = [
    { label: "Manikandan", value: "Manikandan" },
    { label: "Sasikumar", value: "Sasikumar" },
    { label: "Vijay", value: "Vijay" },
    { label: "Gokul", value: "Gokul" },
    { label: "Manoj", value: "Manoj" }
]
const DataClassificationType = [
    { label: "Restricted", value: "Restricted" },
    { label: "Confidential", value: "Confidential" },
    { label: "Internal", value: "Internal" },
    { label: "Private", value: "Private" },
    { label: "Public", value: "Public" }
]
const AllowedDataProcessors = [
    { label: "Any", value: "Any" },

]
const SelectRoles = [
    { label: "owner", value: "owner" },
    { label: "custodian", value: "custodian" },
]

class AddEntity extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: null,
            title: null,
            description: null,
            type: null,
            p_owner: null,
            s_owner: null,
            owner: [{ label: "", value: "" }],
            DataClassification: null,
            Exact: null,
            Approx: null,
            EmailAllow: null,
            // Activelog:null,
            create: null,
            update: null,
            Delete: null,
            SelectRole: null,
            AllowedData: null,
            selected_color: "red",
            fieldType: null,
            tags: null,
            isActivity: false,
            isAcceptUserInput: false,
            resources: null,
            error: {
                type: false,
                title: false
            },
            errorMsg: {
                type: 'Please enter the field',
                title: 'Please enter the field'
            },
            stateuser: {},
            // activity: {
            //     create: false,
            //     update: false,
            //     // read: false,
            //     delete: false
            // },
            // handleClose:true,
            handleOpen: false,
            // documentschema:JSON.parse(sessionStorage.getItem("FinalDocuments")),
            // ruleId:JSON.parse(sessionStorage.getItem("finalrule")),

            // ApproxMatch:null,
            // ExactMatch:null,


            saveopen: true,
            snackmsg: 'find',
            autoHideDuration: 2000,
            snacktype: 'success',
            isSaveEntity: false,
        }
    }
    componentDidMount() {


        let tokenFromUrl = localStorage.getItem("token");
        let parsedToken = tokenFromUrl?.split(".")[1];
        let userInfo = JSON.parse(window.atob(parsedToken));
        this.setState({ stateuser: userInfo });





        const { editData, resouceTemplates, data } = this.props;
        let res = getTemplatesList(this.props, true, true, true);
        console.log("editData22", data)
        if (editData) {
            let dimenvalue = data.fields.filter(li => li.fieldType === "d");
            var singlevalue = dimenvalue.map((a) => a.fieldType)
            // let mdmmetadata = singleArray.filter(li => li.fieldType === "d");
            const [d] = singlevalue;
            // console.log("one",dimenvalue)
            this.setState({ fieldType: d })
            // console.log("singleArray",mdmmetadata)
        }
        if (editData) {
            this.setState({
                title: editData.entity,
                description: editData?.attributes?.description,
                type: editData?.attributes?.type,
                p_owner: editData?.attributes?.permission?.data_owner,
                s_owner: editData?.attributes?.permission?.custodian,
                DataClassification: editData?.attributes?.classification_type,
                Exact: editData?.attributes?.exact_match,
                Approx: editData?.attributes?.approx_match,
                selected_color: editData?.attributes?.style?.color,
                isActivity: editData.isActivity,
                EmailAllow: editData?.attributes?.allow_email_notification,
                // Activelog:editData?.attributes?.allow_Activelog,
                create: editData?.activity?.create,
                update: editData?.activity?.update,
                Delete: editData?.activity?.delete,
                SelectRole: editData?.attributes?.roles,
                AllowedData: editData?.attributes?.data_processors,
                isAcceptUserInput: editData.isAcceptUserInput,
                // activity: editData.activity,
                // activity: {
                //     create:editData.activity.create ,
                //     update: editData.activity.update,
                //     // read: false,
                //     delete: editData.activity.delete
                // },

            })
        } else {
            this.setState({
                resourcesList: res
            })
        }
        // owner: [{ lable: "", value: "" }],
        let owners = sessionStorage.getItem("clientName")
        this.setState((prev) => ({
            ...prev,

            owner: [{ label: userInfo.given_name, value: userInfo.given_name }]
        }))

        console.log(userInfo.given_name, "this.state.stateuser.given_name")
    }
    //     common=(data)=>{
    //     console.log("new",data[0].attributes.id)
    //    this.publishid(data[0].attributes.id,"close")
    //     }

    publishid = (id, action) => {


        debugger
        let { publishForEntity } = this.props
        let { listOfPublish, setListofPublishlist } = publishForEntity

        console.log("oooo", id, "", action)
        if (action === "open") {

            const found = listOfPublish.find(element => element == id);
            if (!found) {
                console.log("newww", found)
                listOfPublish.push(id)

            } else {
                console.log("neww", found)
            }


        } else if (action === "close") {

            let listOfPubli = listOfPublish.filter(_filter => _filter !== id)
            console.log("listOfPublish", listOfPubli)
            setListofPublishlist({
                ...publishForEntity,
                listOfPublish: listOfPubli
            })
        }
    }
    setValue = (name, value) => {

        let errorField = this.state.error;
        let errorMsg = this.state.errorMsg;
        errorMsg.title = 'Please enter the field';
        errorField[name] = ErrorValidationOfField(value);
        if (name === 'title') {
            value = value.trim();
        }
        this.setState({
            [name]: value,
            error: errorField,
            errorMsg
        })
    }

    onSaveClicked = async (data) => {
        //    console.log("newkey",data)
        if (!data) {
            this.handleClose()
        } else {
            let ruleid = [data?.Result[0]?.properties?.doc?._id];
            // let entity=sessionStorage.getItem("entityCode")
            let payload = {
                "db_name": "ATP_Metadata_Dev",
                "entity": "entity_fields_published",
                "filter": `entity_fields_published.entitycode == '${sessionStorage.getItem("entityCode")}'`,
                "return_fields": "entity_fields_published._key"
            }
            await axios.post(` https://arangodbservice.dev.ainqaplatform.in/api/read_documents`, { ...payload }).then(async (res) => {
                // console.log("_key",res.data.result)
                let _key = res.data.result
                this.UpdateCreatequeue(_key, ruleid);

            })

            // console.log(ruleid)
            // props.updateState("entrycriteria", data)
            // this.UpdateCreatequeue(ruleid);
        }

    };


    UpdateCreatequeue = async (_key, ruleid) => {
        try {
            let params = [
                {
                    db_name: "ATP_Metadata_Dev",
                    entity: "entity_fields_published",
                    filter: {
                        _key: `${_key}`,
                        // 625133878
                    },
                    is_metadata: false,
                    // metadataId: "f75aa786-df9a-41df-ac21-358aacaba7d9",
                    // metadata_dbname: "ATP_Metadata_Dev",
                    doc: {
                        RuleID: ruleid,
                    },
                },
            ];
            let config = {
                method: "post",
                url: "https://arangodbservice.dev.ainqaplatform.in/api/upsert_document",
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(params),
            };
            await axios(config)
                .then((res) => {

                })
                .catch((err) => {
                    console.error(err);
                });
        } catch (error) {
            console.log(error);
        }
    };
    handleOpen = () => {
        debugger
        this.setState({ handleOpen: true })
    }
    handleClose = () => {
        this.setState({ handleOpen: false })

    }


    AddEntity() {
        debugger
        let { activity, isActivity, tags, isAcceptUserInput, title, description, AllowedData, SelectRole, EmailAllow, Activelog, create, update, Delete, type, selected_color, p_owner, s_owner, DataClassification, Exact, Approx, errorMsg, ExactMatch } = this.state;
        let { list, setEntity } = this.context;
        let { onclose, editData, resouceTemplates, renameContext } = this.props;
        let { rename, setRenameObj } = renameContext;
        let cloneState = this.state;
        // this.props.publishid(this.props.data.attributes.id,"close")
        cloneState.errorMsg.title = 'Please enter the field';
        let is_error = false;
        // this.props.publishopen.setisopen(false)
        //     if(editData){
        //     // this.props.publishopen.setisopen(false)
        //     console.log("edit",editData)
        //     this.publishid(editData.attributes.id,"close")
        // }else{
        //     console.log("newedit",editData)
        //     // this.props.publishopen.setisopen(false)
        // }

        if (!title || title.length <= 0 || !/([A-Za-z]{1}[\w\-]+)$/y.test(title)) {
            cloneState.error.title = true;
            is_error = true;
        } else if (title.length > 0) {
            cloneState.error.title = false;
        }
        //set all entity to  single array
        let listOfEntity = [];
        for (let _li in list) {
            listOfEntity = [...list[_li], ...listOfEntity];
        }
        //duplicate entity check from entitycontext
        for (let i = 0; i < listOfEntity?.length; i++) {
            if (listOfEntity[i]?.entity === title && editData?.attributes?.id !== listOfEntity[i]?.attributes?.id) {
                cloneState.error.title = true;
                cloneState.errorMsg.title = "Duplicate entity not allow";
                is_error = true;

            }
        }
        //push rename object to renameContext
        if (editData) {
            let checkwithrename = rename.some(_ck => _ck.id === editData.attributes.id);
            if (checkwithrename) {
                let checksameEntity = false;
                rename = rename.map(_re => {
                    if (_re.id === editData.attributes.id) {
                        _re.renameEntity = title;
                        if (_re.renameEntity === _re.entity) {
                            checksameEntity = true;
                        }
                    }
                    return _re;
                });
                if (checksameEntity) {
                    rename = rename.filter(_fl => _fl.id !== editData.attributes.id);
                }
            } else {
                rename.push({
                    "db_name": sessionStorage.getItem('metadataname'),
                    "entity": editData?.entity,
                    "renameEntity": title,
                    "id": editData.attributes.id
                });
            }

            setRenameObj({
                rename
            })
        }

        if (!type || Object.keys(type).length <= 0) {
            cloneState.error.type = true;
            is_error = true;
        } else if (Object.keys(type).length > 0) {
            cloneState.error.type = false;
        }
        if (is_error) {
            this.setState({
                ...cloneState,

            })
            return;
        }
        this.disableSaveBtn();
        const { resources } = resouceTemplates;
        if (editData) {

            list[editData.attributes.type.value] && list[editData.attributes.type.value]?.length > 0 && list[editData.attributes.type.value].map((val, i) => {
                if (val.id === editData.id && val.attributes.id === editData.attributes.id) {
                    list[editData.attributes.type.value][i] = {
                        ...list[editData.attributes.type.value][i],
                        entity: title,
                        status: "draft",
                        entity_group_name: type.value,
                        type,
                        db_objectname: title,
                        isActivity: isActivity,

                        isAcceptUserInput: isAcceptUserInput,
                        // indexes:[],
                        // activity: activity,

                        activity: {
                            create: create,
                            update: update,
                            delete: Delete,
                        },
                        attributes: {
                            ...list[editData.attributes.type.value][i].attributes,
                            type,
                            description,
                            classification_type: DataClassification,
                            exact_match: Exact,
                            approx_match: Approx,
                            allow_email_notification: EmailAllow,
                            // allow_Activelog:Activelog,

                            roles: SelectRole,
                            data_processors: AllowedData,
                            permission: {
                                data_owner: p_owner,
                                custodian: s_owner,
                            },
                            style: {
                                color: selected_color
                            }
                        },
                    }
                }
                return false
            })
            let values = Object.values(list)
            let datalist = []
            values.map(val => {
                datalist = [...datalist, ...val]
                return false
            });
            list = converEntityData(datalist);
        } else {
            let data = list[type.value] ? JSON.parse(JSON.stringify(list[type.value])) : [];
            let newTableId = uuid();
            data.push({
                entity: title,
                status: "draft",
                db_objectname: title,
                fields: this?.state?.resources?.fields ?? [
                    {
                        "name": "_id",
                        "properties": {
                            "datatype": "string",
                            "mandatory": false,
                            "length": {
                                "min": "",
                                "max": ""
                            },
                            "Sequence": {
                                "suffix": "",
                                "prefix": "",
                                "StartValue": 0,
                                "SequenceId": "",
                                "isSequence": false,
                                "IncrementBy": 0,
                                "MaxValue": 0,
                                "IsReset": false
                            },
                            "autoGenerate": false,
                            "defaultValue": "",
                            "IsLOV": false,
                            "LOVType": "",
                            "LOV": [
                                {
                                    "key": "",
                                    "value": ""
                                }
                            ],
                            //   "CaseSensitive_Match": "",
                            "Accuracy_Match": "",
                            "allow_lookup_data": false,
                            "lookup_referece_name": "",
                            "LOV_value_fields": "value",
                            "LOV_key_field": "key",
                            "LOV_ref_collection": "",
                            "isCaseSensitive": false,
                            "IsPseducode": false,
                            "Pseducode": "Now()",
                            "CaseSensitive": "uppercase",
                            "regexPattern": "",
                            "Unique": true,
                            "Security": {
                                "Encryption": {
                                    "Script": "AES"
                                }
                            }
                        },
                        "fieldType": "f",
                        "attributes": {
                            "id": uuid(),
                            "table": title,
                            "tableID": newTableId,
                            "selected_color": "red"
                        }
                    },
                    {
                        "name": "grc",
                        "properties": {
                            "datatype": "string",
                            "mandatory": false,
                            "length": {
                                "min": "",
                                "max": ""
                            },
                            "Sequence": {
                                "suffix": "",
                                "prefix": "",
                                "StartValue": 0,
                                "SequenceId": "",
                                "isSequence": false,
                                "IncrementBy": 0,
                                "MaxValue": 0,
                                "IsReset": false
                            },
                            "autoGenerate": false,
                            "defaultValue": "",
                            "IsLOV": false,
                            "LOVType": "",
                            "LOV": [
                                {
                                    "key": "",
                                    "value": ""
                                }
                            ],
                            //   "CaseSensitive_Match": "",
                            "Accuracy_Match": "",
                            "allow_lookup_data": false,
                            "lookup_referece_name": "",
                            "LOV_value_fields": "value",
                            "LOV_key_field": "key",
                            "LOV_ref_collection": "",
                            "isCaseSensitive": false,
                            "IsPseducode": false,
                            "Pseducode": "Now()",
                            "CaseSensitive": "uppercase",
                            "regexPattern": "",
                            "Unique": true,
                            "Security": {
                                "Encryption": {
                                    "Script": "AES"
                                }
                            }
                        },
                        "fieldType": "f",
                        "attributes": {
                            "id": uuid(),
                            "table": title,
                            "tableID": newTableId,
                            "selected_color": "red"
                        }
                    },

                ],
                indexes: [],
                entity_group_name: type.value,
                isActivity: isActivity,

                isAcceptUserInput: isAcceptUserInput,
                // activity: activity,
                activity: {
                    create: create,
                    update: update,
                    delete: Delete,
                },
                attributes: {
                    description,
                    tags: tags,
                    id: newTableId,
                    type,

                    left: 10,
                    top: 10,
                    classification_type: DataClassification,
                    // exact_match:Exact,
                    // approx_match:Approx,
                    allow_email_notification: EmailAllow,
                    // allow_Activelog:Activelog,

                    roles: SelectRole,
                    data_processors: AllowedData,
                    permission: {
                        data_owner: p_owner,
                        custodian: s_owner,
                    },
                    style: {
                        color: selected_color
                    }
                }
            });
            list[type.value] = data;
            // let message = this.props.isTemplate ? 'Template created successfully' : 'Entity created successfully';
            // this.setState(prevState=>({
            //     ...prevState,
            //     saveopen:true,
            //     snackmsg: message,
            //     autoHideDuration:2000,
            //     snacktype:'success'
            // }))
            //  this.common(data)
        }
        setEntity({
            ...this.context,
            list
        })
        if (editData) {
            // this.props.publishopen.setisopen(false)
            console.log("edit", editData)
            this.publishid(editData.attributes.id, "close")
        } else {
            console.log("newedit", editData)
            this.props.publishopen.setisopen(false)

        }
        // this.props.publishopen.setisopen(false)
        if (onclose) {
            onclose()
        }
    }
    closeSnackBar = () => {
        this.setState(prevState => ({
            ...prevState,
            saveopen: false
        }));
    }


    disableSaveBtn = () => {
        debugger
        const { customTemplate } = this.props.customTemplate;
        this.setState(prevState => ({
            ...prevState,
            isSaveEntity: true
        }));
        const cloneState = this.state;
        if (this.props.onSaveFun) {
            cloneState.errorMsg.title = 'Please enter the field';
            let is_error = false;

            if (!cloneState?.title || cloneState?.title?.length <= 0 || !/([A-Za-z]{1}[\w\-]+)$/y.test(cloneState?.title)) {
                cloneState.error.title = true;
                is_error = true;
            } else if (cloneState?.title.length > 0) {
                cloneState.error.title = false;
            }
            //set all entity to  single array
            let listOfEntity = customTemplate;
            // for(let _li in list){
            //     listOfEntity  = [...list[_li],...listOfEntity];
            // }
            //duplicate entity check from entitycontext
            for (let i = 0; i < listOfEntity?.length; i++) {
                if (listOfEntity[i]?.entity === cloneState.title) {
                    cloneState.error.title = true;
                    cloneState.errorMsg.title = "Duplicate entity not allow";
                    is_error = true;
                    break;
                }
            }

            if (!is_error) {
                this.props.onSaveFun(this.state)
                this.props.onclose()
            }
        }
        this.setState(prevState => ({
            ...prevState,
            ...cloneState,
            isSaveEntity: false

        }));
    }
    render() {

        let { classes, onclose, isHeight, editData, list, data, publishid } = this.props;
        // let owner = ["thiru"]
        console.log("dcdc", list)
        let { owner, documentschema, ruleId, activity, tags, resources, resourcesList, anchorEl, isActivity, ruleclick, ruleopen, isAcceptUserInput, title, description, AllowedData, SelectRole, EmailAllow, Activelog, create, update, Delete, type, p_owner, s_owner, DataClassification, Exact, Approx, selected_color, error, isSaveEntity, errorMsg, ExactMatch, ApproxMatch, handleOpen } = this.state;
        // console.log("ererer",this.state.fieldType)
        console.log(this.state.stateuser.given_name, "user")

        // ? sessionStorage.getItem("clientName")
        // : "Foodzo v0.1"


        // var singleArray=data.map((a)=>a.fields.fieldType)
        // let dimension= data.filter(employee => {
        //     return employee.fields== "dimension";
        //   })

        return (
            < >

                <Grid item md={12} >
                    <div className={isHeight ? classes.toparea1 : classes?.toparea}>
                        <Typography className={classes?.bottomSpace} >{editData ? 'Edit' : 'Add'} {this.props.isTemplate ? 'Template' : 'Entity'}</Typography>
                        <TextField value={title} onChange={(e) => this.setValue('title', e.target.value)} className={classes.input + ' ' + classes.bottomSpace} size="small" label={(this.props.isTemplate ? 'Template' : 'Entity') + " Name"} variant="outlined" error={error.title ? true : false} helperText={error.title ? errorMsg.title : ''} />
                        {!this.props.isTemplate && <React.Fragment>
                            <SelectBox
                                list={Types}
                                className={classes.bottomSpace}
                                onchange={(e, value) => { this.setValue('type', value) }}
                                value={type}
                                title='Entity Types'
                                // disabled={this.state.fieldType?true:false}
                                indicate={this.state.fieldType ? true : false}
                                entityname={this.state.fieldType ? data.entity : ""}
                                error={error.type}


                            />
                            <SelectBox
                                list={owner}
                                classname={classes.bottomSpace}
                                onchange={(e, value) => { this.setValue('p_owner', value) }}
                                value={p_owner}
                                title='Data Owner'
                            />
                            <SelectBox
                                list={owner}
                                classname={classes.bottomSpace}
                                onchange={(e, value) => { this.setValue('s_owner', value) }}
                                value={s_owner}
                                title='Custodian'
                            />
                            <SelectBox
                                list={DataClassificationType}
                                classname={classes.bottomSpace}
                                onchange={(e, value) => { this.setValue('DataClassification', value) }}
                                value={DataClassification}
                                title='Data Classification'
                            />

                            {
                                // !editData && <React.Fragment>

                                //     {/* <TagSelects
                                //         // list={tagsList}
                                //         classname={classes.bottomSpace}
                                //         onchange={(e, value) => { this.setValue('tags', value) }}
                                //         value={tags}
                                //         placeholder={"Select Tags"}
                                //         title='Select Tags'
                                //     /> */}
                                // </React.Fragment>
                            }
                            {/* <TextField value={description} onChange={(e) => this.setValue('description', e.target.value)} className={classes.input + ' ' + classes.bottomSpace} size="small" label="Description" multiline rows={4} variant="outlined" /> */}
                            {/* <TextField value={description} /> */}
                            {/* <TextField value={ExactMatch } onChange={(e) => this.setValue('Exact Match %', e.target.value)} className={classes.input + ' ' + classes.bottomSpace} size="small" label={"Exact Match %"} variant="outlined" error={error.title ? true:false}  helperText={error.title ? errorMsg.title:''} />
                            <TextField value={ApproxMatch} onChange={(e) => this.setValue('Approx Match %', e.target.value)} className={classes.input + ' ' + classes.bottomSpace} size="small" label={"Approx Match %"} variant="outlined" error={error.title ? true:false}  helperText={error.title ? errorMsg.title:''} /> */}

                            {/* <Typography variant="caption">Exact Match %</Typography>
                                         <Grid item md='6' sm='6'>
                                         <TextField
                                                    inputProps={{ style: { height: "14px" } }}
                                                    id="min-length"
                                                    value={Exact}
                                                    onChange={(e) => this.setValue('Exact', e.target.value)}
                                                     className={classes.input + ' ' + classes.bottomSpace}
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    // onKeyDown={(e)=>(IsNumberKeyDown(e,true)) }
                                                    onInput={  
                                                        (e) => {
                                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString();
                                                        }
                                                    }
                                                    onKeyDown={(evt) => (evt.which === 40 || evt.which === 38) && evt.preventDefault()}

                                                />
                        </Grid> */}

                            {/* <Grid item md='6' sm='6' >
                                                <Typography variant="caption">Approx Match %</Typography>
                                                <TextField
                                                    inputProps={{ style: { height: "14px" } }}
                                                    id="min-length"
                                                    value={Approx}
                                                    onChange={(e) => this.setValue('Approx', e.target.value)}
                                                     className={classes.input + ' ' + classes.bottomSpace}
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    // onKeyDown={(e)=>(IsNumberKeyDown(e,true)) }
                                                    onInput={
                                                        (e) => {
                                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString();
                                                        }
                                                    }
                                                    onKeyDown={(evt) => (evt.which === 40 || evt.which === 38) && evt.preventDefault()}
                                                />
                                            </Grid> */}




                            <SelectBox
                                list={AllowedDataProcessors}
                                classname={classes.bottomSpace}
                                onchange={(e, value) => { this.setValue('AllowedData', value) }}
                                value={AllowedData}
                                title='AllowedDataProcessors'
                            />
                            <TextField value={description} onChange={(e) => this.setValue('description', e.target.value)} className={classes.input + ' ' + classes.bottomSpace} size="small" label="Description" multiline rows={4} variant="outlined" />

                        </React.Fragment>}
                        <Grid container className={classes.bottomSpace}>
                            <Grid md={6}>
                                <Typography>Email Notification </Typography>
                            </Grid>
                            <Grid md={6}>
                                <Checkbox className={classes.checkBox} checked={EmailAllow} onChange={(event) => this.setState({ EmailAllow: event.target.checked })} />
                            </Grid>

                            <Grid container className={classes.bottomSpace}>
                                {EmailAllow &&
                                    <SelectBox
                                        list={SelectRoles}
                                        classname={classes.bottomSpace}
                                        onchange={(e, value) => { this.setValue('SelectRole', value) }}
                                        value={SelectRole}
                                        title='SelectRole'
                                    />}

                            </Grid>
                        </Grid>



                        <Grid container className={classes.bottomSpace}>
                            <Grid md={6}>
                                <Typography>Active log </Typography>
                            </Grid>
                            <Grid md={6}>
                                <Checkbox className={classes.checkBox} checked={isActivity} onChange={(event) => this.setState({ isActivity: event.target.checked })} />
                            </Grid>

                            <Grid container className={classes.bottomSpace}>
                                {isActivity &&

                                    <>
                                        {/* <Divider style={{color:"red",width:"297px",height:"2px"}}/> */}
                                        <Grid md={6}>
                                            <Typography>Create </Typography>
                                        </Grid>
                                        <Grid md={6}>
                                            <Checkbox
                                                className={classes.checkBox}
                                                checked={create} onChange={(event) => this.setState({ create: event.target.checked })}
                                            //   checked={this.state.activity.create} onChange={(event) => this.setState({ ...this.state,activity:{...this.state.activity,create:event.target.checked} })}
                                            />
                                        </Grid>


                                        <Grid md={6}>
                                            <Typography>Update </Typography>
                                        </Grid>
                                        <Grid md={6}>
                                            <Checkbox
                                                className={classes.checkBox}
                                                checked={update} onChange={(event) => this.setState({ update: event.target.checked })}
                                            />
                                        </Grid>


                                        <Grid md={6}>
                                            <Typography>Delete </Typography>
                                        </Grid>
                                        <Grid md={6}>
                                            <Checkbox
                                                className={classes.checkBox}
                                                checked={Delete} onChange={(event) => this.setState({ Delete: event.target.checked })}
                                            />
                                        </Grid>
                                    </>
                                }

                            </Grid>



                            <Grid md={6}>
                                <Typography>Accept User Input </Typography>
                            </Grid>
                            <Grid md={6}>
                                <Checkbox className={classes.checkBox}
                                    checked={isAcceptUserInput} onChange={(event) => this.setState({ isAcceptUserInput: event.target.checked })}
                                />
                            </Grid>

                        </Grid>




                        {/* <Grid>
                            
                            <Typography variant="caption">{editData?"Colour :":null}</Typography>
                            {editData?colors.map(val => <Checkbox checked={selected_color === val} onChange={() => this.setValue('selected_color', val)} style={{ color: val }} icon={<FiberManualRecordIcon />} checkedIcon={<CheckCircleIcon />} name="checkedH" />):null}
                        </Grid> */}

                    </div>
                    <Divider />
                    <Grid className={classes.bottomarea}>
                        <Button onClick={() => onclose()} className={classes.fontFormat}>Cancel</Button>
                        {editData?.entity_group_name == "MDO" ? <Button onClick={() => this.handleOpen()} className={classes.fontFormat}>Rule</Button> : null}

                        {
                            isSaveEntity ?
                                <Button size="small" color="secondary" style={{ marginRight: "14px" }} className={classes.fontFormat} >Save</Button>
                                :
                                <Button onClick={this.props.onSaveFun ? () => {
                                    this.disableSaveBtn();

                                } : () => this.AddEntity()} size="small" variant="contained" color="secondary" className={classes.fontFormat} >Save</Button>
                        }


                    </Grid>

                </Grid>
                {/* <Grid className={classes.bottomarea}>
                        <Button onClick={() => onclose()} className={classes.fontFormat}>Cancel</Button>
                      { editData? <Button onClick={() => this.handleOpen()} className={classes.fontFormat}>Rule</Button>:null}
                        
                        {
                            isSaveEntity ? 
                            <Button  size="small"  color="secondary" style={{marginRight:"14px"}} className={classes.fontFormat} >Save</Button>
                            :
                            <Button onClick={this.props.onSaveFun ? () => {
                                this.disableSaveBtn();
                              
                            } : () => this.AddEntity()} size="small" variant="contained" color="secondary" className={classes.fontFormat} >Save</Button>
                        }
                        
                       
                    </Grid> */}

                <Modal
                    open={handleOpen}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box style={{
                        position: "absolute",
                        // color:"red",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "70%",
                        height: "80%",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 3,
                        overflowY: "scroll",
                        background: "white",

                    }}>
                        {/* <Paper> */}
                        <CreateRule
                            inputSchema={this.props.finalDocu.finalDocu}
                            editId={this.props.ruleID.ruleid ?? null}
                            handleClose={(data) => {
                                this.onSaveClicked(data,);
                            }}
                            open={true}
                            performvalueData={{
                                stateList: [
                                    {
                                        label: "TRUE",

                                        states: "TRUE",
                                    },
                                    {
                                        label: "FALSE",

                                        states: "FALSE",
                                    },
                                ],
                            }}
                            performKeyData={performData}
                            dbName={environment.database}
                        />
                        {/* </Paper> */}
                    </Box>

                </Modal>
            </>


        )
    }
}

AddEntity.contextType = EntityLeftContext;

export default withRouter(withStyles(useStyles)(withAllContexts(AddEntity)));

// const ActivityData = [
//     { label: "Create", state: "create" },
//     { label: "Update", state: "update" },
//     // { label: "Read", state: "read" },
//     { label: "Delete", state: "delete" },
// ]
