const Types = [
    // { label: "Integer", value: 1 },
    // { label: "BigInt", value: 2 },
    // { label: "String", value: 3 },
    // { label: "Float", value: 4 },
    // { label: "Double", value: 5 },
    // { label: "Decimal", value: 6 },
    // { label: "LOV", value: 'lov' }
    { label: "Boolean", value: 1 },
    { label: "DigitalObject", value: 2 },
    { label: "Reference", value: 3 },
    { label: "String", value: 4 },
    { label: "Date", value: 5 },
    { label: "DateTime", value: 6 },
    // { label: "Decimal", value: 7 },
    { label: "Instant", value: 8 },
    { label: "Integer", value: 9 },
    { label: "PositiveInt", value: 10 },
    { label: "Time", value: 11 },
    { label: "UnsignedInt", value: 12 },
    { label: "dimension", value: 13 },
    { label: "number", value: 14 },
    { label: "object", value: 15 },
    { label: 'array', value: 16 }
    // { label: "LOV", value: 'lov' }

];
const performData = [
    {
      label: "Set Status",
      aliasName: "states",
      labelName: "states",
      listName: "stateList",
    },
  ];
const caseSensitiveData = [

    { label: 'uppercase', value: 'uppercase' },
    { label: 'lowercase', value: 'lowercase' },
    { label: 'camelcase', value: 'camelcase' },
    { label: 'propercase', value: 'propercase' },
    { label: 'mixedcase', value: 'mixedcase' }



];

const psudoData = [
    { label: 'Now()', value: 'Now()' },
    { label: 'Year()', value: 'Year()' },
    { label: 'MM()', value: 'MM()' },
    { label: 'MMMYYY()', value: 'MMMYYY()' },
    { label: 'DD()', value: 'DD()' },
    { label: 'YY()', value: 'YY()' },
    { label: 'MON()', value: 'MON()' },
    { label: 'DDMMYYYY()', value: 'DDMMYYYY()' }

];

const encriptionTypeData = [
    { label: 'AES', value: 'AES' },
    { label: 'MD5', value: 'MD5' },
]
const securityData = [
    // { label: 'Encryption', value: 'Encryption' },
    // { label: 'Sensitive Information', value: 'Sensitive_Information' },
    { label: 'Personal Information', value: 'Personal_information' },
    { label: 'Integrity Check', value: 'IntergrityCheck' }
]
const cardinalityOptions = [
    // { label: 'select', value: "0" },
    { label: '0..*', value: '0..*' },
    { label: '0..1', value: '0..1' },
    { label: '1..*', value: '1..*' },
    { label: '1..1', value: '1..1' }
]

const securityState = {
    Encryption: false,
    Sensitive_Information: false,
    Personal_information: false,
}


const typeData = [
    { label: 'Indexes', value: 'isindex' },
    { label: 'Masked', value: 'masked' },
    { label: 'Unique', value: 'unique' },
    
    { label: 'Auto Increment', value: 'Auto Increment' },
    { label: 'UUID', value: 'UUID' },
    { label: 'UUID', value: 'autoGenerate' }
]

const typeDataState = {
    indexed: false,
    masked: false,
    unique: false,
    autoIncrement: false,
    
}

const digitalObjectButton = [
    { label: 'PDF', value: 'pdf' },
    { label: 'Word', value: 'word' },
    { label: 'Video', value: 'video' },
    { label: 'JPEG', value: 'jpeg' },
    { label: 'Other', value: 'other' },
    { label: 'LOV', value: 'lov' }
]

const digitalObjectList = [
    { label: 'Readable / OCRable', value: 'readableOrOcrable' },
    { label: 'Compression', value: 'compression' },
    { label: 'Thumnail', value: 'Thumbnail' },
]

const digitalObjectListState = {
    compression: false,
    Thumbnail: false,
    readableOrOcrable: false
}
const indexTypes = [
    { label: 'Fulltext', value: 'fulltext' },
    { label: 'Persistent', value: 'persistent' },
    { label: 'Time to Live', value: 'ttl' },
    { label: 'Geo', value: 'geo' },

]

const barcodeTypes = [
    { label: 'UPC', value: 'UPC' },
    { label: 'QRCODE', value: 'QRCODE' },
]
export {
    Types,
    performData,
    securityData,
    typeData,
    digitalObjectButton,
    digitalObjectList,
    securityState,
    typeDataState,
    digitalObjectListState,
    cardinalityOptions,
    caseSensitiveData,
    psudoData,
    encriptionTypeData,
    indexTypes,
    barcodeTypes
}