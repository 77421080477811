import React from 'react';
import { Typography, Grid, Popover, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { AddEntity, CollapseListCard, ListCard, EntityFilter } from '../../../components';
import { EntityLeftContext } from '../../../contexts';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FilterListIcon from '@material-ui/icons/FilterList';
import { color } from '@mui/system';
const useStyles = ((theme) => ({
    bottomParenticon: {
        display: 'flex',
        margin: "auto 0px 0px 0px",
        borderTop: "0.5px solid #f3ecec"
    },
    topIcon: {
        margin: "12px auto",
        display: "inline",
        width: "auto"
    },
    bottomIcon: {
        margin: "12px auto",
        display: "inline",
        width: "auto",
        cursor: "pointer"
    },
    newIcon: {
        fontSize: "40px"
    },
    leftTitle: {
        fontSize: "15px",
        fontWeight: 600,
        display: "inline",
        color: "#0b78fa"
    },
    leftTitleIcon: {
        float: "right"
    },
    leftTitleParent: {
        margin: "13px 10px"
    },
    Filtercard: {
        "& .MuiPopover-paper": {
            borderRadius: "10px"
        }
    },
    notificationIcon: {
        marginLeft: "5px",
        background: "#0b78fa",
        color: "white",
        borderRadius: "50%",
        width: "18px",
        height: "18px !important"

    },
    notificationFont: {
        fontSize: "12px",
        fontWeight: "bold",
        textAlign: "center",


    }
}));

class ExpandCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            anchorEl: null,
            filterAnchorEl: null,
            open: null,
            // mdoLength:null,
            // rdoLength:null
        }
        this.handleClose = this.handleClose.bind(this)
    }
    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };
    OpenFilter = (event) => {
        this.setState({ filterAnchorEl: event.currentTarget });
    };
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    filterClose = () => {
        this.setState({ filterAnchorEl: null });
    };
    countList = (list) => {
        let count = 0;
        if (list.MDO?.length) {
            count = list.MDO?.length
            console.log("mdo", count)
        } else if (list.RDO?.length) {
            count = list.RDO?.length
            console.log("rdo", count)
        }
        return count;

    }

    render() {

        let { classes } = this.props;
        let { anchorEl, filterAnchorEl, count, } = this.state;
        let { list, selected } = this.context;

        return (
            <React.Fragment>
                <div className={classes.topParent}>

                    <Grid className={classes.leftTitleParent}>
                        <Grid style={{ display: "flex" }}>
                            <Typography className={classes.leftTitle}>MDM Entity</Typography>
                            <Grid className={classes.notificationIcon}>
                                <Typography className={classes.notificationFont}>{list?.["MDO"] && list?.["MDO"].length > 0 && list?.["RDO"] && list?.["RDO"].length > 0 ? list["MDO"].length + list["RDO"].length : this.countList(list)}</Typography>
                            </Grid>
                        </Grid>
                        {/* <FilterListIcon onClick={this.OpenFilter} className={classes.leftTitleIcon} /> */}
                    </Grid>
                    <Divider />
                    <Grid>
                        <CollapseListCard
                            title={"MDO"}
                            textStyle={{ fontSize: "11px", fontWeight: 600, color: "#b1b0b0" }}
                            collapseChildren={<ListCard titlekey='entity' selected={selected} isexpand={true} list={list["MDO"]} />}
                            icon={<img src={require('../../../assets/icons8-bulleted-list.svg').default} />}
                            NotificationCount={list?.["MDO"] && list?.["MDO"].length > 0 ? list["MDO"].length : '0'}
                        />

                        <CollapseListCard
                            title={"RDO"}
                            textStyle={{ fontSize: "11px", fontWeight: 600, color: "#b1b0b0" }}
                            collapseChildren={<ListCard titlekey='entity' selected={selected} isexpand={true} list={list["RDO"]} />}
                            //  icon={<CancelPresentationIcon />}
                            icon={<img src={require('../../../assets/icons8-bulleted-list.svg').default} />}
                            NotificationCount={list?.["RDO"] && list?.["RDO"].length > 0 ? list["RDO"].length : '0'}

                        />

                    </Grid>
                </div>

                <div className={classes.bottomParenticon} onClick={this.handleClick}>
                    <div className={classes.bottomIcon}>
                        <Typography color="secondary" className={classes.leftTitle} style={{ position: "fixed", bottom: "20px", left: "74px" }}>+ New Entity</Typography>
                    </div>
                </div>
                <Popover
                    id={'add_entity'}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{ vertical: 'center', horizontal: 'right', }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                >
                    <AddEntity isHeight={true} onclose={this.handleClose} />
                </Popover>
                {/* <Popover
                    id={'filter'}
                    className={classes.Filtercard}
                    open={Boolean(filterAnchorEl)}
                    anchorEl={filterAnchorEl}
                    onClose={this.filterClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                >
                    <EntityFilter onclose={this.filterClose} />
                </Popover> */}
            </React.Fragment>
        )
    }
}

ExpandCard.contextType = EntityLeftContext;

export default withStyles(useStyles)(ExpandCard);