

import React from "react";
import { Grid, makeStyles, withStyles, Popover } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import WebIcon from "@material-ui/icons/Web";
import Divider from "@material-ui/core/Divider";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Avatar from "@material-ui/core/Avatar";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Personalist from "./librarylist/Personalist";
// import DataJson from "../../../data.json";
import EditIcon from "@material-ui/icons/Edit";
import { withAllContexts } from "../../../HOCs";
import Text from "../leftSection/textfield";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme) => ({
  head: {
    boxShadow: "none",
    // width: 210,
    borderTop: "solid 1px rgba(17, 15, 71, 0.08)",
    borderRadius: "0!important",
    margin: 0 + " !important",
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "14px 0px",
    },
    "& .MuiAccordionSummary-content": {
      display: "inline-block",
    },
  },
  headtext: {
    paddingLeft: 10,
    fontSize: 12,
    color: "#9696a9",
    fontWeight: 500,
    textTransform: "uppercase",
  },
  webicons: {
    fontSize: 17,
    color: "grey",
  },
  imges: {
    width: 200,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    display: "flex",
  },
  avttar: {
    display: "inline-flex",
    margin: "16px 19px 16px 21px",
  },

  add: {
    color: "#e34d65",
    fontSize: 16,
    justifyContent: "right!important",
    textAlign: "right!important",
  },
  arrowdown: {
    color: "black",
    marginTop: -5,
  },
  textwidth: {
    borderRadius: 0,
    // padding: "7px 0px 7px 0px",
    height: "45vh",
    overflowY: "auto",
  },
  expmore: {
    display: "none",
  },
  avttxt: {
    display: "inline-flex",
    fontSize: "12px",
  },
  accro: {
    marginTop: -5 + " !important",
    marginBottom: -5 + " !important",
    minHeight: "0!important",
  },
  gridadd: {
    marginLeft: "72px",
  },
  pop: {
    padding: "15px",
    height: "200px",
    width: "260px",
  },
  input: {
    opacity: 0,
    position: "absolute",
    height: "100%",
    width: "100%",
  },
}));

const AccordionSummary = withStyles({
  root: {
    "& .MuiAccordionSummary-content": {
      margin: "9px 0px",
    },
  },
  expandIcon: {
    order: -1,
    width: "10px",
    height: "10px",
    // border: "1px solid rgba(17, 15, 71, 0.08)",

    color: "black",
    margin: "0px",
    backgroundColor: "white !important",
    "&$expanded": {
      transform: "rotate(90deg)",
    },
  },
  expanded: {
    // background: "whitesmoke",
  },
})(MuiAccordionSummary);

function Personas(props) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);

  // const { Data } = props.datajson;
  // const handleClick = () => {
  //   setOpen(!open);
  // };

  return (
    <div>
      <Accordion
        className={classes.head}
        onChange={() => props.handleClickA("persona")}
        expanded={props.openA === "persona"}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={
            <ArrowRightIcon fontSize="small" style={{ marginTop: -4 }} />
          }
          className={classes.accro}
        >
          <Grid container justify="space-between" style={{ marginTop: "2px" }}>
            <Grid item style={{ marginTop: "auto", marginBottom: "auto" }}>
              <div style={{ display: "flex" }}>
                <div>
                  <WebIcon className={classes.webicons} />
                </div>

                <div className={classes.headtext}>Persona</div>
              </div>
            </Grid>
            <Grid item>
              <div>
                <AddOutlinedIcon
                  className={classes.add}
                  onClick={props.handleClickP}
                />
              </div>
            </Grid>
          </Grid>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.textwidth}>
          <Personalist value={props.value} handleChange={props.handleChange} />
        </AccordionDetails>
      </Accordion>
      <Popover
        id={props.id}
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={classes.pop}>
          <div
            style={{
              textAlign: "center",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              paddingBottom: "6px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {!props.upload && (
              <label htmlFor="icon-button-file">
                <Avatar
                  id="icon-button-file"
                  style={{
                    width: "100px",
                    height: "100px",
                    margin: "auto",
                    fontSize: 12,
                    background: "#bdbdbd30",
                  }}
                  // color='primary'
                  // startIcon={
                  //   <CloudUploadIcon
                  //     style={{
                  //       fontSize: '1.5rem',
                  //       color: '#007AFF',
                  //       marginBottom: 4
                  //     }}
                  //   />
                  // }
                >
                  <input
                    accept="image/*"
                    className={classes.input}
                    // id='icon-button-file'
                    type="file"
                    onChange={(e) => props.handleImageChange(e)}
                  />
                  <CloudUploadIcon
                    style={{
                      fontSize: "1.5rem",
                      color: "#007AFF",
                      marginBottom: 4,
                    }}
                  />
                  &nbsp;
                  <span
                    style={{
                      color: "#417aff",
                      fontWeight: 500,
                    }}
                  >
                    Upload
                  </span>
                </Avatar>
              </label>
            )}
            {props.upload && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <label
                  htmlFor="icon-button-file"
                  id="icon-button-file"
                  style={{
                    width: "100px",
                    height: "100px",
                    margin: "auto",
                    fontSize: 12,
                  }}
                >
                  <Avatar
                    style={{
                      width: "100%",
                      height: "100%",
                      margin: "auto",
                      fontSize: 12,
                      background: "#bdbdbd85",
                    }}
                    className={classes.avtr}
                  >
                    {
                      <img
                        alt={props.upload}
                        src={props.upload}
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                          opacity: 0.7,
                        }}
                      />
                    }
                    <input
                      accept="image/*"
                      className={classes.input}
                      // id='icon-button-file'
                      type="file"
                      onChange={(e) => props.handleImageChange(e)}
                    />
                  </Avatar>
                  <EditIcon
                    style={{
                      marginTop: "-64px",
                      position: "absolute",
                      color: "#3f3f44",
                      marginLeft: "-10px",
                    }}
                  />
                </label>
              </div>
            )}
          </div>
          <br />
          <Text
            placeholder="New Persona"
            value={props.text}
            onChange={props.textChange}
            onKeyPress={props.keypresspersona}
            onClickadd={props.onClickPersona}
            onClickdelete={props.handleClose}
            error={props.err}
          />
        </div>
      </Popover>
    </div>
  );
}

export default withAllContexts(Personas);
