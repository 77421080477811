import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MiddleData,Iframe } from '../../../components';
import { ZoomContext,LoadingManagement } from '../../../contexts';
// import { Iframe, BottomCard, JSONDiff, EntityTemplate, AddRelationShip } from '../../components'

const useStyles = ((theme) => ({
    root: {
        flexGrow: 1
    },
    loading:{
        width: "100%",
        /* margin: auto; */
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 120px)"
    }
}));

class MiddleCard extends React.Component { 
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        let {align}=this.props
        let {collapse}=this.props
        console.log("collapse",collapse)
        console.log("alignm",this.props.align)
        let { classes } = this.props;
        return ( 
            <ZoomContext.Consumer>
                {
                    zoom => 
                    <>
                    <LoadingManagement.Consumer>
                        {
                            loading =>
                            <>
                            {
                        !loading?.isLoading ?
                        <div className={classes.loading}>
                           <CircularProgress />
                        </div>
                        :
                        
                        <Iframe zoom={zoom} >
    
                        <Grid container className={classes.root +"  test"} style={{ background : "red",zoom: zoom?.zoom }}>
                <Grid item md={12} className={classes.canvas}>
                    
                    
                    <MiddleData {...this.props} align={align} collapse={collapse}/>
                </Grid>
               </Grid> 
          
           </Iframe>
                    }
                            </>
                        }
                    </LoadingManagement.Consumer>
                    
                    </>
                    
                   
                  
                }
            </ZoomContext.Consumer>
        )
    }
}

export default withStyles(useStyles)(MiddleCard); 