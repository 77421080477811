import React from 'react';
// import { Grid,Drawer } from '@material-ui/core';
import { Button, Drawer, Divider, Grid, Typography, TextField } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import {
    BasicDetails,
    SequenceDetails,
    Security,
    DigitalObject
} from './';
import { withAllContexts } from "../../../../HOCs";
import { useStyles } from './style';
import { EntityLeftContext } from '../../../../contexts';
import environment from '../../../../config';
import axios from 'axios';
import { Alerts } from '../../../../components';
import { CreateRule } from "sequence-rule-builder";
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import ConfirmationBox from '../../../../functions/confirmationBox';


// const useStyles = ((theme) => ({
//     root: {
//         flexGrow: 1,
//         width: "395px"
//     },
//     headerRoot: {
//         padding: "9px 7px"
//     },
//     headertitleRoot: {
//         display: "flex",
//         margin: "auto"
//     },
//     headertitle: {
//         fontWeight: 600
//     },
//     headerButtonRoot: {
//         textAlign: "right"
//     },
//     headerButton: {
//         height: "26px",
//         marginLeft: "10px"
//     },
//     contentRoot: {
//         paddingTop: 0,
//         borderTop: "1px solid #f3f3f3"
//     },
//     currentTableName: {
//         fontWeight: 600,
//         fontSize: "14px",
//         marginRight: "8px",
//         marginLeft: "20px"
//     },
//     relationshipCaption: {
//         width: "100%",
//         marginRight: "10px"
//     },
//     selectTableField: {
//         marginLeft: "20px"
//     },
//     selectTable: {
//         width: "166px",
//         display: "inline-block",
//         '& .MuiFormControl-marginNormal': {
//             marginTop: 0
//         }
//     },
//     boxPadding: {
//         padding: "10px"
//     },
//     deleteFont:{
//         color: "white",
//         background: "#f50057",
//         padding: "5px",
//         textTransform: "capitalize",
//         fontSize: "12px",
//         '&:hover': {
//             background: "#f50057",
//         }
//     },
//     fontFormat:{
//         textTransform: "capitalize"
//     }
// }));
class Properties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            update: true,
            snackopen: false,
            snackmsg: '',
            snacktype: 'error',
            saveopen: false,
            autoHideDuration: 2000,
            isedgeFieldCollection: false,
            collectionList: [],
            fieldProperties: {},
            entityLists: [],
            fieldLists: [],
            isRuleBuilderOpen: false,
            sequenceFieldName: '',
            sequenceValue: '',
            sequenceAnchorEl: null,
            sequenceRuleIds: [],
            ruleAddIndex: 0,
            deleteBoxOpen: false,
            deleteBoxTitle: 'Confirmation',
            deleteBoxMessage: 'Are you sure delete this field ?',
            deleteBoxIndex: null,
            GroupByAnchorEl: null,
            groupBy: {},
            groupByArray: [{

            }]
        }
        this.setValueJSON = this.setValueJSON.bind(this);
    }
    componentDidMount() {

        let { datalist } = this.props;
        if (this.props.datalist?.editFieldData?.properties?.datatype === 'dimension') {

            this.dimensionFieldCollection();

        }
        if (this.props.datalist?.editFieldData?.properties?.datatype === 'reference') {
            this.getCollectionList();
        }
        if (this.props.datalist?.editFieldData?.properties?.datatype === 'string') {
            if (this.props.datalist?.editFieldData?.properties?.LOV_ref_collection?.length > 0) {
                this.getFieldLists(this.props.datalist?.editFieldData?.properties?.LOV_ref_collection);
            }
        }
        this.getEntityLists();
        this.props.propertiesChanges(datalist);
        this.getProperties();

    }

    getEntityLists = () => {
        let context = this.context;
        let entityFields = Object.keys(context.list);
        let entityValue = [];
        if (entityFields.length > 0) {
            entityFields.map(entityField => {
                entityValue = [...entityValue, ...context.list[entityField]]
                return false;
            })
        }
        if (entityValue.length > 0) {
            entityValue = entityValue.map(entity_val => {
                let params = {
                    label: entity_val.entity,
                    value: entity_val.entity
                }
                return params
            })
        }
        this.setState((prevState) => ({
            ...prevState,
            entityLists: entityValue
        }))
        // datalist.editFieldData.properties.entityLists = entityValue;  
    }
    getProperties = async () => {
        let fieldProperties = await this.backendProperty(this.props.datalist?.editFieldData?.properties?.datatype);
        this.setState((prevState) => ({
            ...prevState,
            fieldProperties: fieldProperties?.QDMProperties ?? {}
        }))
    };

    getCollectionList = () => {
        // let { datalist } = this.props;
        // let { editTable } = datalist;
        let params = {
            //"db_name":`${environment.database}`,
            "db_name": sessionStorage.getItem('clientDB'),
            "excludeDefault": true,
            "docType": "Document",


        }
        axios.post(`${environment.api_url}/api/getcollectionlist`, params)
            .then(response => {
                if (response?.data?.Result) {
                    this.setState(prevstate => ({
                        ...prevstate,
                        collectionList: response?.data?.Result
                    }));
                }
            })
    }
    // selectEntityList = () =>{
    //     let { datalist } = this.props;
    //     let { editTable } = datalist;
    // }
    getCollectionFields = async (collectionName) => {
        let params = {
            "db_name": sessionStorage.getItem('metadataname'),
            "entity": [collectionName],

        }
        let responseData = []
        await axios.post(`${environment.api_url}/api/getcollectionattributes`, params)
            .then(response => {
                if (response?.data?.Result[0]?.encounter_c) {
                    responseData = response?.data?.Result[0].encounter_c.map(_ => {
                        _.label = _.columnName;
                        _.value = _.columnName;
                        return _

                    })
                }
            })
            .catch(() => {
                responseData = []
            })

        return responseData;
    }
    dimensionFieldCollection = () => {
        let { editTable } = this.props.datalist;
        this.checkFieldCollection(editTable?.fields)
    }
    checkFieldCollection = (field) => {
        let { editFieldData } = this.props?.datalist;

        for (let i = 0; i < field?.length; i++) {
            // if(!fieldpath[i]){
            //   fieldpath[i] = {error:false,path:[]};

            // }
            if (field[i].fieldType === 'd') {
                //   if(field[i].properties.fieldCollection===false){
                if (field[i].properties.fieldCollection === true && field[i]?.attributes?.id === editFieldData?.attributes?.parentID) {
                    this.setState(prevState => ({
                        ...prevState,
                        isedgeFieldCollection: true
                    }))
                    break;
                }

                //  else{
                //     this.checkFieldCollection(field[i]?.properties?.fields);

                // }
                //   } 
                // this.validatePath[i] = fieldpath;

            }
        }
    }

    backendProperty = (type) => {

        return new Promise(async (resolve, reject) => {
            let ArangoProperty = this.props?.datalist?.databaseProperties;
            let filterData = ArangoProperty.filter((data) => data.label === type)[0];
            //  filterData.QDMProperties.properties.fields = [];

            //  return  filterData;

            resolve(filterData);
        })
    }


    editValueJSON = async (fields, stateName, value) => {
        let { datalist } = this.props;
        let editFieldId = datalist?.editFieldData?.attributes?.id;

        return new Promise(async (resolve, reject) => {
            await Promise.all(
                fields && fields.map(async (val, index) => {
                    if (editFieldId === val?.attributes?.id) {

                        //   fields[index].properties[stateName] = value;   
                        //   datalist.editFieldData.properties[stateName] = value;
                        if (datalist?.editFieldData?.datatype?.toLowerCase() !== value?.toLowerCase()) {
                            // await axios
                            //     .post(`${environment.api_url}/api/read_documents`, {
                            //         "db_name": `${environment.database}`,
                            //         "entity": `${environment.qdm_property_entity}`,
                            //         "filter": `${environment.qdm_property_entity}.datatype=='${value?.toLowerCase()}' `,
                            //         "return_fields": `{${environment.qdm_property_entity}}`
                            //     }
                            //     ).then(response => {
                            let dataTypeProperty = await this.backendProperty(value?.toLowerCase());
                            this.setState((prevState) => (
                                {
                                    ...prevState,
                                    fieldProperties: dataTypeProperty?.QDMProperties
                                }
                            ))
                            // let data = converData(response.data);
                            if (value?.toLowerCase() === 'dimension') {
                                fields[index].fieldType = 'd';
                            } else {
                                fields[index].fieldType = 'f';
                            }

                            // let clonePropertes = fields[index].properties;
                            fields[index].properties = { ...dataTypeProperty?.QDMProperties?.properties, datatype: value };

                            // delete fields[index].properties.dataTypeProperties;
                            // fields[index].properties.datatype =  value;

                            datalist.editFieldData.properties = { ...dataTypeProperty?.QDMProperties?.properties, datatype: value };
                            if (value === 'instant') {
                                datalist.editFieldData.properties.format = "dd/MM/yyyy HH:mm:ss";
                            }
                            // 
                            // })
                            // .catch(err => {
                            // });
                        }
                        this.props.propertiesChanges(datalist);

                    } else {
                        if (val.fields) {
                            let field = await this.editValueJSON(val.fields, stateName, value);
                            fields[index].fields = field;

                        } else if (val.properties.fields) {
                            let field = await this.editValueJSON(val.properties.fields, stateName, value);
                            fields[index].properties.fields = field;


                        }
                    }
                })
            );
            resolve(fields);
        });
    }
    setValueJSON = async (stateName, value) => {


        let { datalist } = this.props;
        let context = this.context;
        let fields = context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields;
        // let dataList = properties ? JSON.parse(JSON.stringify(properties)) : {};
        // dataList = {
        //     ...dataList,
        //     [stateName]: value 
        // }
        let updatedData = [];
        if (fields.length > 0) {
            updatedData = await this.editValueJSON(fields, stateName, value)
        }

        context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields = updatedData;

        // if(datalist?.editFieldData?.dataTypeProperties?.QDMProperties?.datatype.toLowerCase() !==  value.toLowerCase()){
        //     await axios
        //         .post(`${environment.api_url}/api/read_documents`, {
        //             "db_name": `${environment.database}`,
        //             "entity": `${environment.qdm_property_entity}`,
        //             "filter": `${environment.qdm_property_entity}.datatype=='${value.toLowerCase()}' `,
        //             "return_fields": `${environment.qdm_property_entity}`
        //         }
        //         ).then(response => {
        //             // let data = converData(response.data);
        //             context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields[datalist.fieldIndex].properties.dataTypeProperties = response.data.result[0];
        //             datalist.editFieldData.properties.dataTypeProperties =  response.data.result[0];

        //             // 
        //         })
        //         .catch(err => {
        //         });
        // }
        // this.props.propertiesChanges(datalist);
        context.setEntity(context)
    }
    fieldArray = [];
    filterCollectionName = (value) => {
        let context = this.context;
        let entityFields = Object.keys(context.list);
        let entityValue = [];
        if (entityFields.length > 0) {
            entityFields.map(entityField => {
                entityValue = [...entityValue, ...context.list[entityField]]
                return false;
            })
        }
        let collection = entityValue.filter((collect) => collect.entity === value)[0];

        this.fieldArray = [];
        let filterFieldName = this.filterFieldName(collection?.fields);
        return filterFieldName
    }
    filterFieldName = (collection) => {
        collection && collection.map((field_d, index) => {
            if (field_d.fieldType === 'f') {
                let params = {
                    label: field_d.name,
                    value: field_d.name
                }
                // field_d.label = field_d.name;
                this.fieldArray.push(params)
            }
            if (field_d.fields) {
                this.filterFieldName(field_d.fields)
            } else if (field_d.properties.fields) {
                this.filterFieldName(field_d.properties.fields)
            }
            return false
        });
        return this.fieldArray;
    }
    changeEdgeCollection = (fields) => {
        fields.map((field, inx) => {
            if (field.fieldType === 'd') {
                fields[inx].properties.isdimensionedge = false;
                fields[inx].properties.fields = this.changeEdgeCollection(field.properties.fields)
            }
        })
        return fields;
    }
    //create field list from entity collections
    getFieldLists = (value) => {
        let context = this.context;

        let entityFields = Object.keys(context.list);
        let entityValue = [];
        if (entityFields.length > 0) {
            entityFields.map(entityField => {
                entityValue = [...entityValue, ...context.list[entityField]]
                return false;
            })
        }
        let collection = entityValue.filter((collect) => collect.entity === value)[0];

        this.fieldArray = [];
        let filterFieldName = this.filterFieldName(collection?.fields);
        this.setState((prevState) => ({
            ...prevState,
            fieldLists: filterFieldName
        }))
    }
    //edit field properties
    editField = (fields, stateName, value, field) => {

        let { datalist } = this.props;
        let context = this.context;

        let editFieldId = datalist?.editFieldData?.attributes?.id;


        fields && fields.map((val, index) => {
            if (editFieldId === val?.attributes?.id) {
                if (field === 'dimension' && fields[index].properties) {
                    if (stateName === 'fieldCollection' && value) {
                        let setProperties = fields[index].properties;
                        let setDataListProperties = datalist.editFieldData.properties;
                        let reset_collecton = {
                            // "fields": [],
                            "isdimensionedge": false,
                            "ref_entity": "",
                            "fieldCollection": true
                        }
                        if (typeof datalist?.editFieldData?.attributes?.dimentionID === 'undefined') {
                            fields[index].properties = {
                                ...setProperties,
                                ...reset_collecton,
                                fields: this.changeEdgeCollection(fields[index]?.properties?.fields ?? [])
                            };
                            datalist.editFieldData.properties = {
                                ...setDataListProperties,
                                ...reset_collecton,
                                fields: this.changeEdgeCollection(fields[index]?.properties?.fields ?? [])
                            };
                        } else {


                            fields[index].properties = {
                                ...setProperties,
                                ...reset_collecton
                            };
                            datalist.editFieldData.properties = {
                                ...setDataListProperties,
                                ...reset_collecton
                            };
                        }

                    } else {
                        fields[index].properties[stateName] = value;
                        datalist.editFieldData.properties[stateName] = value;
                    }

                }
                if (field === 'property' && fields[index].properties) {
                    // if(stateName==='autoIncrement' && fields[index]?.properties?.Sequence?.isSequence){
                    //   fields[index].properties.Sequence.isSequence = !value;   
                    // }
                    let cloneField = fields[index].properties;
                    let cloneDataList = datalist.editFieldData.properties;
                    if ((stateName === 'IsLOV' && !value)) {

                        let lovParams = {
                            "IsLOV": false,
                            "LOVType": "",
                            "LOV": [
                                {
                                    "key": "",
                                    "value": ""
                                }
                            ],
                            "LOV_value_fields": "value",
                            "LOV_key_field": "key",
                            "LOV_ref_collection": "",
                        }
                        fields[index].properties = {
                            ...cloneField,
                            ...lovParams
                        };
                        datalist.editFieldData.properties = {
                            ...cloneDataList,
                            ...lovParams
                        };
                    } else if (stateName === 'isCaseSensitive' && !value) {

                        let CaseParams = {
                            isCaseSensitive: false,
                            CaseSensitive: ''
                        }
                        fields[index].properties = {
                            ...cloneField,
                            ...CaseParams
                        };
                        datalist.editFieldData.properties = {
                            ...cloneDataList,
                            ...CaseParams
                        };
                    } else if (stateName === 'IsPseducode' && !value) {
                        let pseduParams = {
                            IsPseducode: false,
                            Pseducode: ''
                        }
                        fields[index].properties = {
                            ...cloneField,
                            ...pseduParams
                        };
                        datalist.editFieldData.properties = {
                            ...cloneDataList,
                            ...pseduParams
                        };
                    }
                    else if (stateName === 'barcodetypes' && value != undefined) {
                        datalist.editFieldData.properties.barcodeType = value;

                        if (value === "UPC") {
                            datalist.editFieldData.properties.barcodeProp.width = "100";
                            datalist.editFieldData.properties.barcodeProp.height = "100";
                            datalist.editFieldData.properties.barcodeProp.errorFontsize = ""
                        }

                        else if (value === "QRCODE") {
                            datalist.editFieldData.properties.barcodeProp.fontsize = "1";
                            datalist.editFieldData.properties.barcodeProp.errorFontsize = ""
                        }
                    }
                    else if (stateName === 'fontsize' && value != undefined) {
                        datalist.editFieldData.properties.barcodeProp.fontsize = parseInt(value)

                        if (parseInt(value) >= 20 && parseInt(value) <= 50 || parseInt(value) == 1) {
                            datalist.editFieldData.properties.barcodeProp.errorFontsize = ""
                        }

                        else {
                            datalist.editFieldData.properties.barcodeProp.errorFontsize = "Barcode fontsize should be min 20 to max 50"
                        }

                    }
                    else if (stateName === 'barcode_width' && value != undefined) {
                        datalist.editFieldData.properties.barcodeProp.width = parseInt(value)

                    }
                    else if (stateName === 'barcode_height' && value != undefined) {
                        datalist.editFieldData.properties.barcodeProp.height = parseInt(value)

                    }
                    else {
                        if ((stateName === 'IsLOV' && value) || stateName === 'LOVType') {
                            // let entityFields = Object.keys(context.list);
                            // let entityValue = [];
                            // if(entityFields.length>0){
                            //     entityFields.map(entityField=>{
                            //         entityValue = [...entityValue,...context.list[entityField]]
                            //         return false;
                            //     })
                            // }
                            // if(entityValue.length>0){
                            //     entityValue=  entityValue.map(entity_val=>{
                            //         let params = {
                            //             label: entity_val.entity,
                            //             value :entity_val.entity
                            //        }
                            //         return params
                            //     })
                            // }
                            // datalist.editFieldData.properties.entityLists = entityValue;   

                        }
                        if (stateName === 'LOV_ref_collection') {
                            this.getFieldLists(value);
                            // datalist.editFieldData.properties.entityColumns = filterFieldName;
                        }
                        if (stateName === 'LOV') {

                            value = value
                        }
                        if (stateName === 'autoIncrement' && value) {
                            fields[index].properties.autoGenerate = false;
                            datalist.editFieldData.properties.autoGenerate = false;
                            fields[index].properties.defaultValue = '';
                            datalist.editFieldData.properties.defaultValue = '';
                        }
                        if (stateName === 'autoGenerate' && value) {
                            fields[index].properties.autoIncrement = false;
                            datalist.editFieldData.properties.autoIncrement = false;
                            fields[index].properties.defaultValue = '';
                            datalist.editFieldData.properties.defaultValue = '';
                        }
                        if (stateName === 'selectCollectionList' && value) {
                            let selectEntityLists = value.map(_s => _s.name);
                            let selectEntity = Object.keys(datalist.editFieldData.properties.reference_keyFields);
                            selectEntity.map(_ => {
                                if (selectEntityLists.indexOf(_) < 0) {
                                    delete fields[index].properties.reference_keyFields[_];
                                    delete datalist.editFieldData.properties.reference_keyFields[_];
                                }
                                return false
                            })
                        }
                        fields[index].properties[stateName] = value;
                        datalist.editFieldData.properties[stateName] = value;
                    }


                } else if (field === 'sequence' && fields[index].properties) {
                    const data = fields[index].properties
                    console.log("data", data)
                    if (stateName === 'isSequence' && !value) {
                        let sequenceParams = {
                            "suffix": "",
                            "prefix": "",
                            "StartValue": 0,
                            "SequenceId": "",
                            "isSequence": false,
                            "IncrementBy": 0,
                            "MaxValue": 0,
                            "IsReset": false
                        }
                        fields[index].properties.Sequence = sequenceParams;
                        datalist.editFieldData.properties.Sequence = sequenceParams;
                    } else {
                        if (stateName === 'isSequence') {
                            fields[index].properties.autoIncrement = !value;
                        }

                        if (stateName === 'IsReset' && !value) {
                            delete fields[index].properties.Sequence.reset_config;
                            delete datalist.editFieldData.properties.Sequence.reset_config;
                        }

                        fields[index].properties.Sequence[stateName] = value;

                        datalist.editFieldData.properties.Sequence[stateName] = value;

                        if (fields[index].properties.Sequence.reset_config !== undefined) {
                            if (["daywise", "monthwise", "custom"].includes(fields[index].properties.Sequence.reset_config.resetType)) {
                                fields[index].properties.Sequence.MaxValue = 0
                            }
                        }
                        if (stateName === 'groupBy') {
                            console.log("value", value)
                            console.log("valuelength", value.length)
                            const Arr = [];
                            if (value.length > 0) {
                                value.map(valuesss => {
                                    const datavaluee = JSON.parse(JSON.stringify(valuesss));
                                    Arr.push(datavaluee)

                                })
                            }

                            const ArrayValue = JSON.parse("[" + Arr + "]");
                            fields[index].properties.Sequence[stateName] = ArrayValue
                            this.setState((prev) => ({
                                ...prev,
                                GroupByAnchorEl: null
                            }));

                        }
                    }
                }


                this.props.propertiesChanges(datalist);

            } else {
                if (val.fields) {
                    fields[index].fields = this.editField(val.fields, stateName, value, field);
                } else if (val.properties.fields) {
                    fields[index].properties.fields = this.editField(val.properties.fields, stateName, value, field);
                }
            }
        })
        return fields;
    }
    //edit index properties
    updateIndexProperties = (name, value) => {
        let { datalist } = this.props;
        let context = this.context;
        if (name === 'indexType') {
            let modifyJson = {
                "indexType": value,
                "indexName": "",
                "isCreateInBackground": false,
                "minLength": 0,
                "isSparse": false,
                "isUnique": false,
                "expirationTime": 0,
                "isGeoJson": false,
                "deduplicateArray": false
            }
            context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].indexes =
                context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].indexes?.map(_fil_data => {
                    if (_fil_data.fieldId === datalist?.editFieldData?.attributes?.id) {
                        _fil_data = {
                            ..._fil_data,
                            ...modifyJson
                        }
                    }
                    return _fil_data;
                });
        } else {
            context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].indexes =
                context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].indexes?.map(_fil_data => {
                    if (_fil_data.fieldId === datalist?.editFieldData?.attributes?.id) {
                        _fil_data[name] = value
                    }
                    return _fil_data;
                });
        }

        context.setEntity(context)
    }
    setDataTypeProperties = async (stateName, value, field) => {
        debugger
        let { datalist } = this.props;
        console.log("datalist", datalist)
        let context = this.context;
        let fields = context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields;
        let updatedFields = [];

        if (stateName === 'isindex' && value) {
            context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].indexes = context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex]?.indexes ?? [];
            let indexFields = {
                "isIndex": true,
                "indexType": "",
                "indexName": "",
                "fields": datalist?.editFieldData?.name,
                "isCreateInBackground": false,
                "minLength": 0,
                "isSparse": false,
                "isUnique": false,
                "expirationTime": 0,
                "isGeoJson": false,
                "deduplicateArray": false,
                "fieldId": datalist?.editFieldData?.attributes?.id

            }
            context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].indexes.push(indexFields)
        } else if (stateName === 'isindex' && !value) {
            context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].indexes = context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].indexes.filter(_fil_data => _fil_data.fieldId !== datalist?.editFieldData?.attributes?.id);
        }
        else if (stateName === 'fontsize' && !value) {
            context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].indexes = context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].indexes.filter(_fil_data => _fil_data.fieldId !== datalist?.editFieldData?.attributes?.id);
        }
        if (stateName === 'isSequence' && !value && context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields[0]?.name === "rule_params") {
            let getRuleId = Object.values(datalist.editFieldData.properties.Sequence);
            getRuleId = getRuleId.filter(_rule => _rule.toString().indexOf('Rules/') !== -1);
            let constructRequest = [];
            getRuleId.map(rule => {
                rule.split(',').map(_ruleId => {
                    let payload = {
                        "db_name": "RuleBuilder",
                        "entity": "Rules",
                        "filter": `Rules.activestatus==true && Rules._id == '${_ruleId}'`,
                        "return_fields": "Rules"
                    }
                    let apiCall = axios.post(`${environment.api_url}/api/read_documents`, payload)
                    constructRequest.push(apiCall)
                })

            });
            let sequenceGeneratorParams = context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields[0];

            if (constructRequest.length > 0) {
                let responseRules = await Promise.all(constructRequest);
                responseRules = responseRules.reduce((prev, _fact) => { return [..._fact.data.result[0].facts.facts, ...prev] }, [])
                sequenceGeneratorParams.properties.fields = JSON.parse(JSON.stringify(sequenceGeneratorParams.properties.fields)).map(_construct => {
                    responseRules.map(_sequence => {
                        if (_construct.name === Object.keys(_sequence)[0]) {

                            if (_construct.properties.fields.some(_in => _in.name === _sequence[Object.keys(_sequence)[0]].attr)) {
                                if (_construct?.properties?.fields?.length > 0) {
                                    _construct.properties.fields = _construct.properties.fields.reduce((prevValue, _in) => {
                                        if (_in.name === _sequence[Object.keys(_sequence)[0]].attr) {
                                            if (_in.count > 1) {
                                                _in.count = _in.count - 1;
                                                prevValue.push(_in)
                                            }
                                            // _in.count = parseInt(_in.count)>0 ? _in.count+1 : 2;
                                        } else {
                                            prevValue.push(_in)
                                        }
                                        return prevValue;
                                    }, []);
                                }
                            }
                        }
                    })

                    return _construct;
                })


            }

            context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields[0] = sequenceGeneratorParams;

        }
        if (fields.length > 0) {

            updatedFields = this.editField(fields, stateName, value, field)

        }
        context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields = updatedFields;

        context.setEntity(context)

        if (stateName == "fieldCollection" && value == false) {
            console.log("conttt", stateName, value, field)
            this.setDataTypeProperties("isdimensionedge", true, 'dimension')
        }
    }
    // setDataTypeProperties = (stateName,value,field)=>{

    //     let { datalist } = this.props;
    //     let context = this.context;
    //     let properties = context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields[datalist.fieldIndex].properties;
    //     let dataList = properties ? JSON.parse(JSON.stringify(properties)) : {};
    //     let stateData = datalist?.editFieldData?.properties?.dataTypeProperties?.QDMProperties;
    //     if(field==='property'){
    //         stateData = {
    //             ...stateData,
    //             [stateName]:value
    //         }
    //         dataList = {
    //             ...dataList,
    //             [stateName]: value 
    //         }
    //         datalist.editFieldData = stateData;
    //         context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields[datalist.fieldIndex].properties = dataList;

    //     }  else if(field==='sequence'){
    //        stateData = stateData.Sequence;

    //         dataList = properties?.Sequence ? JSON.parse(JSON.stringify(properties?.Sequence )) : {};
    //         stateData = {
    //             ...stateData,
    //             [stateName]:value
    //         };
    //         dataList = {
    //             ...dataList,
    //             [stateName]: value 
    //         }
    //         datalist.editFieldData.Sequence = stateData;

    //     context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields[datalist.fieldIndex].properties.Sequence = dataList;

    //     }
    //     this.props.propertiesChanges(datalist);
    //     context.setEntity(context)  
    // }
    errorValidation = (params) => {
        this.setState(prevState => ({
            ...prevState,
            ...params
        }))
    }
    closeSnackBar() {
        this.setState({ snackopen: false, saveopen: false })
    }
    sequence_generator() { }
    setRuleBuilderAction = (params, fieldName, boxvalue, ruleindex) => {
        let { datalist } = this.props;
        let context = this.context;
        if (typeof params === 'boolean') {
            if (!params) {
                this.setState((prev) => ({
                    ...prev,
                    isRuleBuilderOpen: params
                }));
            } else {
                this.setState((prev) => ({
                    ...prev,
                    isRuleBuilderOpen: params,
                    sequenceFieldName: fieldName,
                    sequenceValue: boxvalue ? boxvalue : '',
                    ruleAddIndex: ruleindex
                }));
            }

            return;
        } else {
            // not yet validate duplicate fields in ruleparams
            let responseRuleBuilder = params?.Result[0]?.properties?.doc?.facts?.facts;
            let sequenceGeneratorParams = context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex]?.fields[0]?.name === 'rule_params' ? context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex]?.fields[0].properties.fields : [];

            if (responseRuleBuilder && responseRuleBuilder.length > 0) {
                responseRuleBuilder.map(_sequence => {
                    let _isExites = sequenceGeneratorParams.some(_exites => _exites.name === Object.keys(_sequence)[0])
                    if (_isExites) {

                        sequenceGeneratorParams = JSON.parse(JSON.stringify(sequenceGeneratorParams)).map(_construct => {
                            if (_construct.name === Object.keys(_sequence)[0]) {

                                if (!_construct.properties.fields.some(_in => _in.name === _sequence[Object.keys(_sequence)[0]].attr)) {
                                    if (_construct?.properties?.fields) {
                                        let struct = {
                                            "name": _sequence[Object.keys(_sequence)[0]].attr,
                                            "count": 1,
                                            "properties": {
                                                "datatype": _sequence[Object.keys(_sequence)[0]].type
                                            }
                                        };
                                        _construct.properties.fields = [..._construct.properties.fields, struct];
                                    }
                                } else {
                                    _construct.properties.fields = _construct.properties.fields.map(_in => {
                                        if (_in.name === _sequence[Object.keys(_sequence)[0]].attr) {
                                            if (params?.Result[0]?.properties?.type === 'insert') {
                                                _in.count = parseInt(_in.count) > 0 ? _in.count + 1 : 1;

                                            }
                                        }
                                        return _in;
                                    });
                                    //    addedCount.count = addedCount.count+1;


                                }
                            }
                            return _construct;
                        })
                    } else {
                        let construct = {
                            "name": Object.keys(_sequence)[0],
                            "properties": {
                                "datatype": "dimension",
                                "fieldCollection": true
                            }
                        };
                        construct.properties.fields = [
                            {
                                "name": _sequence[Object.keys(_sequence)[0]].attr,
                                "count": 1,
                                "properties": {
                                    "datatype": _sequence[Object.keys(_sequence)[0]].type
                                }
                            }
                        ];
                        sequenceGeneratorParams.push(
                            construct
                        );
                        // sequenceGeneratorParams = JSON.parse(JSON.stringify(sequenceGeneratorParams));

                    }
                })
            }
            console.log(sequenceGeneratorParams);
            let sequenceConstructParams = {
                "name": "rule_params",
                "properties": {
                    "datatype": "dimension",
                    "fieldCollection": true,
                    "fields": sequenceGeneratorParams
                }
            };


            let checkprevStore = context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields.some(_store => _store.name === 'rule_params');
            if (checkprevStore) {
                context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields = context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields.map(_generateMap => {
                    if (_generateMap.name === 'rule_params') {
                        _generateMap = sequenceConstructParams;
                    }
                    return _generateMap
                })
            } else {
                context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields = [sequenceConstructParams, ...context.list[datalist.editTable?.attributes?.type.value][datalist.tableIndex].fields];

            }


            context.setEntity(context);
            let sequenceIDS = this.state.sequenceRuleIds;
            if (!sequenceIDS.some(_IDS => _IDS === params?.Result[0]?.properties?.doc?._id)) {
                sequenceIDS[this.state.ruleAddIndex] = params?.Result[0]?.properties?.doc?._id;
                // sequenceIDS.push(params?.Result[0]?.properties?.doc?._id);
            }
            this.setState((prev) => ({
                ...prev,
                isRuleBuilderOpen: false,
                sequenceRuleIds: sequenceIDS
            }));
            // setTimeout(()=>{
            //     this.setDataTypeProperties(this.state.sequenceFieldName, params?.Result[0]?.properties?.doc?._id,'sequence')

            // },0)
        }
    };

    handleSequenceClose = () => {
        this.setState((prev) => ({
            ...prev,
            sequenceAnchorEl: null
        }));
    }
    GroupByClose = () => {
        this.setState((prev) => ({
            ...prev,
            GroupByAnchorEl: null
        }));
    }
    handleSequenceOpen = (fieldName, boxValue) => {
        let sequenceSplitValue = [""];
        if (boxValue.toString().trim().length > 0) {

            sequenceSplitValue = boxValue?.toString()?.split(',');
        }
        this.setState((prev) => ({
            ...prev,
            // isRuleBuilderOpen:true,
            sequenceAnchorEl: true,
            sequenceFieldName: fieldName,
            sequenceRuleIds: sequenceSplitValue
        }));

    }

    saveSequenceGenerator = () => {
        let { sequenceRuleIds } = this.state;

        let modifyIds = sequenceRuleIds?.filter(_ => _?.trim()?.length > 0) ?? [];

        this.setDataTypeProperties(this.state.sequenceFieldName, modifyIds.join(), 'sequence');
        setTimeout(() => {
            this.setState((prev) => ({
                ...prev,
                sequenceAnchorEl: null
            }));
        }, 0);

    }
    addSequenceGenerator = () => {

        let { sequenceRuleIds } = this.state;
        let checkingid = sequenceRuleIds.some(_ => _?.trim()?.length === 0)
        if (checkingid) {
            return;
        }
        sequenceRuleIds = [...sequenceRuleIds, ""];
        this.setState((prev) => ({
            ...prev,
            sequenceRuleIds
        }));
    }
    deleteSequenceGenerator = (index) => {
        // let { sequenceRuleIds } = this.state;
        // sequenceRuleIds = sequenceRuleIds.filter((_,ind)=>ind!==index);
        this.setState((prev) => ({
            ...prev,
            deleteBoxIndex: index,
            deleteBoxOpen: true
        }));
    }

    handleCloseConfirmation = () => {
        this.setState((prev) => ({
            ...prev,
            deleteBoxOpen: !prev.deleteBoxOpen
        }));
    }
    onDeleteSuccess = () => {
        let { sequenceRuleIds, deleteBoxIndex } = this.state;
        sequenceRuleIds = sequenceRuleIds.filter((_, ind) => ind !== deleteBoxIndex);
        this.setState((prev) => ({
            ...prev,
            deleteBoxOpen: !prev.deleteBoxOpen,
            sequenceRuleIds
        }));
    }
    handleGRoupBYOpen = (fieldName, boxValue) => {

        if (boxValue !== undefined) {
            const Arr = [];
            if (boxValue.length > 0) {
                boxValue.map(valuesss => {
                    var Arrvalue = JSON.stringify(valuesss);
                    Arr.push(Arrvalue);
                })

            }
            this.setState(() => ({
                groupByArray: Arr,
            }))
        }

        this.setState((prev) => ({
            ...prev,
            GroupByAnchorEl: true,
        }));

    }

    handleGroupBy = (name, value, index) => {

        let { groupByArray } = this.state;
        groupByArray[index] = value
        this.setState({ groupByArray });
    }

    AddGroupBy = () => {
        let { groupByArray } = this.state;
        const data = {};
        groupByArray.push(data);
        this.setState({ groupByArray })
    }

    DeleteGroupBy = (index) => {
        let { groupByArray } = this.state;
        groupByArray.splice(index, 1)
        this.setState({ groupByArray })

    }


    render() {
        let { datalist, classes } = this.props;
        let properties = datalist?.editFieldData;
        let relationData = properties;
        let { deleteBoxOpen,
            deleteBoxTitle,
            deleteBoxMessage, sequenceRuleIds, sequenceFieldName, sequenceAnchorEl, GroupByAnchorEl, sequenceValue, isRuleBuilderOpen, snackopen, snackmsg, snacktype, saveopen, autoHideDuration, isedgeFieldCollection, collectionList, fieldProperties, entityLists, fieldLists } = this.state
        let dbNAME = sessionStorage.clientDB;
        console.log("this.props", this.props.isDimensionContext.IsDimension)
        return (
            <Grid>
                <ConfirmationBox onDeleteSuccess={this.onDeleteSuccess} handleCloseConfirmation={this.handleCloseConfirmation} open={deleteBoxOpen} title={deleteBoxTitle} dialog={deleteBoxMessage} />

                {
                    !isRuleBuilderOpen && sequenceAnchorEl && <Drawer anchor={"right"} id="sequence-generator-file" open={Boolean(sequenceAnchorEl)} onClose={() => this.handleSequenceClose} >
                        <React.Fragment>
                            <Grid className={classes.sequenceDrawerroot}>
                                <Grid md={12} container className={classes.headerRoot}>
                                    <Grid item md={6} className={classes.headertitleRoot} style={{ textAlign: "left" }}>
                                        <Typography variant="h6" style={{ fontSize: "1.06rem" }}>Add {sequenceFieldName} Rules</Typography>
                                    </Grid>
                                    <Grid item md={6} className={classes.headerButtonRoot} >
                                        <Button className={classes.headerButton + ' ' + classes.fontFormat} onClick={() => this.handleSequenceClose()}>Cancel</Button>
                                        <Button className={classes.headerButton + ' ' + classes.fontFormat} onClick={() => this.saveSequenceGenerator()} variant="contained" color="secondary">Save</Button>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid item md={12} className={classes.contentRoot} style={{ marginLeft: "17px" }}>
                                    <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                        <Button className={classes.headerButton + ' ' + classes.fontFormat} onClick={() => this.addSequenceGenerator()} variant="contained" color="primary">Add Rule</Button>

                                    </Grid>
                                    {sequenceRuleIds?.map((val, rindex) => <React.Fragment>


                                        <Grid item md={12}>
                                            <Typography>Rule{rindex + 1}</Typography>
                                        </Grid>
                                        <Grid item md={12} className={classes.boxPadding}>
                                            <TextField
                                                disabled
                                                value={val}
                                                id="relationship-caption"
                                                size="small" className={classes.relationshipCaption} variant="outlined" />
                                            <img style={{ margin: "6px" }} onClick={() => this.setRuleBuilderAction(true, sequenceFieldName, val, rindex)} src={require('../../../../assets/icons8-table-properties.svg')} />
                                            <DeleteForeverOutlinedIcon onClick={() => this.deleteSequenceGenerator(rindex)} />

                                            {/* {
                                        rindex==0 ? <img style={{margin: "6px"}} onClick={this.addSequenceGenerator} src={require('../../../../assets/group-18 (1).svg')} />
                                        :''
                                     }  */}

                                        </Grid>



                                        <Grid item md={12} className={classes.boxPadding} style={{ textAlign: "end", paddingBottom: "0px", top: "-17px", position: "relative" }}>
                                            <Button onClick={() => this.deleteSequenceGenerator(rindex)} variant="contained" color="secondary" className={classes.headerButton + ' ' + classes.fontFormat} >Delete</Button>
                                        </Grid>
                                    </React.Fragment>)}
                                    {/* <Grid item md={12} className={classes.boxPadding}>
                               <Button onClick={this.addSequenceGenerator} variant="contained" color="primary" className={classes.fontFormat}>More Field</Button>
                           </Grid> */}
                                    {/* <Divider /> */}


                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </Drawer>
                }

                {
                    GroupByAnchorEl && <Drawer anchor={"right"} id="sequence-generator-file" open={Boolean(GroupByAnchorEl)} onClose={() => this.GroupByClose} >
                        <React.Fragment>
                            <Grid className={classes.sequenceDrawerroot}>
                                <Grid md={12} container className={classes.headerRoot}>
                                    <Grid item md={6} className={classes.headertitleRoot} style={{ textAlign: "left" }}>
                                        <Typography variant="h6" style={{ fontSize: "1.06rem" }}>Add {sequenceFieldName} Group By</Typography>
                                    </Grid>
                                    <Grid item md={6} className={classes.headerButtonRoot} >
                                        <Button className={classes.headerButton + ' ' + classes.fontFormat} onClick={() => this.GroupByClose()}>Cancel</Button>
                                        <Button className={classes.headerButton + ' ' + classes.fontFormat} onClick={() => this.setDataTypeProperties("groupBy", this.state.groupByArray, "sequence")} variant="contained" color="secondary">Save</Button>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid item md={12} className={classes.contentRoot} style={{ marginLeft: "17px" }}>
                                    {console.log("groupBy", this.state.groupByArray)}
                                    {this.state.groupByArray.map((datassss, index) =>

                                        <>

                                            <Grid item md={12} className={classes.boxPadding}>
                                                <Typography variant="caption" className={classes.currentTableName} style={{ margin: "0px", fontWeight: 400 }}>group By</Typography>
                                                <TextField

                                                    value={datassss === {} ? null : datassss}
                                                    fullWidth={true}
                                                    onChange={(e) => { this.handleGroupBy("groupBy", e.target.value, index) }}
                                                    multiline
                                                    variant="outlined"

                                                // className={classes.relationshipCaption}

                                                />
                                            </Grid>

                                            {/* <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                         <Grid>
                                          <TextField
                                            //  inputProps={{ style: { height: "14px" } }}
                                             id="groupBy"
                                             value={data}
                                             onChange={(e) => {this.handleGroupBy("groupBy",e.target.value , index ) }}
                                             multiline
                                             rows={5}
                                             rowsMax={10}
                                             variant="outlined"
                                             
                                         />
                                         </Grid>
                                        
 
                                     </Grid> */}
                                            <Divider />
                                            <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                                                <Button className={classes.headerButton + ' ' + classes.fontFormat} onClick={() => this.AddGroupBy()} variant="contained" color="primary">Add</Button>
                                                <Button className={classes.headerButton + ' ' + classes.fontFormat} onClick={() => this.DeleteGroupBy(index)} variant="contained" color="secondary">Delete</Button>
                                            </Grid>
                                        </>

                                    )}


                                    {/* <Grid item md={12} className={classes.boxPadding}>
                               <Button onClick={this.addSequenceGenerator} variant="contained" color="primary" className={classes.fontFormat}>More Field</Button>
                           </Grid> */}
                                    {/* <Divider /> */}


                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </Drawer>
                }
                {saveopen && (
                    <Alerts
                        severity={snacktype}
                        open={saveopen}
                        vertical={'top'}
                        horizontal={'right'}
                        msg={snackmsg}
                        autoHideDuration={autoHideDuration}
                        onclose={() => this.closeSnackBar()}
                    />
                )}
                {
                    isRuleBuilderOpen ? <CreateRule editId={sequenceValue} dbName={dbNAME} open={isRuleBuilderOpen} handleClose={(params) => this.setRuleBuilderAction(params ? params : false)} /> : ''
                }


                <BasicDetails entityLists={entityLists} fieldLists={fieldLists} fieldProperties={fieldProperties} filterCollectionName={this.filterCollectionName} getCollectionList={this.getCollectionList} getCollectionFields={this.getCollectionFields} collectionList={collectionList} isedgeFieldCollection={isedgeFieldCollection} errorValidation={this.errorValidation} setValueJSON={this.setValueJSON} setDataTypeProperties={this.setDataTypeProperties} editproperties1={properties ? properties : {}} editproperties={relationData ? relationData : {}} datalist={datalist ?? {}} />
                {
                    properties?.properties?.datatype?.toLowerCase() === 'number' || properties?.properties?.datatype?.toLowerCase() === 'string' || properties?.properties?.datatype?.toLowerCase() === 'unsignedint' || properties?.properties?.datatype?.toLowerCase() === 'positiveint' || properties?.properties?.datatype?.toLowerCase() === 'integer' || properties?.properties?.datatype?.toLowerCase() === 'integer' ?
                        <React.Fragment>
                            <SequenceDetails handleGRoupBYOpen={this.handleGRoupBYOpen} handleSequenceOpen={this.handleSequenceOpen} setRuleBuilderAction={this.setRuleBuilderAction} fieldProperties={fieldProperties} errorValidation={this.errorValidation} setValueJSON={this.setValueJSON} setDataTypeProperties={this.setDataTypeProperties} editproperties1={properties ? properties : {}} editproperties={relationData ? relationData : {}} />
                        </React.Fragment> : ''
                }

                <Security sequence_generator={this.sequence_generator} errorValidation={this.errorValidation} fieldProperties={fieldProperties} setValueJSON={this.setValueJSON} setDataTypeProperties={this.setDataTypeProperties} cloneContext={this.context} datalist={datalist ?? {}} updateIndexProperties={this.updateIndexProperties} editproperties1={properties ? properties : {}} editproperties={relationData ? relationData : {}} />
                {/* {datalist?.editFieldData?.properties.datatype === 'DigitalObject' ? <DigitalObject setValueJSON={this.setValueJSON} editproperties={properties ? properties : {}} editproperties={relationData ? relationData : {}} /> : ""} */}
            </Grid>
        )
    }
}

Properties.contextType = EntityLeftContext;

export default withStyles(useStyles)(withAllContexts(Properties));