import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Routes } from "./routes";

import {
  Entity,
  NotFound
} from './../screens';
// import RuleID from "../screens/rulepage";

const RouterApp = (props) => {

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Entity} />

        {/* entity path */}
        <Route path={Routes.entity} component={Entity} />
        {/* <Route path={Routes.rule} component={RuleID} /> */}
        {/* For unknow/non-defined path */}
        <Route path="*" component={NotFound} />

      </Switch>
    </Router>
  );
};

export default RouterApp;
