import React from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { JsonCheckerContext } from '../../../contexts';
import axios from 'axios';

import './jsondiffer.css';
const useStyles = ((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    zIndex: 26,
    maxHeight: "100vh",
    overflow: "auto",
    background: 'white',
    display: "unset"
  },
  topHeaderBackIcon: {
    width: "20px",
    cursor: "pointer"
  },
  topHeaderVertical: {
    height: "18px"
  },
  topHeaderTitle: {
    fontSize: "15px",
    height: "22px",
    display: "inline"
  },
  topHeader: {
    display: "grid",
    gridTemplateColumns: "27px 19px 1fr",
    padding: "13px",
    height: "55px"
  },
  secondHeaderLeft: {
    fontSize: "15px",
    height: "22px",
    display: "inline",
  },
  secondHeaderParentLeft: {
    padding: "10px 10px 10px 31px"
  },
  secondHeaderParentRight: {
    padding: "10px 10px 10px 31px",
    borderLeft: "1px solid #e0e0e0"
  },
  secondHeaderRight: {
    fontSize: "15px",
    height: "22px",
    display: "inline",
    // padding: "10px 10px 10px 31px"
  }
}));

// const oldCode = `
// {
//   "name": "Original name",
//   "description": null,
//   "axios": "^0.19.2",
//   "date-fns": "^2.15.0",
//   "dotenv": "^8.2.0",
//   "graphql": "^15.1.0",
//   "moment": "^2.27.0",
// }
// `;

// const newCode = `
// {
//   "name": "My updated name",
//   "description": "Brand new description",
//   "status": "running",
//   "axios": "^0.19.2",
//   "dotenv": "^8.2.0",
//   "graphql": "^15.1.0",
//   "jss-rtl": "^0.3.0",
//   "moment": "^2.27.0",
// }
// `;

class JSONDiff extends React.Component {
  constructor(props){
    super(props);
    this.state= {
      compareVersionList:[],
      oldCode:'',
      newCode:''
    }
  }
  CloseVersionChecker() {
    let context = this.context;
    context.setJsonChecker({
      ...context,
      isopen: false
    })
  }
  // componentDidMount(){
  //   this.getCompareVersion();
  // }
  // shouldComponentUpdate(){
  //   let context = this.context;
  //   if(context.isopen){
  //     this.getCompareVersion();
  //   }
  // }
  // async getCompareVersion() {
  //   let context = this.context;
  //       let payload = {
  //         "metadataId":"697fa075-8045-4549-85c2-651110d84237",
  //         "entitycode":"77fd8878-008d-47e8-9289-5ea6375a1dcf",
  //         "entity":"merchant",
  //         "version":["1.0","2.0"]
  //       }
  //       await axios
  //           .post('http://13.71.3.63:8084/api/compare_entity_version', payload).then(async (res) => {
  //             console.log(res);
  //               this.setState({
  //                 compareVersionList: res.data,
  //                 oldCode:`${res.data[0]}`,
  //                 newCode:`${res.data[1]}`

  //               })
  //               context.setJsonChecker({
  //                 compareVersionList: res.data,
  //                 oldCode:`${res.data[0]}`,
  //                 newCode:`${res.data[1]}`
  //               })
  //           })
  //           .catch(err => {
  //           });
  // }
  render() {
    let { classes } = this.props;
    let { isopen,compareVersionList,oldCode,newCode,compareVersion } = this.context;
    // let  { oldCode,newCode,compareVersionList } = this.state;
    return (
      <React.Fragment>
        {isopen &&
          <Grid container className={classes.root}>
            <Grid item md={12} className={classes.topHeader}>
              <ArrowBackIosIcon onClick={() => this.CloseVersionChecker()} className={classes.topHeaderBackIcon} />
              <Divider orientation="vertical" className={classes.topHeaderVertical} />
              <Typography variant="h6" className={classes.topHeaderTitle}>Version Comparision</Typography>
            </Grid>
            <Divider />
            <Grid container className={classes.secondHeader}>
              <Grid item md={6} className={classes.secondHeaderParentLeft}>
                <Typography variant="h6" className={classes.secondHeaderLeft}>{`${sessionStorage.getItem('entity')} v${compareVersion[0]}`}</Typography>
              </Grid>
              <Grid item md={6} className={classes.secondHeaderParentRight}>
                <Typography variant="h6" className={classes.secondHeaderRight}>{`${sessionStorage.getItem('entity')} v${compareVersion[1]}`}</Typography>
              </Grid>
            </Grid>
            {
              compareVersionList?.length>0 ? <Paper>
              <ReactDiffViewer
                oldValue={oldCode}
                newValue={newCode}
                compareMethod={DiffMethod.WORDS}
                splitView={true} 
              />
            </Paper> :''
            }
            
          </Grid>
        }
      </React.Fragment>
    );
  };
}

JSONDiff.contextType = JsonCheckerContext;

export default withStyles(useStyles)(JSONDiff);