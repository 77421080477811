import React from 'react';
import { Grid, ListItem, List, ListItemText, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { EntityLeftContext } from '../../../../contexts'
const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
        padding: 0
    },
    listRoot: {
        padding: "0",
        flexGrow: 1
    },
    listItem: {
        padding: "7px ​15p",
        borderRadius: "7px",
        marginBottom: 0,
        borderBottom: "1px solid #ececec",
        boxShadow: "1px 1px 1px 1px #dadada",
        marginBottom: 10,
        wordBreak: "break-all"
    },
    listItemSelected: {
        background: theme.palette.primary.main
    },
    listItemText: {
        margin: "0px"
    },
    iconLeft: {
        marginLeft: "5px"
    },
    listicon: {
        height: "24px",
        '& svg': {
            width: "20px",
            color: "#5f5d5d"
        }
    },
    emptyData: {
        padding: "10px",
        fontSize: "12px",
        textAlign: "center"
    }
}));

class NormalListCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: null
        }
    }
    expandLeftCard(val) {
        let { setEntity } = this.context;
        setEntity({
            ...this.context,
            selected: val.id
        })
    }
    render() {
        let { classes, list, emptyContent, selected, selectedList, titlekey, isnostatus, type } = this.props;
        return (
            <Grid container className={classes.root}>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={classes.listRoot}
                >
                    {
                        list && list.map((val, index) => {
                            let isselected = (selected && (selected === val.id || selected === val._id || selected === val?.attributes?.id));
                            return typeof val === "object" ? <ListItem key={index} onClick={() => selectedList(val, type)} button className={`${isselected ? classes.listItemSelected : ''}  ${classes.listItem}`}>
                                <ListItemText secondaryTypographyProps={{ style: { color: isselected ? "white" : '', fontWeight: "bolder" } }} secondary={val[titlekey ? titlekey : 'title'] ? val[titlekey ? titlekey : 'title'] : val.name} />
                                {!isnostatus && <ListItemText secondaryTypographyProps={{ style: { color: isselected ? "white" : '', float: "right" } }} secondary={val.status ? val.status === 'draft' ? 'Draft' : 'Published' : 'Draft'} />}
                            </ListItem>
                                : <ListItem key={index} onClick={() => selectedList(val, type)} button className={`${(selected && selected === val) ? classes.listItemSelected : ''}  ${classes.listItem}`}>
                                    <ListItemText secondaryTypographyProps={{ style: { color: (selected && selected === val) ? "white" : '', fontWeight: "bolder" } }} secondary={val} />
                                    {!isnostatus && <ListItemText secondaryTypographyProps={{ style: { color: (selected && selected === val) ? "white" : '', float: "right" } }} secondary={val.status ? val.status === 'draft' ? 'Draft' : 'Published' : 'Draft'} />}
                                </ListItem>
                        })
                    }
                    {
                        (!list || list === 0) && <div>
                            <Typography className={classes.emptyData}>{emptyContent ? emptyContent : `No Data`}</Typography>
                        </div>
                    }
                </List>
            </Grid >
        )
    }
}
NormalListCard.contextType = EntityLeftContext;
export default withStyles(useStyles)(NormalListCard);