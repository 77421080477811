import React from 'react'
import { makeStyles, Card } from '@material-ui/core'
import WorkflowSection from './workflowSection'

const useStyles = makeStyles(theme => ({
  card: {
    // border: '1px solid #d8d8d8',
    backgroundColor: '#fff',
    boxShadow: '4px 6px 13px 0 rgb(17 15 71 / 6%)',
    padding: '18px 18px 0px',
    margin: 16,
  }
}))

export default function Body (props) {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <WorkflowSection {...props} />
    </Card>
  )
}
